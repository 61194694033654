import React from "react";

const PropertyShimmer = () => {
	return (
		<div className="wrap_listing_card shimmer-container">
			<div className="recommend_list">
				<figure className="shimmer">
					<img alt="" src={""}></img>
					<span></span>
				</figure>
				<div className="cstm_wrap_card_list">
					<div className="wrap_value shimmer">
						<b>
							<span></span>
						</b>
						<span className="wrap_rating"></span>
					</div>
					<span></span>
					<span className="property_location shimmer"></span>
					<div className="wrap_dealer_name shimmer">
						<figure></figure>
						<div className="name_wrap">
							<p>
								<b></b>
							</p>
							<span></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PropertyShimmer;
