import React, { useEffect, useState } from "react";
import { MdVerified } from "react-icons/md";
import { BsChatText } from "react-icons/bs";
import { getBooking } from "./bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "../modal/CommonModal";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  BOOKING_TRANSACTION,
  SAVE_CARD,
} from "../../service/booking/bookingMutaion";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getDecryptUserDetail } from "../../helper/common";
import { setChat } from "../chat/chatSlice";
import face_img from "../../assets/images/face1.png";
import { spin } from "../../DefaultImport";
import { Form } from "react-bootstrap";
import { CARD_LIST } from "../../service/booking/bookingQuery";
const Pay = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [BookingTransaction] = useMutation(BOOKING_TRANSACTION);
  const [saveCard, setSaveCard] = useState(null);
  const [SaveCard] = useMutation(SAVE_CARD);
  const dispatch = useDispatch();
  const getUser = JSON.parse(getDecryptUserDetail());
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [cardHolderName, setCardHolderName] = useState(null);
  const [statusHolderName, setStatusHolder] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setError(null);
    setSaveCard(null);
    setIsChecked(false);
  };
  const { data } = useQuery(CARD_LIST);
  const { cardList } = data || {};
  const { booking } = useSelector(getBooking);
  const { property } = booking || {};
  const { userDetail, amount } = property || {};
  const { totalRentAmount } = booking || {};
  const [payments, setPayment] = useState({
    paymentType: null,
    paymentAmount: null,
  });
  const handleCardValidation = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);
      if (!stripe || !elements) {
        return;
      }

      if (!cardHolderName && !saveCard) {
        setStatusHolder(true);
        return;
      }

      if (isChecked) {
        const { error: stripeErrors, token } = await stripe.createToken(
          elements.getElement(CardNumberElement),
          {
            name: cardHolderName,
          }
        );
        if (token) {
          await SaveCard({
            variables: {
              input: {
                token: token.id,
              },
            },
          });
        }
      }
      if (saveCard) {
        const saveCardOjbect = {
          bookingId: booking._id,
          paymentType: payments.paymentType,
          type: "card",
          paymentMethod: null,
          cardId: saveCard.id,
        };
        const { data } = await BookingTransaction({
          variables: {
            input: saveCardOjbect,
          },
        });
        if (data) {
          const { bookingTransaction } = data || {};
          const { message } = bookingTransaction || {};
          toast.success(message);
          setIsChecked(false);
          navigate(`/booking/${booking._id}/Ongoing`);
        }
        return;
      }
      const { error: stripeError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: cardHolderName,
          },
        });
      if (stripeError) {
        setError(stripeError.message);
      } else {
        const { data } = await BookingTransaction({
          variables: {
            input: {
              bookingId: booking._id,
              paymentType: payments.paymentType,
              paymentMethod: paymentMethod.id,
              type: "direct",
            },
          },
        });
        if (data) {
          navigate(`/booking/${booking._id}/Ongoing`);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
      setCardHolderName(null);
      setSaveCard(null);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const navigateChat = () => {
    dispatch(
      setChat({
        propertyDetails: property,
        receiverId: userDetail?._id,
        senderId: getUser?._id,
        receiverdata: userDetail,
      })
    );
    navigate("/chat", {
      state: { detail: property },
    });
  };

  const payment = (amount, type) => {
    setPayment({
      paymentAmount: amount,
      paymentType: type,
    });
    setShowModal(true);
  };

  const navigateMyBooking = (type) => {
    if (type === "Pay") {
      navigate("/my_booking");
    }
  };
  console.log(cardList);
  window.addEventListener("popstate", function (event) {
    navigateMyBooking("Pay");
  });
  return (
    <div className="inner_chat_card wrap_booking_cancel">
      <div className="card_chat">
        <div className="wrap_chat_img">
          <figure>
            <img
              src={!userDetail?.image ? face_img : userDetail?.image}
              alt=""
            />
          </figure>
          <div className="hosted">
            <p>Entire Property hosted by</p>
            <h5>
              {userDetail?.name}{" "}
              {userDetail?.isUserIdProofVerify && <MdVerified />}
            </h5>
          </div>
        </div>
        <div className="wrap_chat_btn">
          <button className="chat_now_btn" onClick={() => navigateChat()}>
            <BsChatText />
            Chat Now
          </button>
        </div>
      </div>
      <div className="lease">
        <div className="wrap_detail_amount">
          <h5>Price Details</h5>
          {/* <ul>
              <li>
                <span>Accommodation</span> <span>$ 2499</span>
              </li>
              <li>
                <span>Ycasa Service fee</span> <span>$ 199</span>
              </li>
              <li>
                <span>Taxes</span> <span>$ 249</span>
              </li>
            </ul> */}
          <div className="total_amount">
            <span>Total {`(Include all taxes)`}</span>{" "}
            {/* <span>$ {booking?.totalRentAmount}</span> */}
            <span>$ {booking?.property?.amount * (booking?.leaseLength / 30)}</span>
          </div>
        </div>
      </div>
      <div className="btn_book_now btn_flex_wrap">
        <button
          className="btn_see_more btn_pay_half"
          onClick={() => payment(Math.ceil(Number(amount) / 2), "half")}
        >
          Pay 50% | $ {Math.ceil(Number(amount) / 2)}
        </button>
        <button
          className="btn_see_more btn_request_cancel"
          onClick={() => payment(Number(amount), "full")}
        >
          Pay 100% | $ {Number(amount)}
        </button>
      </div>
      <CommonModal
        show={showModal}
        customClass="wrap_modal payment_modal_save_card"
        handleClose={handleClose}
        title="Pay with"
      >
        <div className="row cstm_padding">
          {cardList?.length > 0 && (
            <div className="col-lg-6 col-md-6 col-12">
              <p className="cstm_stripe_p">SAVED CARDS</p>
              <div className="wrap_save_cards">
                {cardList?.map((data, index) => (
                  <div className="inner_cards" key={index}>
                    <input
                      className="radio_cstm"
                      type="radio"
                      id={`card_${index}`}
                      name="selectedCard"
                      value={data.cardNumber}
                      onClick={(e) => {
                        setSaveCard(data);
                        setIsChecked(false);
                      }}
                    />
                    <label htmlFor={`card_${index}`}>
                      <div>
                        <span className="cstm_space">
                          <span>xxxx xxxx xxxx {data?.card?.last4}</span>
                        </span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div
            className={
              cardList?.length > 0
                ? "col-lg-6 col-md-6 col-12 cstm_col_label"
                : "col-lg-12 col-md-12 col-12 cstm_col_label"
            }
          >
            <p className="cstm_stripe_p">ADD NEW CARD</p>
            <form onSubmit={handleCardValidation}>
              <label>
                Card number
                <CardNumberElement
                  className="cstm_number_element"
                  options={{
                    placeholder: "4242 4242 4242 4242",
                    disabled: saveCard ? true : false,
                  }}
                />
              </label>
              <div className="cstm_width_pay">
                <label className="label_1_pay">
                  Expiration date
                  <CardExpiryElement
                    options={{
                      placeholder: "MM / YY",
                      disabled: saveCard ? true : false,
                    }}
                  />
                </label>
                <label className="label_2_pay">
                  CVC
                  <CardCvcElement
                    options={{
                      placeholder: "123",
                      disabled: saveCard ? true : false,
                    }}
                  />
                </label>
              </div>
              <label>
                Card Holder Name
                <input
                  className="cstm_input_profile"
                  type="text"
                  placeholder="Enter Card Holder Name"
                  name="cardHolderName"
                  disabled={saveCard ? true : false}
                  value={cardHolderName}
                  onChange={(e) => setCardHolderName(e.target.value)}
                />
                {statusHolderName === true && (
                  <span className="erro_img">Card holder name is required</span>
                )}
              </label>
              <div className="check_save_card">
                <Form.Check
                  name="agreement"
                  aria-label="saved card for future payments"
                  checked={isChecked}
                  disabled={saveCard ? true : false}
                  onChange={handleCheckboxChange}
                />
                <span>saved card for future payments.</span>
              </div>
              {error && (
                <div>
                  {" "}
                  <p style={{ color: "red" }}>{error}</p>
                </div>
              )}
              <div className="cstm_set_btn">
                <button
                  className="btn_see_more w-50 form_sbmt_btn"
                  type="submit"
                  disabled={loader}
                >
                  {loader === true ? <img alt="" src={spin} /> : "Pay Now"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default Pay;
