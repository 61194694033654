import { gql } from "@apollo/client";

export const ADD_REMOVE_WISHLIST = gql`
  mutation AddRemoveWishList($input: AddWishListDTO!) {
    addAndRemoveWishlist(input: $input)
  }
`;

export const NOTIFY_PROPERTY = gql`
  mutation NotifyMe($input: NotifyMeDTO!){
    notifyMe(input: $input)
  }
`