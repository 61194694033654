import React, { useEffect, useState } from "react";
import "./booking.scss";
import {
  BookingDetails,
  CancelRequest,
  Pay,
  PaymentDetails,
  RateExperience,
  RequestAgain,
} from "../../DefaultImportComponent";
import { useParams } from "react-router-dom";

const Booking = () => {
  const param = useParams();
  const [bookingCategory, setBookingCategory] = useState(null);
  useEffect(() => {
    const type = param.type ? param.type.toLowerCase() : "";
    switch (type) {
      case "pending":
        setBookingCategory(0);
        break;
      case "accepted":
        setBookingCategory(1);
        break;
      case "completed":
        setBookingCategory(2);
        break;
      case "rejected":
        setBookingCategory(3);
        break;
      case "ongoing":
        setBookingCategory(4);
        break;
      case "cancelled":
        setBookingCategory(5);
        break;
      default:
        setBookingCategory(null);
    }
  }, [param.type]);

  const renderBookingAction = () => {
    switch (bookingCategory) {
      case 0:
        return <CancelRequest />;
      case 1:
        return <Pay />;
      case 2:
        return <RateExperience />;
      case 3:
        return <RequestAgain />;
      case 4:
        return <PaymentDetails />;
      case 5:
        return <RequestAgain />;
      default:
        return null;
    }
  };

  
  return (
    <div className="wrap_booking_request">
      <div className="wrap_heading">
        <h5>Booking Details</h5>
      </div>
      <div className="wrap_image_property propert_detailing_list">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <BookingDetails />
          </div>
          <div className="col-lg-4 col-md-5">{renderBookingAction()}</div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
