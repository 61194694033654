import { gql } from "@apollo/client";
export const HOME_PAGE = gql`
  query HomePage($input: HomePageDTO!) {
    homePage(input: $input) {
      total
      newestProperty {
        _id
        title
        beds
        address
        furnishingType
        houseNumber
        postalCode
        images
        bath
        rating
        amount
        userDetail {
          name
          image
        }
      }
      property {
        _id
        title
        beds
        address
        houseNumber
        postalCode
        propertyType
        images
        rating
        bath
        amount
        furnishingType
        userDetail {
          name
          image
        }
        isWishlist
      }
    }
  }
`;

export const BEFORE_LOGIN_PROPERTY = gql`
  query HomePage($input: HomePageDTO!) {
    homePage(input: $input) {
      total
      newestProperty {
        _id
        title
        beds
        address
        furnishingType
        houseNumber
        postalCode
        images
        bath
        rating
        amount
        userDetail {
          name
          image
        }
      }
      property {
        _id
        title
        beds
        address
        houseNumber
        postalCode
        images
        rating
        bath
        amount
        furnishingType
        userDetail {
          name
          image
        }
      }
    }
  }
`;


export const GET_JSON_DATA = gql`
  query {
    getJsonData {
      FurnishingType {
        value
        name
				id
      }
			LeaseLength{
				value
				name
				daysCount
				id
			}
			Amenities{
				value
				name
				image
				id
			}
			AccessibilityFeatures{
				name
				value
				id
			}
			Facilities{
				value
				name
				image
				id
			}
			Smoking{
				id
				value
				name
			}
      PropertyType{
        id
        value
        name
      }
      LeaseType{
        id
        value
        name
      }
      BookingStatus{
        id
        value
        name
      }
      DisputeTopic{
        id
        value
        name
      }
    }
  }
`;
