import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";

import "./App.scss";
import {
  AboutYcasa,
  Auth,
  Booking,
  BookingRequest,
  ChangePassword,
  Dispute,
  DisputeChat,
  Faq,
  Footer,
  ForgetPassword,
  Header,
  HelpAndSupport,
  Landing,
  Message,
  MyBooking,
  MyProfile,
  MyStaysBooking,
  MywishList,
  PrivacyPolicy,
  Property,
  PropertyDetail,
  SaveCard,
  Sidebar,
  Subscription,
  TermCondition,
  UserHeader,
} from "./DefaultImportComponent";
import { getToken } from "./helper/common";
import { toast } from "react-toastify";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import ScrollToTop from "./components/ScrollTop";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import TermsCondition from "./userDashboard/pages/staticpages/TermsCondition";
import StaticPolicy from "./userDashboard/pages/staticpages/StaticPolicy";
import Delete from "./userDashboard/pages/staticpages/Delete";
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY);

const App = () => {
  const DashboardRoute = () => {
    return (
      <>
        <Elements stripe={stripePromise}>
          <ScrollToTop />

          <UserHeader />
          <div className="wrapper_account">
            <div className="Custom_container">
              <div className="dashboard_wrap">
                <Sidebar />
                <div className="dashbaord_content">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Elements>
      </>
    );
  };

  const PropertyRoute = () => {
    const isLoggedIn = getToken();
    if (isLoggedIn) {
      return (
        <>
          <Elements stripe={stripePromise}>
            <UserHeader />
            <div className="wrapper_property_details">
              <div className="Custom_container">
                <div className="wrapper_property">
                  <Outlet />
                </div>
              </div>
            </div>
          </Elements>

          <Footer />
        </>
      );
    }
    return (
      <>
        <Header />
        <div className="wrapper_property_details">
          <div className="Custom_container">
            <div className="wrapper_property">
              <Outlet />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  };
  const ProtectedRoute = ({ element }) => {
    const isLoggedIn = getToken();
    if (!isLoggedIn) {
      toast.error("Session Expired");
      return <Navigate to="/auth" />;
    }
    return element;
  };
  return (
    <Router>
      <Routes>
        <Route exact={true} path="/" element={<Landing />} />
        <Route exact={true} path="/auth" element={<Auth />} />
        <Route
          exact={true}
          path="/auth/forget_password"
          element={<ForgetPassword />}
        />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<StaticPolicy />} />
        <Route path="/delete-account" element={<Delete />} />

        <Route path="/" element={<DashboardRoute />}>
          <Route
            path="/account_setting/my_profile"
            element={<ProtectedRoute element={<MyProfile />} />}
          />
          <Route
            path="/account_setting/change_password"
            element={<ProtectedRoute element={<ChangePassword />} />}
          />
          <Route
            path="/account_setting/about_us"
            element={<ProtectedRoute element={<AboutYcasa />} />}
          />
          <Route
            path="/account_setting/subcriptions"
            element={<ProtectedRoute element={<Subscription />} />}
          />
          <Route
            path="/account_setting/Help_Support"
            element={<ProtectedRoute element={<HelpAndSupport />} />}
          />
          <Route
            path="/account_setting/faq"
            element={<ProtectedRoute element={<Faq />} />}
          />
          <Route
            path="/account_setting/save_card"
            element={<ProtectedRoute element={<SaveCard />} />}
          />
          <Route
            path="/account_setting/privacy_policy"
            element={<ProtectedRoute element={<PrivacyPolicy />} />}
          />
          <Route
            path="/account_setting/terms_Conditions"
            element={<ProtectedRoute element={<TermCondition />} />}
          />
        </Route>
        <Route element={<PropertyRoute />}>
          <Route
            path="/property_list"
            element={<ProtectedRoute element={<Property />} />}
          />
          <Route path="/property_detail/:id" element={<PropertyDetail />} />
          <Route
            path="/booking_request"
            element={<ProtectedRoute element={<BookingRequest />} />}
          />
          <Route
            path="/my_booking"
            element={<ProtectedRoute element={<MyBooking />} />}
          />
          <Route
            path="/booking/:id/:type"
            element={<ProtectedRoute element={<Booking />} />}
          />
          <Route
            path="/mywish_list"
            element={<ProtectedRoute element={<MywishList />} />}
          />
          <Route
            path="/my_dispute"
            element={<ProtectedRoute element={<Dispute />} />}
          />
          <Route
            path="/chat"
            element={<ProtectedRoute element={<Message />} />}
          />
          <Route
            path="/dispute_chat"
            element={<ProtectedRoute element={<DisputeChat />} />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
