import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
// const URL = "https://devnode.ripenapps.in:4343";


// const URL = "https://devnode.foodonreels.com:4343";
const URL="https://apps.ycasa.io"

export const socket = io(URL);
