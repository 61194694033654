import React from "react";
import { Header } from "../../../DefaultImportComponent";
import { Link } from "react-router-dom";

const TermsCondition = () => {
  return (
    <div className="wrapper-static_terms">
      <Header />

      <div className="wrap_static_terms">
        <div className="tittle">
          <h1>Terms & Condition</h1>
          <p>Legal Terms - Last update on 20 Jun 2024</p>
        </div>

        <div className="wrap_content">
          <div className="Custom_container">
            <div className="wrap_terms_content">
              <div className="wrap_txt">
                <h5>TERMS OF SERVICE AGREEMENT</h5>
                <p>
                  PLEASE READ THE FOLLOWING TERMS OF USE AGREEMENT CAREFULLY. BY
                  ACCESSING OR USING OUR SITES AND OUR SERVICES, YOU HEREBY
                  AGREE TO BE BOUND BY THE TERMS AND ALL TERMS INCORPORATED
                  HEREIN BY REFERENCE. IF YOU DO NOT EXPRESSLY AGREE TO ALL OF
                  THE TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS OR USE OUR
                  SITES OR OUR SERVICES.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>ACCEPTANCE OF TERMS</h6>
                <p>
                  The following Terms of Service Agreement (the “TOS”) is a
                  legally binding agreement that shall govern the relationship
                  with our users and others which may interact or interface with
                  CloakUnity LLC, a Texas limited liability company doing
                  business as “YCasa” (“Company”, “we”, “us”, or “our”), with a
                  mailing address of 13208 Bourbon Street, Austin, TX 78727 in
                  association with the use of the website Ycasa.io, (the “Site”)
                  and its Services, which shall be defined below.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>DESCRIPTION OF WEBSITE SERVICES OFFERED</h6>
                <p>
                  Any and all visitors to our site, despite whether they are
                  registered or not, shall be deemed as “users” of the herein
                  contained Services provided for the purpose of this TOS. Once
                  an individual registers for our Services, through the process
                  of creating an account, the user shall then be considered a
                  “member.”
                </p>
                <p>
                  The user and/or member acknowledges and agrees that the
                  services provided and made available through our website and
                  applications, which may include some mobile applications and
                  that those applications may be made available on various
                  social media networking sites and numerous other platforms,
                  and downloadable programs. At its discretion, company may
                  offer additional website services and/or products, or update,
                  modify or revise any current content and services, and this
                  Agreement shall apply to any and all additional services
                  and/or products and any and all updated, modified or revised
                  services unless otherwise stipulated. Company does hereby
                  reserve the right to cancel and cease offering any of the
                  aforementioned services and/or products. You, as the end user
                  and/or member, acknowledges, accepts and agrees that Company
                  shall not be held liable for any such update, modification,
                  revisions, suspensions or discontinuance of any of our
                  services and/or products. Your continued use of the services
                  provided, after such posting of any updates, changes, and/or
                  modifications shall constitute your acceptance of such
                  updates, changes and/or modifications, and as such, frequent
                  review of this Agreement and any and all applicable terms and
                  policies should be made by you to ensure you are aware of all
                  terms and policies currently in effect. Should you not agree
                  to the updated, modified, revised or modified terms, you must
                  stop using the provided services.
                </p>
                <p>
                  Furthermore, the user and/or member understands, acknowledges
                  and agrees that the Services offered shall be provided “AS-IS”
                  and as such Company shall not assume any responsibility or
                  obligation for the timeliness, missed delivery, deletion
                  and/or any failure to store user content, communication or
                  personalization settings.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>REGISTRATION</h6>
                <p>
                  To register and become a “member” of our website, you must be
                  18 years of age to enter into and form a legally binding
                  contract. In addition, you must be in good standing and not an
                  individual that has been previously barred from receiving our
                  Services under the laws and statutes of the United States or
                  other applicable jurisdiction.
                </p>
                <p>
                  Furthermore, the registering party hereby acknowledges,
                  understands and agrees to:
                </p>
                <ul className="cstm_alpha">
                  <li>
                    <p>
                      furnish factual, correct, current and complete information
                      with regards to yourself as may be requested by the data
                      registration process, and
                    </p>
                  </li>
                  <li>
                    <p>
                      furnish factual, correct, current and complete information
                      with regards to yourself as may be requested by the data
                      registration process, and
                    </p>
                  </li>
                </ul>
                <p>
                  If anyone knowingly provides any information of a false,
                  untrue, inaccurate or incomplete nature, Company will have
                  sufficient grounds and rights to suspend or terminate the
                  member in violation of this aspect of the Agreement, and as
                  such refuse any and all current or future use of our services,
                  or any portion thereof.
                </p>
                <p>
                  t is our priority to ensure the safety and privacy of all its
                  visitors, users and members, especially that of children.
                  Therefore, it is for this reason that the parents of any child
                  under the age of 13 that permit their child or children access
                  to our website platform services must create a “family”
                  account, which will certify that the individual creating the
                  “family” account is of 18 years of age and as such, the parent
                  or legal guardian of any child or children registered under
                  the “family” account. As the creator of the “family” account,
                  s/he is thereby granting permission for his/her child or
                  children to access the various Services provided, including,
                  but not limited to, message boards, email, and/or instant
                  messaging. It is the parent’s and/or legal guardian’s
                  responsibility to determine whether any of the Services and/or
                  content provided are age appropriate for his/her child.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>PRIVACY POLICY</h6>
                <p>
                  Every member’s registration data and various other personal
                  information is strictly protected by the out Online Privacy
                  Policy (see the full privacy policy) As a member, you herein
                  consent to the collection and use of the information provided,
                  including the transfer of information within the United States
                  and/or other countries for storage, processing or use by us
                  and/or our subsidiaries and affiliates.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>MEMBER ACCOUNT, USERNAME, PASSWORD AND SECURITY</h6>
                <p>
                  After completion of the registration data and creation of your
                  username, you will receive a random password—which you can
                  later change—and account designation. It shall be your
                  responsibility to maintain the secrecy and confidentiality of
                  your password and for all activities that transpire on or
                  within your account. It shall be your responsibility to notify
                  us immediately if you notice any unauthorized access or use of
                  your account or password or any other breach of security. We
                  shall not be held liable for any loss and/or damage arising
                  from any failure to comply with this term and/or condition of
                  the TOS.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>CONDUCT</h6>
                <p>
                  As a user of the Site, you herein acknowledge, understand and
                  agree that all information, text, software, data, photographs,
                  music, video, messages, tags or any other content, whether it
                  is publicly or privately posted and/or transmitted, is the
                  expressed sole responsibility of the individual from whom the
                  content originated. In short, this means that you are solely
                  responsible for any and all content posted, uploaded, emailed,
                  transmitted or otherwise made available by way of our
                  Services, and as such, we do not guarantee the accuracy,
                  integrity or quality of such content. It is expressly
                  understood that by use of our Services, you may be exposed to
                  content including, but not limited to, any errors or omissions
                  in any content posted, and/or any loss or damage of any kind
                  incurred as a result of the use of any content posted,
                  emailed, transmitted or otherwise made available by us.
                </p>
                <p>
                  Furthermore, you herein agree not to make use of the Site’s
                  Services for the purpose of:
                </p>
                <ul className="number_order">
                  <li>
                    <p>
                      Systematically retrieve data or other content from the
                      Site to create or compile, directly or indirectly, a
                      collection, compilation, database, or directory without
                      written permission from us;
                    </p>
                  </li>
                  <li>
                    <p>
                      Make any unauthorized use of the Site, including
                      collecting usernames and/or email addresses of users by
                      electronic or other means for the purpose of sending
                      unsolicited email, or creating user accounts by automated
                      means or under false pretenses;
                    </p>
                  </li>
                  <li>
                    <p>
                      Use a buying agent or purchasing agent to make purchases
                      on the Site;
                    </p>
                  </li>
                  <li>
                    <p>
                      Use the Site to advertise or offer to sell goods and
                      services not related to the Site;
                    </p>
                  </li>
                  <li>
                    <p>
                      Circumvent, disable, or otherwise interfere with
                      security-related features of the Site, including features
                      that prevent or restrict the use or copying of any Content
                      or enforce limitations on the use of the Site and/or the
                      Content contained therein;
                    </p>
                  </li>
                  <li>
                    <p>
                      Trick, defraud, or mislead us and other users, especially
                      in any attempt to learn sensitive account information such
                      as user passwords;
                    </p>
                  </li>
                  <li>
                    <p>
                      Make improper use of our support services or submit false
                      reports of abuse or misconduct;
                    </p>
                  </li>
                  <li>
                    <p>
                      Engage in any automated use of the system, such as using
                      scripts to send comments or messages, or using any data
                      mining, robots, or similar data gathering and extraction
                      tools;
                    </p>
                  </li>
                  <li>
                    <p>
                      Interfere with, disrupt, or create an undue burden on the
                      Site or the networks or services connected to the Site;
                    </p>
                  </li>
                  <li>
                    <p>
                      Attempt to impersonate another user or person or use the
                      username of another user;
                    </p>
                  </li>
                  <li>
                    <p>Sell or otherwise transfer your profile;</p>
                  </li>
                  <li>
                    <p>
                      Use any information obtained from the Site in order to
                      harass, abuse, or harm another person;
                    </p>
                  </li>
                  <li>
                    <p>
                      Use the Site as part of any effort to compete with us or
                      otherwise use the Site and/or the Content for any
                      revenue-generating endeavor or commercial enterprise.
                    </p>
                  </li>
                  <li>
                    <p>
                      Decipher, decompile, disassemble, or reverse engineer any
                      of the software comprising or in any way making up a part
                      of the Site;
                    </p>
                  </li>
                  <li>
                    <p>
                      Decipher, decompile, disassemble, or reverse engineer any
                      of the software comprising or in any way making up a part
                      of the Site;
                    </p>
                  </li>
                  <li>
                    <p>
                      Harass, annoy, intimidate, or threaten any of our
                      employees or agents engaged in providing any portion of
                      the Site to you;
                    </p>
                  </li>
                  <li>
                    <p>
                      Delete the copyright or other proprietary rights notice
                      from any Content;
                    </p>
                  </li>
                  <li>
                    <p>
                      Copy or adapt the Site’s software, including but not
                      limited to Flash, PHP, HTML, JavaScript, or other code;
                    </p>
                  </li>
                  <li>
                    <p>
                      Upload or transmit (or attempt to upload or to transmit)
                      viruses, Trojan horses, or other material, including
                      excessive use of capital letters and spamming (continuous
                      posting of repetitive text), that interferes with any
                      party’s uninterrupted use and enjoyment of the Site or
                      modifies, impairs, disrupts, alters, or interferes with
                      the use, features, functions, operation, or maintenance of
                      the Site;
                    </p>
                  </li>
                  <li>
                    <p>
                      Upload or transmit (or attempt to upload or to transmit)
                      any material that acts as a passive or active information
                      collection or transmission mechanism, including without
                      limitation, clear graphics interchange formats (“gifs”),
                      1×1 pixels, web bugs, cookies, or other similar devices
                      (sometimes referred to as “spyware” or “passive collection
                      mechanisms” or “pcms”);
                    </p>
                  </li>
                  <li>
                    <p>
                      Except as may be the result of standard search engine or
                      Internet browser usage, use, launch, develop, or
                      distribute any automated system, including without
                      limitation, any spider, robot, cheat utility, scraper, or
                      offline reader that accesses the Site, or using or
                      launching any unauthorized script or other software;
                    </p>
                  </li>
                  <li>
                    <p>
                      Disparage, tarnish, or otherwise harm, in our opinion, us
                      and/or the Site; or
                    </p>
                  </li>
                  <li>
                    <p>
                      Use the Site in a manner inconsistent with any applicable
                      laws or regulations.
                    </p>
                  </li>
                </ul>
                <p>
                  We reserve the right to pre-screen, refuse and/or delete any
                  content currently available through our services. In addition,
                  we reserve the right to remove and/or delete any such content
                  that would violate the TOS or which would otherwise be
                  considerable to other visitors, users and/or members.
                </p>
                <p>
                  We reserve the right to access, preserve and/or disclose
                  member account information and/or content if it is requested
                  to do so by law or in good faith belief that any such action
                  is deemed reasonably necessary for:
                </p>
                <ul className="cstm_alpha">
                  <li>
                    <p>compliance with any legal process;</p>
                  </li>
                  <li>
                    <p>enforcement of the TOS;</p>
                  </li>
                  <li>
                    <p>
                      responding to any claim that therein contained content is
                      in violation of the rights of any third party;
                    </p>
                  </li>
                  <li>
                    <p>responding to requests for customer service; or</p>
                  </li>
                  <li>
                    <p>
                      protecting the rights, property or the personal safety of
                      the Site, its visitors, users and members, including the
                      general public.
                    </p>
                  </li>
                </ul>
                <p>
                  We reserve the right to include the use of security components
                  that may permit digital information or material to be
                  protected, and that such use of information and/or material is
                  subject to usage guidelines and regulations established by
                  Company or any other content providers supplying content
                  services to Company. You are hereby prohibited from making any
                  attempt to override or circumvent any of the embedded usage
                  rules in our Services. Furthermore, unauthorized reproduction,
                  publication, distribution, or exhibition of any information or
                  materials supplied by our Services, despite whether done so in
                  whole or in part, is expressly prohibited.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>INTERSTATE COMMUNICATION</h6>
                <p>
                  Upon registration, you hereby acknowledge that by using our
                  Site to send electronic communications, which would include,
                  but are not limited to, email, searches, instant messages,
                  uploading of files, photos and/or videos, you will be causing
                  communications to be sent through our computer network.
                  Therefore, through your use, and thus your agreement with this
                  TOS, you are acknowledging that the use of this Service shall
                  result in interstate transmissions.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>
                  CAUTIONS FOR GLOBAL USE AND EXPORT AND IMPORT COMPLIANCE
                </h6>
                <p>
                  Due to the global nature of the internet, through the use of
                  our network you hereby agree to comply with all local rules
                  relating to online conduct and that which is considered
                  acceptable Content. Uploading, posting and/or transferring of
                  software, technology and other technical data may be subject
                  to the export and import laws of the United States and
                  possibly other countries. Through the use of our network, you
                  thus agree to comply with all applicable export and import
                  laws, statutes and regulations, including, but not limited to,
                  the Export Administration Regulations (
                  <Link
                    target="_blank"
                    to={"http://www.access.gpo.gov/bis/ear/ear_data.html"}
                  >
                    http://www.access.gpo.gov/bis/ear/ear_data.html
                  </Link>
                  ), as well as the sanctions control program of the United
                  States (
                  <Link
                    target="_blank"
                    to={
                      "http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx"
                    }
                  >
                    http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx
                  </Link>
                  ). Furthermore, you state and pledge that you:
                </p>
                <ul className="cstm_alpha">
                  <li>
                    <p>
                      are not on the list of prohibited individuals which may be
                      identified on any government export exclusion report ({" "}
                      <Link
                        target="_blank"
                        to={
                          "http://www.bis.doc.gov/complianceandenforcement/liststocheck.html"
                        }
                      >
                        http://www.bis.doc.gov/complianceandenforcement/liststocheck.html
                      </Link>{" "}
                      ) nor a member of any other government which may be part
                      of an export-prohibited country identified in applicable
                      export and import laws and regulations;
                    </p>
                  </li>
                  <li>
                    <p>
                      agree not to transfer any software, technology or any
                      other technical data through the use of our network
                      services to any export-prohibited country;
                    </p>
                  </li>
                  <li>
                    <p>
                      agree not to use our website network services for any
                      military, nuclear, missile, chemical or biological
                      weaponry end uses that would be a violation of the U.S.
                      export laws; and
                    </p>
                  </li>
                  <li>
                    <p>
                      agree not to post, transfer nor upload any software,
                      technology or any other technical data which would be in
                      violation of the U.S. or other applicable export and/or
                      import laws.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h6>CONTENT PLACED OR MADE AVAILABLE FOR COMPANY SERVICES</h6>
                <p>
                  Company shall not lay claim to ownership of any content
                  submitted by any visitor, user or member, nor make such
                  content available for inclusion on our website services.
                  Therefore, you hereby grant and allow for Company the below
                  listed worldwide, royalty-free and non-exclusive licenses, as
                  applicable:
                </p>
                <ul className="cstm_alpha">
                  <li>
                    <p>
                      The content submitted or made available for inclusion on
                      the publicly accessible areas of our sites, the license
                      provided to permit to use, distribute, reproduce, modify,
                      adapt, publicly perform and/or publicly display said
                      Content on our network services for the sole purpose of
                      providing and promoting the specific area in which this
                      content was placed and/or made available for viewing. This
                      license shall be available so long as you are a member of
                      our sites, and shall terminate at such time when you elect
                      to discontinue your membership.
                    </p>
                  </li>
                  <li>
                    <p>
                      Photos, audio, video and/or graphics submitted or made
                      available for inclusion on the publicly accessible areas
                      of our sites, the license provided to permit to use,
                      distribute, reproduce, modify, adapt, publicly perform
                      and/or publicly display said Content on our network
                      services for the sole purpose of providing and promoting
                      the specific area in which this content was placed and/or
                      made available for viewing. This license shall be
                      available so long as you are a member of our sites and
                      shall terminate at such time when you elect to discontinue
                      your membership.
                    </p>
                  </li>
                  <li>
                    <p>
                      For any other content submitted or made available for
                      inclusion on the publicly accessible areas of our sites,
                      the continuous, binding and completely sub-licensable
                      license which is meant to permit to use, distribute,
                      reproduce, modify, adapt, publish, translate, publicly
                      perform and/or publicly display said content, whether in
                      whole or in part, and the incorporation of any such
                      Content into other works in any arrangement or medium
                      currently used or later developed.
                    </p>
                  </li>
                </ul>
                <p>
                  Those areas which may be deemed “publicly accessible” areas of
                  our sites are those such areas of our network properties which
                  are meant to be available to the general public, and that
                  which would include message boards and groups that are openly
                  available to both visitors and members. However, those areas
                  which are not open to the public, and thus available to
                  members only, would include our mail system and instant
                  messaging.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>CONTRIBUTIONS TO COMPANY WEBSITE</h6>
                <p>
                  Company provides an area for our users and members to
                  contribute feedback to our website. When you submit ideas,
                  documents, suggestions and/or proposals (“Contributions”) to
                  our site, you acknowledge and agree that:
                </p>
                <ul className="Cstm_alpha">
                  <li>
                    <p>
                      your Contributions do not contain any type of confidential
                      or proprietary information;
                    </p>
                  </li>
                  <li>
                    <p>
                      Company shall not be liable or under any obligation to
                      ensure or maintain confidentiality, expressed or implied,
                      related to any Contributions;
                    </p>
                  </li>
                  <li>
                    <p>
                      Company shall be entitled to make use of and/or disclose
                      any such Contributions in any such manner as they may see
                      fit;
                    </p>
                  </li>
                  <li>
                    <p>
                      the contributor’s Contributions shall automatically become
                      the sole property of Company; and
                    </p>
                  </li>
                  <li>
                    <p>
                      Company is under no obligation to either compensate or
                      provide any form of reimbursement in any manner or nature.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h6>INDEMNITY</h6>
                <p>
                  All users and/or members herein agree to insure and hold
                  Company, including our subsidiaries, affiliates, agents,
                  employees, officers, partners and/or licensors blameless or
                  not liable for any claim or demand, which may include, but is
                  not limited to, reasonable attorney fees, made by any third
                  party which may arise from any content a member or user of our
                  site may submit, post, modify, transmit or otherwise make
                  available through our Services, the use of our services or
                  your connection with their services, your violations of the
                  Terms of Service and/or your violation of any such rights of
                  another person.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>COMMERCIAL REUSE OF SERVICES</h6>
                <p>
                  The member or user herein agrees not to replicate, duplicate,
                  copy, trade, sell, resell nor exploit for any commercial
                  reason any part, use of, or access to our sites.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>USE AND STORAGE GENERAL PRACTICES</h6>
                <p>
                  You herein acknowledge that we may set up any such practices
                  and/or limits regarding the use of our Services, without
                  limitation of the maximum number of days that any email,
                  message postings or any other uploaded content shall be
                  retained by us, nor the maximum number of email messages that
                  may be sent and/or received by any member, the maximum volume
                  or size of any email message that may be sent from or may be
                  received by an account on our service, the maximum disk space
                  allowable that shall be allocated on our servers on the
                  member’s behalf, and/or the maximum number of times and/or
                  duration that any member may access our Services in a given
                  period of time. In addition, you also agree that Company has
                  absolutely no responsibility or liability for the removal or
                  failure to maintain storage of any messages and/or other
                  communications or content maintained or transmitted by our
                  Services. You also herein acknowledge that we reserve the
                  right to delete or remove any account that is no longer active
                  for an extended period of time. Furthermore, Company shall
                  reserve the right to modify, alter and/or update these general
                  practices and limits at our discretion.
                </p>
                <p>
                  Any messenger service, which may include any web-based
                  versions, shall allow you and the individuals with whom you
                  communicate with the ability to save your conversations in
                  your account located on our servers. In this manner, you will
                  be able to access and search your message history from any
                  computer with internet access. You also acknowledge that
                  others have the option to use and save conversations with you
                  in their own personal account on our Site. It is your
                  agreement to this TOS which establishes your consent to allow
                  us to store any and all communications on its servers.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>MODIFICATIONS</h6>
                <p>
                  Company shall reserve the right at any time it may deem fit,
                  to modify, alter and or discontinue, whether temporarily or
                  permanently, our service, or any part thereof, with or without
                  prior notice. In addition, we shall not be held liable to you
                  or to any third party for any such alteration, modification,
                  suspension and/or discontinuance of our Services, or any part
                  thereof.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>TERMINATION</h6>
                <p>
                  As a member of our Site, you may cancel or terminate your
                  account, associated email address and/or access to our
                  services by submitting a cancellation or termination request
                  to estevan@cloakunity.com.
                </p>
                <p>
                  As a member, you agree that Company may, without any prior
                  written notice, immediately suspend, terminate, discontinue
                  and/or limit your account, any email associated with your
                  account, and access to any of our Services. The cause for such
                  termination, discontinuance, suspension and/or limitation of
                  access shall include, but is not limited to:
                </p>
                <ul className="cstm_alpha">
                  <li>
                    <p>
                      any breach or violation of our TOS or any other
                      incorporated agreement, regulation and/or guideline;
                    </p>
                  </li>
                  <li>
                    <p>
                      by way of requests from law enforcement or any other
                      governmental agencies;
                    </p>
                  </li>
                  <li>
                    <p>
                      the discontinuance, alteration and/or material
                      modification to our Services, or any part thereof;
                    </p>
                  </li>
                  <li>
                    <p>
                      unexpected technical or security issues and/or problems;
                    </p>
                  </li>
                  <li>
                    <p>any extended periods of activity;</p>
                  </li>
                  <li>
                    <p>
                      any engagement by you in any fraudulent or illegal
                      activities; and/or
                    </p>
                  </li>
                  <li>
                    <p>
                      the nonpayment of any associated fees that may be owed by
                      you in connection with our account services.
                    </p>
                  </li>
                </ul>
                <p>
                  Furthermore, you herein agree that any and all terminations,
                  suspensions, discontinuances, and or limitations of access for
                  cause shall be made at our sole discretion and that we shall
                  not be liable to you or any other third party with regards to
                  the termination of your account, associated email address
                  and/or access to any of our services.
                </p>
                <p>
                  The termination of your account with us, shall include any
                  and/or all of the following:
                </p>
                <ul>
                  <li>
                    <p>
                      the removal of any access to all or part of the Services
                      offered within our Site;
                    </p>
                  </li>
                  <li>
                    <p>
                      the deletion of your password and any and all related
                      information, files, and any such content that may be
                      associated with or inside your account, or any part
                      thereof; and
                    </p>
                  </li>
                  <li>
                    <p>
                      the deletion of your password and any and all related
                      information, files, and any such content that may be
                      associated with or inside your account, or any part
                      thereof; and
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h6>ADVERTISERS</h6>
                <p>
                  Any correspondence or business dealings with, or the
                  participation in any promotions of, advertisers located on or
                  through our Services, which may include the payment and/or
                  delivery of such related goods and/or services, and any such
                  other term, condition, warranty and/or representation
                  associated with such dealings, are and shall be solely between
                  you and any such advertiser. Moreover, you herein agree that
                  Company shall not be held responsible or liable for any loss
                  or damage of any nature or manner incurred as a direct result
                  of any such dealings or as a result of the presence of such
                  advertisers on our website.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>LINKS</h6>
                <p>
                  Either Company or any third parties, may provide links to
                  other websites and/or resources. Thus, you acknowledge and
                  agree that we are not responsible for the availability of any
                  such external sites or resources, and as such, we do not
                  endorse nor are we responsible or liable for any content,
                  products, advertising or any other materials, on or available
                  from such third party sites or resources. Furthermore, you
                  acknowledge and agree that Company shall not be responsible or
                  liable, directly or indirectly, for any such damage or loss
                  which may be a result of, caused or allegedly to be caused by
                  or in connection with the use of or the reliance on any such
                  content, goods or services made available on or through any
                  such site or resource.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>PROPRIETARY RIGHTS</h6>
                <p>
                  You do hereby acknowledge and agree that our Services and any
                  essential software that may be used in connection with our
                  services (“Software”) shall contain proprietary and
                  confidential material that is protected by applicable
                  intellectual property rights and other laws. Furthermore, you
                  herein acknowledge and agree that any Content which may be
                  contained in any advertisements or information presented by
                  and through our services or by advertisers is protected by
                  copyrights, trademarks, patents or other proprietary rights
                  and laws. Therefore, except for that which is expressly
                  permitted by applicable law or as authorized by Company or
                  such applicable licensor, you agree not to alter, modify,
                  lease, rent, loan, sell, distribute, transmit, broadcast,
                  publicly perform and/or created any plagiaristic works which
                  are based on our Services (e.g. Content or Software), in whole
                  or part.
                </p>
                <p>
                  We have granted you personal, non-transferable and
                  non-exclusive rights and/or license to make use of the object
                  code or its Software on a single computer, as long as you do
                  not, and shall not, allow any third party to duplicate, alter,
                  modify, create or plagiarize work from, reverse engineer,
                  reverse assemble or otherwise make an attempt to locate or
                  discern any source code, sell, assign, sublicense, grant a
                  security interest in and/or otherwise transfer any such right
                  in the Software. Furthermore, you do herein agree not to alter
                  or change the Software in any manner, nature or form, and as
                  such, not to use any modified versions of the Software,
                  including and without limitation, for the purpose of obtaining
                  unauthorized access to our Services. Lastly, you also agree
                  not to access or attempt to access our Services through any
                  means other than through the interface which is provided by
                  Company for use in accessing our Services.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>WARRANTY DISCLAIMERS</h6>
                <p>WARRANTY DISCLAIMERS</p>
                <ul className="cstm_alpha">
                  <li>
                    <p>
                      THE USE OF OUR SERVICES AND SOFTWARE ARE AT THE SOLE RISK
                      BY YOU. OUR SERVICES AND SOFTWARE SHALL BE PROVIDED ON AN
                      “AS IS” AND/OR “AS AVAILABLE” BASIS. COMPANY AND OUR
                      SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
                      PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY AND ALL
                      WARRANTIES OF ANY KIND WHETHER EXPRESSED OR IMPLIED,
                      INCLUDING, BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF
                      TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
                      AND NON-INFRINGEMENT.
                    </p>
                  </li>
                  <li>
                    <p>
                      COMPANY AND OUR SUBSIDIARIES, OFFICERS, EMPLOYEES, AGENTS,
                      PARTNERS AND LICESNSORS MAKE NO SUCH WARRANTIES THAT (i)
                      COMPANY SERVICES OR SOFTWARE WILL MEET YOUR REQUIREMENTS;
                      (ii) COMPANY SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED,
                      TIMELY, SECURE OR ERROR-FREE; (iii) THAT SUCH RESULTS
                      WHICH MAY BE OBTAINED FROM THE USE OF THE COMPANY SERVICES
                      OR SOFTWARE WILL BE ACCURATE OR RELIABLE; (iv) QUALITY OF
                      ANY PRODUCTS, SERVICES, ANY INFORMATION OR OTHER MATERIAL
                      WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH OUR
                      SERVICES OR SOFTWARE WILL MEET YOUR EXPECTATIONS; AND (v)
                      THAT ANY SUCH ERRORS CONTAINED IN THE SOFTWARE SHALL BE
                      CORRECTED.
                    </p>
                  </li>
                  <li>
                    <p>
                      ANY INFORMATION OR MATERIAL DOWNLOADED OR OTHERWISE OBTAIN
                      BY WAY OF OUR SERVICES OR SOFTWARE SHALL BE ACCESSED BY
                      YOUR SOLE DISCRETION AND SOLE RISK, AND AS SUCH YOU SHALL
                      BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL
                      CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO
                      YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING AND/OR
                      DISPLAYING, OR FOR ANY LOSS OF DATA THAT COULD RESULT FROM
                      THE DOWNLOAD OF ANY SUCH INFORMATION OR MATERIAL.
                    </p>
                  </li>
                  <li>
                    <p>
                      NO ADVICE AND/OR INFORMATION, DESPITE WETHER WRITTEN OR
                      ORAL, THAT MAY BE OBTAINED BY YOU FROM US OR BY WAY OF OR
                      FROM OUR SERVICES OR SOFTWARE SHALL CREATE ANY WARRANTY
                      NOT EXPRESSLY STATED IN THE TOS.
                    </p>
                  </li>
                  <li>
                    <p>
                      A SMALL PERCENTAGE OF SOME USERS MAY EXPERIENCE SOME
                      DEGREE OF EPILEPTIC SEIZURE WHEN EXPOSED TO CERTAIN LIGHT
                      PATTERNS OR BACKGROUNDS THAT MAY BE CONTAINED ON A
                      COMPUTER SCREEN OR WHILE USING OUR SERVICES. CERTAIN
                      CONDITIONS MAY INDUCE A PREVIOUSLY UNKNOWN CONDITION OR
                      UNDETECTED ELILEPTIC SYMPTOM IN USERS WHO HAVE SHOWN NO
                      HISTORY OF ANY PRIOR SEIZURE OR EPILEPSY. SHOULD YOU,
                      ANYONE YOU KNOW OR ANYONE IN YOUR FAMILY HAVE AN EPILEPTIC
                      CONDITION, PLEASE CONSULT A PHYSICAN IF YOU EXPERIENCE ANY
                      OF THE FOLLOWING SYMPTOMS WHILE USING OUR SERVICES:
                      DIZZINESS, ALTERED VISON, EYE OR MUSCLE TWITCHES, LOSS OF
                      AWARNESS, DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR
                      CONVULSIONS.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h6>LIMITATION OF LIABILITY</h6>
                <p>
                  YOU EXPLICITY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT COMPANY
                  AND OUR SUBISDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
                  PARTNERS AND LICENSORS SHALL NOT BE LAIBLE TO YOU FOR ANY
                  PUNITIVE, INDIRECT, INCIDENTIAL, SPECIAL, CONSEQUENIAL OR
                  EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES
                  WHICH MAY BE RELATED TO THE LOSS OF ANY PROFITS, GOODWILL,
                  USE, DATA AND/OR OTHER INTANGILBE LOSSES, EVEN THOUGH WE MAY
                  HAVE BEEN ADVISED OF SUCH POSSIBILITY THAT SAID DAMAGES MAY
                  OCCUR, AND RESULT FROM:
                </p>
                <ul className="cstm_alpha">
                  <li>
                    <p>THE USE OR INABILITY TO USE OUR SERVICE;</p>
                  </li>
                  <li>
                    <p>THE COST OF PRECURING SUBSTITUTE GOODS AND SERVICES;</p>
                  </li>
                  <li>
                    <p>
                      UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR
                      TRANSMISSIONS AND/OR DATA;
                    </p>
                  </li>
                  <li>
                    <p>
                      STATEMENTS OR CONDUCT OF ANY SUCH THRID PARTY ON OUR
                      SERVICE;
                    </p>
                  </li>
                  <li>
                    <p>
                      AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h6>SPECIAL ADMONITION RELATED TO FINANCIAL MATTERS</h6>
                <p>
                  Should you intend to create or to join any service, receive or
                  request any such news, messages, alerts or other information
                  from our Services concerning companies, stock quotes,
                  investments or securities, please review the above Sections
                  nineteen (19) and twenty (20) again. In addition, for this
                  particular type of information, the phrase “Let the investor
                  beware” is appropriate. Our content is provided primarily for
                  informational purposes, and no content that shall be provided
                  or included in our services is intended for trading or
                  investing purposes. Company and our licensors shall not be
                  responsible or liable for the accuracy, usefulness or
                  availability of any information transmitted and/or made
                  available by way of our services, and shall not be responsible
                  or liable for any trading and/or investment decisions based on
                  any such information.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>EXCLUSION AND LIMITATIONS</h6>
                <p>
                  THERE ARE SOME JURISDICTIONS WHICH DO NOT ALLOW THE EXCLUSION
                  OF CERTAIN WARRANTIES OR THE LIMITATION OF EXCLUSION OF
                  LAIBILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. THEREFORE,
                  SOME OF THE ABOVE LIMITATIONS OF SECTIONS NINETEEN (19) AND
                  TWENTY (20) MAY NOT APPLY TO YOU.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>THIRD PARTY BENEFICIARIES</h6>
                <p>
                  You herein acknowledge, understand and agree, unless otherwise
                  expressly provided in this TOS, that there shall be
                  third-party beneficiaries to this agreement.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>NOTICE</h6>
                <p>
                  Company may furnish you with notices, including those with
                  regards to any changes to the TOS, including but not limited
                  to email, regular mail, MMS or SMS, text messaging, postings
                  on our website services, or other reasonable means currently
                  known or any which may be herein after developed. Any such
                  notices may not be received if you violate any aspects of the
                  TOS by accessing our Services in an unauthorized manner. Your
                  acceptance of this TOS constitutes your agreement that you are
                  deemed to have received any and all notices that would have
                  been delivered had you accessed our Services in an authorized
                  manner.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>TRADEMARK INFORMATION</h6>
                <p>
                  You herein acknowledge, understand, and agree that all of our
                  trademarks, copyright, trade name, service marks, and other
                  Company logos and any brand features, and/or product and
                  service names are trademarks and as such, are and shall remain
                  the property of Company. You herein agree not to display
                  and/or use in any manner the Company logo or marks.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>
                  COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS NOTICE
                  & PROCEDURES
                </h6>
                <p>
                  Company will always respect the intellectual property of
                  others, and we ask that all of our users to the same. With
                  regards to appropriate circumstances and at its sole
                  discretion, we may disable and/or terminate the accounts of
                  any user who repeatedly violates our TOS and/or infringes the
                  rights of others. If you feel that your work has been
                  duplicated in such a way that would constitute copyright
                  infringement, or if you believe your intellectual property
                  rights have been otherwise violated, you should provide to us
                  the following information:
                </p>
                <ul className="cstm_alpha">
                  <li>
                    <p>
                      The electronic or the physical signature of the individual
                      that is authorized on behalf of the owner of the copyright
                      or other intellectual property interest;
                    </p>
                  </li>
                  <li>
                    <p>
                      A description of the copyrighted work or other
                      intellectual property that you believe has been infringed
                      upon;
                    </p>
                  </li>
                  <li>
                    <p>
                      A description of the location of the site which you allege
                      has been infringing upon your work;
                    </p>
                  </li>
                  <li>
                    <p>
                      The physical address, telephone number, and email address;
                    </p>
                  </li>
                  <li>
                    <p>
                      A statement, in which you state that the alleged and
                      disputed use of your work is not authorized by the
                      copyright owner, its agents or the law;
                    </p>
                  </li>
                  <li>
                    <p>
                      And finally, a statement, made under penalty of perjury,
                      that the aforementioned information in your notice is the
                      truth and accurate, and that you are the copyright or
                      intellectual property owner, representative or agent
                      authorized to act on the copyright or intellectual
                      property owner’s behalf.
                    </p>
                  </li>
                </ul>
                <p>
                  Our Agent for Notice of claims of copyright or other
                  intellectual property infringement can be contacted as
                  follows:
                </p>
                <ul>
                  <li>Attn: Copyright Agent</li>
                  <li>Mailing Address:</li>
                  <li>13208 Bourbon Street <br /> Austin, TX 78727</li>
                  <li>Telephone: (253) 380-4385</li>
                  <li>
                    Email:{" "}
                    <Link to={"mailto:estevan@cloakunity.com"}>
                      estevan@cloakunity.com
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h6>CLOSED CAPTIONING</h6>
                <p>
                  BE IT KNOWN, that Company complies with all applicable Federal
                  Communications Commission rules and regulations regarding the
                  closed captioning of video content. For more information,
                  please visit our website at Ycasa.io.
                </p>
              </div>
              <div className="wrap_txt">
                <h5>GENERAL INFORMATION</h5>
                <h6>ENTIRE AGREEMENT</h6>
                <p>
                  This TOS constitutes the entire agreement between you and
                  Company and shall govern the use of our Services, superseding
                  any prior version of this TOS between you and us with respect
                  to our Services. You may also be subject to additional terms
                  and conditions that may apply when you use or purchase certain
                  other our services, affiliate services, third-party content or
                  third-party software.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>CHOICE OF LAW AND FORUM</h6>
                <p>
                  t is at the mutual agreement of both you and us with regard to
                  the TOS that the relationship between the parties shall be
                  governed by the laws of the Texas without regard to its
                  conflict of law provisions and that any and all claims, causes
                  of action and/or disputes, arising out of or relating to the
                  TOS, or the relationship between you and Company, shall be
                  filed within the courts having jurisdiction within the County
                  of Travis County, Texas or the U.S. District Court located in
                  said state. You and Company agree to submit to the
                  jurisdiction of the courts as previously mentioned, and agree
                  to waive any and all objections to the exercise of
                  jurisdiction over the parties by such courts and to venue in
                  such courts.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>WAIVER AND SEVERABILITY OF TERMS</h6>
                <p>
                  At any time, should Company fail to exercise or enforce any
                  right or provision of the TOS, such failure shall not
                  constitute a waiver of such right or provision. If any
                  provision of this TOS is found by a court of competent
                  jurisdiction to be invalid, the parties nevertheless agree
                  that the court should endeavor to give effect to the parties’
                  intentions as reflected in the provision, and the other
                  provisions of the TOS remain in full force and effect.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>NO RIGHT OF SURVIVORSHIP NON-TRANSFERABILITY</h6>
                <p>
                  You acknowledge, understand and agree that your account is
                  non-transferable and any rights to your ID and/or contents
                  within your account shall terminate upon your death. Upon
                  receipt of a copy of a death certificate, your account may be
                  terminated and all contents therein permanently deleted.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>STATUTE OF LIMITATIONS</h6>
                <p>
                  You acknowledge, understand and agree that regardless of any
                  statute or law to the contrary, any claim or action arising
                  out of or related to the use of our Services or the TOS must
                  be filed within the statute of limitations after said claim or
                  cause of action arose or shall be forever barred.
                </p>
              </div>
              <div className="wrap_txt">
                <h6>VIOLATIONS</h6>
                <p>
                  Please report any and all violations of this TOS to Company as
                  follows:
                </p>
                <ul>
                  <li>Mailing Address:</li>
                  <li>13208 Bourbon Street <br /> Austin, TX 78727</li>
                  <li>Telephone: (253) 380-4385</li>
                  <li>
                    Email:{" "}
                    <Link to={"mailto:estevan@cloakunity.com"}>
                      estevan@cloakunity.com
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
