import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	bookingId: null,
	owner: null,
	property: null,
	disputeTopic: null,
	ticketId: null
}

export const disputeChatSlice = createSlice({
	name: "dispute",
	initialState,
	reducers: {
		setDispute: (state, action) => {
			const { bookingId,owner,property,disputeTopic,ticketId } = action.payload
			state.bookingId = bookingId
			state.owner = owner
			state.property = property
			state.disputeTopic = disputeTopic
			state.ticketId = ticketId
		}
	}
})

export const { setDispute } = disputeChatSlice.actions
export const getDisputState = (state) => state.dispute
export default disputeChatSlice.reducer