import React, { useState } from "react";
import "./filter.scss";
import { FaSearch } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { Filtericon } from "../../DefaultImport";
import { Filter, ListProperty } from "../../DefaultImportComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterProperty,
  setDefaultCoordinate,
  setFilterAccessebility,
  setFilterAmenities,
  setFilterFacilities,
  setFilterFurnishType,
  setFilterLeaseLength,
  setFilterLeaseType,
  setFilterPropertyType,
  setFilterSmoking,
  setSearchFilter,
} from "../../components/property/filterSlice";
import { useQuery } from "@apollo/client";
import { GET_JSON_DATA } from "../../service/home/homeQuery";
import Autocomplete from "react-google-autocomplete";

const Property = () => {
  const dispatch = useDispatch();
  const { filterObject, latLongSearch } = useSelector(getFilterProperty);
  const {
    propertyType,
    leaseType,
    leaseLength,
    accessibilityFeature,
    amenities,
    smoking,
    facilities,
    furnishingType,
  } = filterObject;
  const { data: jsonData } = useQuery(GET_JSON_DATA);
  const { getJsonData } = jsonData || {};
  const {
    PropertyType,
    LeaseType,
    LeaseLength,
    AccessibilityFeatures,
    Smoking,
    Facilities,
    Amenities,
    FurnishingType,
  } = getJsonData || {};
  const mergedArray = [
    ...(PropertyType?.filter((item) => propertyType?.includes(item.value)) ||
      []),
    ...(LeaseType?.filter((item) => leaseType?.includes(item.value)) || []),
    ...(LeaseLength?.filter((item) => leaseLength?.includes(item.value)) || []),
    ...(AccessibilityFeatures?.filter((item) =>
      accessibilityFeature?.includes(item.value)
    ) || []),
    ...(Smoking?.filter((item) => smoking?.includes(item.value)) || []),
    ...(Amenities?.filter((item) => amenities?.includes(item.value)) || []),
    ...(Facilities?.filter((item) => facilities?.includes(item.value)) || []),
    ...(FurnishingType?.filter((item) =>
      furnishingType?.includes(item.value)
    ) || []),
  ];

  const handleCheckboxChange = (property) => {
    const { __typename } = property;
    if (__typename === "PropertyType")
      dispatch(setFilterPropertyType(property.value));
    if (__typename === "Amenities")
      dispatch(setFilterAmenities(property.value));
    if (__typename === "LeaseType")
      dispatch(setFilterLeaseType(property.value));
    if (__typename === "LeaseLength")
      dispatch(setFilterLeaseLength(property.value));
    if (__typename === "AccessibilityFeatures")
      dispatch(setFilterAccessebility(property.value));
    if (__typename === "Smoking") dispatch(setFilterSmoking(property.value));
    if (__typename === "Facilities")
      dispatch(setFilterFacilities(property.value));
    if (__typename === "FurnishingType")
      dispatch(setFilterFurnishType(property.value));
  };

  const handlePlaceSelect = (place) => {
    // setSearched((prev) => place.formatted_address);
    if (!place || !place.geometry || !place.geometry.location) {
      // Handle the case when place or its geometry is not available
      console.error("Invalid place selected");
      // You can display an error message or take appropriate action
      return;
    }

    const { geometry } = place;
    const { location } = geometry;

    if (!location || !location.lat || !location.lng) {
      // Handle the case when latitude or longitude is not available
      console.error("Invalid location selected");
      // You can display an error message or take appropriate action
      return;
    }
    // dispatch(setDefaultCoordinate({place: place.formatted_address,default: false}))

    const { lat, lng } = location;
    // Now, dispatch the valid latitude and longitude
    dispatch(
      setSearchFilter({
        latitude: lat(),
        longitude: lng(),
      })
    );
  };
  const addNavigationClass = () => {
    document.body.classList.add("innerside_filter");
  };

  return (
    <div className="wrapper_filter">
      <div className="Custom_container">
        <div className="wrap_filter">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 cstm_hide_show">
              <Filter />
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="wrap_right_filter position_set_pagination">
                <div className="wrapper_filter_btn mb-4">
                  <div className="cstm_searchbar">
                    {/* <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      className="cstm_input"
                      onPlaceSelected={handlePlaceSelect}
                      onChange={(e) => {
                        dispatch(
                          setDefaultCoordinate({
                            place: e.target.value,
                            default: false,
                          })
                        );
                      }}
                      options={{ types: [] }}
                      value={latLongSearch}
                      placeholder="Search by locality, type, landmark etc..."
                    /> */}
                    <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      className="cstm_input"
                      onPlaceSelected={handlePlaceSelect}
                      options={{ types: [] }}
                      placeholder="Search by locality, type, landmark etc..."
                    />
                    <button className="nav_btn_cstms">
                      <FaSearch /> Search
                    </button>
                  </div>
                  <div className="mobile_filter">
                    <button
                      className="nav_btn_cstms nav_btn_cstms_overwrite"
                      onClick={addNavigationClass}
                    >
                      <img src={Filtericon} alt="" />
                    </button>
                  </div>
                </div>
                <div className="filter_card_details">
                  <div className="wrap_filter_select_cards">
                    {mergedArray?.map((data) => (
                      <span>
                        {data.name}{" "}
                        <ImCross onClick={() => handleCheckboxChange(data)} />
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <ListProperty />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Property;
