import React, { useState } from "react";
import { MdVerified } from "react-icons/md";
import { Form } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import ReactSlider from "react-slider";
import { useQuery } from "@apollo/client";
import { GET_JSON_DATA } from "../../service/home/homeQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterProperty,
  setClearFilter,
  setFilterAccessebility,
  setFilterAmenities,
  setFilterAmount,
  setFilterAvailableFrom,
  setFilterBath,
  setFilterBeds,
  setFilterFacilities,
  setFilterFurnishType,
  setFilterLeaseLength,
  setFilterLeaseType,
  setFilterOwnerVerify,
  setFilterPropertyType,
  setFilterSmoking,
  setPageLimit,
} from "./filterSlice";
import { getCurrentDate } from "../../helper/common";
import { RxCross2 } from "react-icons/rx";

const Filter = () => {
  const dispatch = useDispatch();
  const isSelectedProperty = useSelector((state) => {
    return state.filter.filterObject.propertyType;
  });
  const isSelectedAmenites = useSelector((state) => {
    return state.filter.filterObject.amenities;
  });
  const isSelectedLeasetype = useSelector((state) => {
    return state.filter.filterObject.leaseType;
  });

  const isSelectedLeaseLength = useSelector((state) => {
    return state.filter.filterObject.leaseLength;
  });

  const isSelectedAccesibility = useSelector((state) => {
    return state.filter.filterObject.accessibilityFeature;
  });

  const isSelectedSmoking = useSelector((state) => {
    return state.filter.filterObject.smoking;
  });
  const isSelectedDate = useSelector((state) => {
    return state.filter.filterObject.availableFrom;
  });

  const isSelectedFurnishType = useSelector((state) => {
    return state.filter.filterObject.furnishingType;
  });

  const { data: jsonData } = useQuery(GET_JSON_DATA);
  const { getJsonData } = jsonData || {};
  const { filterObject } = useSelector(getFilterProperty);
  const { amount } = filterObject;
  const [sliderValue, setSliderValue] = useState([amount.min, amount.max]);

  const {
    LeaseLength,
    LeaseType,
    AccessibilityFeatures,
    Amenities,
    Facilities,
    PropertyType,
    Smoking,
    FurnishingType,
  } = getJsonData || {};

  const beds = [
    {
      id: "1",
      name: "1",
    },
    {
      id: "2",
      name: "2",
    },
    {
      id: "3",
      name: "3",
    },
    {
      id: "4",
      name: "3+",
    },
  ];

  const toggleHandler = (index, type) => {
    if (type === "beds") dispatch(setFilterBeds(index));
    if (type === "bath") dispatch(setFilterBath(index));
    if (type === "facilities") dispatch(setFilterFacilities(index));
    dispatch(setPageLimit(1));
  };
  const handleSliderChange = (value) => {
    setSliderValue(value);
    dispatch(setFilterAmount({ min: value[0], max: value[1] }));
  };
  const handleCheckboxChange = (property, type) => {
    if (type === "propertyType")
      dispatch(setFilterPropertyType(property.value));
    if (type === "amenities") dispatch(setFilterAmenities(property.value));
    if (type === "leaseType") dispatch(setFilterLeaseType(property.value));
    if (type === "leaseLength") dispatch(setFilterLeaseLength(property.value));
    if (type === "accessibilityFeature")
      dispatch(setFilterAccessebility(property.value));
    if (type === "smoking") dispatch(setFilterSmoking(property.value));
    if (type === "furnishType") dispatch(setFilterFurnishType(property.value));
    dispatch(setPageLimit(1));
  };

  const handleOwnerVerifyChange = () => {
    dispatch(setFilterOwnerVerify(!filterObject.ownerVerify));
  };

  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    dispatch(setPageLimit(1));
    dispatch(setFilterAvailableFrom(dateValue));
  };

  const clearAllFilters = () => {
    setSliderValue([0, 10000]);
    dispatch(setClearFilter());
  };
  const navigation = () => {
    document.body.classList.remove("innerside_filter");
  };

  return (
    <div className="wrap_left_filter">
      {/* <div className="wrap_select mb-4">
        <Form.Select
          name="gender"
          className="cstm_input_profile cstm_filter_input"
          aria-label="Default select example"
        >
          <option>All cities</option>
          <option value="male">India</option>
          <option value="female">Australia</option>
          <option value="other">Canada</option>
        </Form.Select>
        <MdOutlineLocationOn />
      </div> */}
      <div className="wrapper_clear">
        <div className="filters_cstm_close">
          <span className="me-0" onClick={navigation}>
            <RxCross2 />
          </span>
        </div>
        <div className="filters">
          <p className="font_size_24px">Filters</p>
          <span onClick={clearAllFilters}>Clear All</span>
        </div>
        <div className="range_slider">
          <p className="font_size_24px">Price Range</p>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            value={sliderValue} // Set the value using state
            min={0}
            max={10000}
            ariaLabel={["Lower thumb", "Upper thumb"]}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            renderThumb={(props, state) => <div {...props}>$</div>}
            pearling
            minDistance={10}
            onChange={handleSliderChange} // Handle slider value changes
          />
          <div className="wrap_cstm_input">
            <div className="left_input">
              <label className="font_size_16px">Min.</label>
              <input
                type="number"
                value={amount.min}
                className="cstm_input_profile cstm_filter_input"
                disabled
              />
            </div>
            <div className="right_input">
              <label className="font_size_16px">Max.</label>
              <input
                type="number"
                className="cstm_input_profile cstm_filter_input"
                value={amount.max}
                disabled
              />
            </div>
          </div>
          <div className="radio_wrap">
            <div>
              <MdVerified />
              <span className="font_size_16px ">Verified Property Owners</span>
            </div>
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={filterObject?.ownerVerify}
              onChange={handleOwnerVerifyChange}
            />
          </div>
          <div className="property_type">
            <p className="font_size_24px">Property Type</p>
            <div className="wrap_type">
              <Form>
                <div className="mb-3 wrap_checkbox">
                  {PropertyType?.map((property) => (
                    <Form.Check
                      type={"checkbox"}
                      label={property.name}
                      value={property.value}
                      checked={isSelectedProperty.includes(property.value)}
                      onChange={() =>
                        handleCheckboxChange(property, "propertyType")
                      }
                    />
                  ))}
                </div>
              </Form>
            </div>
          </div>
          <div className="wrap_filter_accordian">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="bottom-accordion-header">
                  <p className="font_size_24px">Beds</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="wrap_span_select">
                    {beds?.map((data) => (
                      <span
                        key={data.id}
                        onClick={() => toggleHandler(data.id, "beds")}
                        className={`cstm_filter_span ${
                          filterObject?.beds?.includes(data.id) ? "active" : ""
                        }`}
                      >
                        {data.name}
                      </span>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <p className="font_size_24px">Baths</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="wrap_span_select">
                    {beds.map((data) => (
                      <span
                        key={data.id}
                        onClick={() => toggleHandler(data.id, "bath")}
                        className={`cstm_filter_span ${
                          filterObject?.bath?.includes(data.id) ? "active" : ""
                        }`}
                      >
                        {data.name}
                      </span>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <p className="font_size_24px">Lease Type</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="property_type">
                    <div className="wrap_type">
                      <Form>
                        <div className="wrap_checkbox">
                          {LeaseType?.map((property) => (
                            <Form.Check
                              type={"checkbox"}
                              label={property.name}
                              value={property.value}
                              checked={isSelectedLeasetype.includes(
                                property.value
                              )}
                              onChange={() =>
                                handleCheckboxChange(property, "leaseType")
                              }
                            />
                          ))}
                        </div>
                      </Form>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <p className="font_size_24px">Furnish Type</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="property_type">
                    <div className="wrap_type">
                      <Form>
                        <div className="wrap_checkbox">
                          {FurnishingType?.map((property) => (
                            <Form.Check
                              type={"checkbox"}
                              label={property.name}
                              value={property.value}
                              checked={isSelectedFurnishType.includes(
                                property.value
                              )}
                              onChange={() =>
                                handleCheckboxChange(property, "furnishType")
                              }
                            />
                          ))}
                        </div>
                      </Form>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <p className="font_size_24px">Lease Length</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="property_type">
                    <div className="wrap_type">
                      <Form>
                        <div className="wrap_checkbox">
                          {LeaseLength?.map((property) => (
                            <Form.Check
                              type={"checkbox"}
                              label={property.name}
                              value={property.value}
                              checked={isSelectedLeaseLength.includes(
                                property.value
                              )}
                              onChange={() =>
                                handleCheckboxChange(property, "leaseLength")
                              }
                            />
                          ))}

                          <div className="datepicker">
                            <label>Availability</label>
                            <input
                              className="cstm_input_profile"
                              type="date"
                              value={isSelectedDate}
                              onChange={handleDateChange}
                              min={getCurrentDate()}
                            />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <p className="font_size_24px">Accessibility Features</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="property_type">
                    <div className="wrap_type">
                      <Form>
                        <div className="wrap_checkbox over_write_checkbox">
                          {AccessibilityFeatures?.map((property) => (
                            <Form.Check
                              type={"checkbox"}
                              label={property.name}
                              value={property.value}
                              checked={isSelectedAccesibility.includes(
                                property.value
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  property,
                                  "accessibilityFeature"
                                )
                              }
                            />
                          ))}
                        </div>
                      </Form>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <p className="font_size_24px">Smoking</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="property_type">
                    <div className="wrap_type">
                      <Form>
                        <div className="wrap_checkbox">
                          {Smoking?.map((property) => (
                            <Form.Check
                              type={"checkbox"}
                              label={property.name}
                              value={property.value}
                              checked={isSelectedSmoking.includes(
                                property.value
                              )}
                              onChange={() =>
                                handleCheckboxChange(property, "smoking")
                              }
                            />
                          ))}
                        </div>
                      </Form>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <p className="font_size_24px">Facilities</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="wrap_span_select over_write_span_select">
                    {Facilities?.map((data) => (
                      <span
                        key={data.id}
                        onClick={() => toggleHandler(data.id, "facilities")}
                        className={`cstm_filter_span ${
                          filterObject?.facilities?.includes(data.id)
                            ? "active"
                            : ""
                        }`}
                      >
                        {data.name}
                      </span>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <p className="font_size_24px">Amenities</p>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="property_type">
                    <div className="wrap_type">
                      <Form>
                        <div className="wrap_checkbox">
                          {Amenities?.map((property) => (
                            <Form.Check
                              type={"checkbox"}
                              label={property.name}
                              value={property.value}
                              checked={isSelectedAmenites.includes(
                                property.value
                              )}
                              onChange={() =>
                                handleCheckboxChange(property, "amenities")
                              }
                            />
                          ))}
                        </div>
                      </Form>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
