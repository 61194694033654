import React, { useState } from "react";
import { MdVerified } from "react-icons/md";
import { BsChatText } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getBooking } from "./bookingSlice";
import face_img from "../../assets/images/face1.png";
import CommonModal from "../modal/CommonModal";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  BOOKING_DETAIL,
  CANCEL_AND_REJECT_BOOKING,
} from "../../service/booking/bookingQuery";
import { client } from "../../service/graphql";
import { getDecryptUserDetail } from "../../helper/common";
import { useQuery } from "@apollo/client";
import { setChat } from "../chat/chatSlice";
const CancelRequest = () => {
  const dispatch = useDispatch();
  const getUser = JSON.parse(getDecryptUserDetail());
  const navigate = useNavigate();
  const param = useParams();
  const { booking } = useSelector(getBooking);
  const [showModal, setShowModal] = useState(false);
  const { owner } = booking || {};
  const handleClose = () => {
    setShowModal(false);
  };
  const { data, refetch } = useQuery(BOOKING_DETAIL, {
    variables: {
      _id: param.id,
    },
  });
  const { bookingDetails } = data || {};
  const { property, userDetail } = bookingDetails || {};

  const cancelRequest = async () => {
    try {
      refetch();
      const { data } = await client.query({
        query: CANCEL_AND_REJECT_BOOKING,
        variables: {
          input: {
            bookingId: booking._id,
            bookingStatus: "4",
          },
        },
      });
      toast.success(data?.cancelAndRejectBooking);
      navigate("/my_booking");
    } catch (error) {
      toast.error(error.message);
      if (error.message === "This booking is cancelled" || error.message === "This bookings is already confirmed") {
        navigate("/my_booking");
      }
    } finally {
      setShowModal(false);
    }
  };
  const navigateChat = () => {
    dispatch(
      setChat({
        propertyDetails: property,
        receiverId: property?.userDetail?._id,
        senderId: getUser?._id,
        receiverdata: property?.userDetail,
      })
    );
    navigate("/chat", {
      state: { detail: property },
    });
  };

  const navigateMyBooking = (type) => {
    if (type === "Cancelled") {
      navigate("/my_booking");
    }
  }

  window.addEventListener("popstate", function (event) {
    navigateMyBooking("Cancelled")
  });

  return (
    <div className="inner_chat_card wrap_booking_cancel">
      <div className="card_chat">
        <div className="wrap_chat_img">
          <figure>
            <img src={owner?.image ? owner?.image : face_img} alt="" />
          </figure>
          <div className="hosted">
            <p>Entire Property hosted by</p>
            <h5>
              {owner?.name} {userDetail?.isUserIdProofVerify && <MdVerified />}
            </h5>
          </div>
        </div>
        <div className="wrap_chat_btn">
          <button className="chat_now_btn" onClick={() => navigateChat()}>
            <BsChatText />
            Chat Now
          </button>
        </div>
      </div>
      <div className="wrap_message">
        <span>
          We are waiting for the property owner to accept your request.
        </span>
      </div>
      <div className="lease">
        <div className="wrap_detail_amount">
          <h5>Price Details</h5>
          <ul>
            {/* <li>
              <span>Accommodation</span> <span>$ 2499</span>
            </li>
            <li>
              <span>Ycasa Service fee</span> <span>$ 199</span>
            </li>
            <li>
              <span>Taxes</span> <span>$ 249</span>
            </li> */}
          </ul>
          <div className="total_amount">
            <span>Total {`(Include all taxes)`}</span>{" "}
            {/* <span>$ {booking?.totalRentAmount}</span> */}
            <span>$ {booking?.property?.amount * (booking?.leaseLength / 30)}</span>
          </div>
        </div>
      </div>
      <div className="btn_book_now">
        <button className="btn_see_more" onClick={() => setShowModal(true)}>
          Cancel Request
        </button>
      </div>
      <CommonModal
        show={showModal}
        customClass="wrap_modal"
        handleClose={handleClose}
        title="Are you sure, want to cancel your request ?"
      >
        <div className="text-center">
          <button
            className="common_btn me-4"
            style={{ maxWidth: "110px", width: "100%" }}
            onClick={() => cancelRequest()}
          >
            Yes, Cancel
          </button>

          <button
            className="common_btn"
            style={{ maxWidth: "110px", width: "100%" }}
            onClick={() => handleClose()}
          >
            No
          </button>
        </div>
      </CommonModal>
    </div>
  );
};

export default CancelRequest;
