import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Accordion } from "react-bootstrap";
import "./faq.scss";
import { add, minus } from "../../../DefaultImport";
import { useQuery } from "@apollo/client";
import { GET_FAQ } from "../../../service/faq/faqQuery";
import { FaBars, FaSearch } from "react-icons/fa";
import { NoData } from "../../../DefaultImportComponent";

const Faq = () => {
  const [selectedTab, setSelectedTab] = useState("all");
  const [allAccordionsOpen, setAllAccordionsOpen] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { data } = useQuery(GET_FAQ, {
    variables: {
      input: {
        limit: 10,
        page: 1,
        search: searchTerm,
      },
    },
  });
  const { faqlist } = data || {};
  const { list } = faqlist || {};

  const handleTabChange = (selectedTab) => {
    console.log(selectedTab)
    setSelectedTab(selectedTab);
    setAllAccordionsOpen(selectedTab === "");
  };

  const toggleAllAccordions = () => {
    setAllAccordionsOpen(!allAccordionsOpen);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };

  useEffect(() => {
    if (selectedTab === "all") {
      setFilteredList(list);
    } else {
      const filtered = list?.filter(faqItem => faqItem?.questionType === selectedTab);
      setFilteredList(filtered);
    }
  }, [selectedTab, list]);

  return (
    <div className="wrap_profile_details wrap_faq">
      <div className="head_setting">
        <div className="wrap_faq_serach_bar">
          <div className="wraptoogle_btn">
            <span className="cross_bar" onClick={addNavigationClass}>
              <FaBars className="cstm_bars" />
            </span>
            <h6>Frequently Asked Questions</h6>
          </div>
          <div className="wrap_search">
            <FaSearch />
            <input
              placeholder="Search"
              type="search"
              className="cstm_search_bar"
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div className="form_profile_set">
        <div className="wrap_faq_tabs">
          <Tabs
            id="controlled-tab-example"
            className="mb-3"
            onSelect={handleTabChange}
          >
            <Tab eventKey="all" title="All">
              <div className="wrap_accordian">
                <Accordion defaultActiveKey="0" flush>
                  {list?.map((faqItem, index) => (
                    <Accordion.Item key={faqItem.id} eventKey={String(index)}>
                      <Accordion.Header className="bottom-accordion-header">
                        <figure className="mb-0 addsign_accord">
                          <img
                            src={add}
                            alt=" "
                            onClick={toggleAllAccordions}
                          />
                        </figure>
                        <figure className="mb-0 minussign_accord ">
                          <img src={minus} alt=" " />
                        </figure>
                        <p>{faqItem.question}</p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{faqItem.answer}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                  {list?.length === 0 ? <NoData msg="No Data Found"/> : null}
                </Accordion>
              </div>
            </Tab>
            <Tab eventKey="account" title="Account Related">
              <div className="wrap_accordian">
                <Accordion defaultActiveKey="0" flush>
                  {filteredList?.map((faqItem, index) => (
                    <Accordion.Item key={faqItem.id} eventKey={String(index)}>
                      <Accordion.Header className="bottom-accordion-header">
                        <figure className="mb-0 addsign_accord">
                          <img
                            src={add}
                            alt=" "
                            onClick={toggleAllAccordions}
                          />
                        </figure>
                        <figure className="mb-0 minussign_accord ">
                          <img src={minus} alt=" " />
                        </figure>
                        <p>{faqItem.question}</p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{faqItem.answer}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                  {filteredList?.length === 0 ? <NoData msg="No Data Found"/>: null}
                </Accordion>
              </div>
            </Tab>
            <Tab eventKey="property" title="Property Related">
              <div className="wrap_accordian">
                <Accordion defaultActiveKey="0" flush>
                  {filteredList?.map((faqItem, index) => (
                    <Accordion.Item key={faqItem.id} eventKey={String(index)}>
                      <Accordion.Header className="bottom-accordion-header">
                        <figure className="mb-0 addsign_accord">
                          <img
                            src={add}
                            alt=" "
                            onClick={toggleAllAccordions}
                          />
                        </figure>
                        <figure className="mb-0 minussign_accord ">
                          <img src={minus} alt=" " />
                        </figure>
                        <p>{faqItem.question}</p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{faqItem.answer}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                  {filteredList?.length === 0 ? <NoData msg="No Data Found"/>: null}
                </Accordion>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Faq;
