import React, { useEffect, useState } from "react";
import "./auth.scss";
import { Corousel, Header } from "../../DefaultImportComponent";
import { FORGET_PASSWORD } from "../../service/auth/autMutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getMailPopupState,
  handleForgetPasswordMailPopup,
  setMailTitle,
} from "../../components/auth/authSlice";
import CommonModal from "../../components/modal/CommonModal";
import MailMessage from "../../components/auth/MailMessage";
import { spin } from "../../DefaultImport";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const mailPopup = useSelector(getMailPopupState);
  const [showModal, setShowModal] = useState(mailPopup.forgetPasswordMailPopup);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [isSelectedEmail, setIsSelectedEmail] = useState(false);
  const [validation, setValidation] = useState(false);

  const [ForgetPassword] = useMutation(FORGET_PASSWORD);
  const handleEmailChange = (event) => {
    setIsSelectedEmail(false);
    setEmail(event.target.value);
  };
  const handleClose = () => {
    dispatch(handleForgetPasswordMailPopup(false));
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoader(true);
      setValidation(true);
      if (!validateEmail(email)) {
        setIsSelectedEmail(true);
        return;
      }

      await ForgetPassword({
        variables: {
          input: {
            email: email,
          },
        },
      });
      dispatch(handleForgetPasswordMailPopup(true));
      dispatch(setMailTitle("Check Email"));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setShowModal(mailPopup.forgetPasswordMailPopup);
  }, [mailPopup.forgetPasswordMailPopup]);
  return (
    <div>
      <Header />
      <section className="wrap_auth">
        <div className="wrap_crousel_form">
          <Corousel />
          <div className="wrap_login_register_form">
            <div className="wrap_form_login">
              <h3>Forget Password</h3>
              <span className="cstm_font_increase">
                Enter the email associated with your account and we'll send an
                email with instruction to reset your account
              </span>
              <div className="wrap_tabs_pills">
                <form>
                  <div className="wrap_form_fields">
                    <label>Email</label>
                    <input
                      className="cstm_input"
                      placeholder="Enter email"
                      value={email}
                      onChange={handleEmailChange}
                    ></input>
                    {validation === true && isSelectedEmail === true && (
                      <span className="erro_img">
                        * Please enter valid email
                      </span>
                    )}
                  </div>
                  <div className="wrap_form_fields">
                    <button className="form_sbmt_btn" onClick={handleSubmit}>
                      {loader === true ? (
                        <img alt="" src={spin} />
                      ) : (
                        "Send Link"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CommonModal
        show={showModal}
        customClass="wrap_modal"
        handleClose={handleClose}
        title={mailPopup.title}
      >
        <MailMessage status={3} type="forgetPassword" id={null} />
      </CommonModal>
    </div>
  );
};

export default ForgetPassword;
