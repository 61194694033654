import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa6";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CARD_LIST } from "../../../service/booking/bookingQuery";
import { useMutation, useQuery } from "@apollo/client";
import { addLeadingZero, capitalizeFirstLetter } from "../../../helper/common";
import CommonModal from "../../../components/modal/CommonModal";
import { DELETE_RATING, SAVE_CARD } from "../../../service/booking/bookingMutaion";
import { toast } from "react-toastify";
import { spin } from "../../../DefaultImport";
import { NoData } from "../../../DefaultImportComponent";
// import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { Form } from "react-bootstrap";

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";


const SaveCard = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [SaveCards] = useMutation(SAVE_CARD);
  const [cardOpen,setCardOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [statusHolderName,setStatusHolder] = useState(false)
  const [DeleteCard] = useMutation(DELETE_RATING)
  const [cardId,setCardId] = useState(null)
  const { data,refetch } = useQuery(CARD_LIST);
  const { cardList } = data || {}
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => {
    setShowModal(false)
  }
  const [cardHolderName, setCardHolderName] = useState(null);
  const removeCard = async() => {
    try {
      setLoader(true)
      const { data } = await DeleteCard({
        variables: {
          input: {
            cardId: cardId
          }
        }
      })
      const {deleteCard} = data || {}
      if (data) {
        toast.success(deleteCard)
        setCardId(null)
        setShowModal(false)
        refetch()
      }
    } catch (error) {
        toast.error(error.message)
    } finally {
      setLoader(false)
    }
  }
  const handleCloseCard = () => {
    setCardOpen(false)
    setCardHolderName("")
    setStatusHolder(false)
  }

  const addNewCard = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);
      if (!cardHolderName) {
        setStatusHolder(true)
        return
      }
      const { error: stripeErrors, token } = await stripe.createToken(
        elements.getElement(CardNumberElement),
        {
          name: cardHolderName,
        }
      );
      if (token) {
      const {data} =  await SaveCards({
          variables: {
            input: {
              token: token.id,
            },
          },
      });
        toast.success(data?.saveCard)
        setCardOpen(false)
        setCardHolderName("")
      }
      
      refetch()
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };
  
  useEffect(() => {
    refetch()
  }, [refetch])
  
  return (
    <div className="wrap_profile_details">
      <div className="head_setting">
        <span className="cross_bar">
          <FaBars className="cstm_bars" />
        </span>
        <h6>Saved Cards</h6>
      </div>
      <div className="form_profile_set card_copy_details">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 text-end">
            <button className="common_btn" onClick={()=>setCardOpen(true)}>+ Add New Card</button>
          </div>
          {
            cardList && cardList.length>0 && cardList?.map((data) => (
              <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="wraper_card_details">
                <div className="user_name">
                    <h4>{capitalizeFirstLetter(data?.billing_details?.name) }</h4>
                    <RiDeleteBin5Line onClick={() => {
                      setShowModal(true)
                      setCardId(data?.id)
                  }}/>
                </div>
                <div className="card_number">
                    <h2>.... .... .... {data?.card?.last4 }</h2>
                </div>
                <div className="wrapper_expiry_date">
                  <div className="cvv_expiry">
                    <div className="cstm_expiry">
                      <p>Expiry Date</p>
                        <h4>{addLeadingZero(data?.card?.exp_month)}/{data?.card?.exp_year}</h4>
                    </div>
                    <div className="cstm_cvv">
                      <p>CVV</p>
                      <h4>...</h4>
                    </div>
                  </div>
                  <div className="cstm_card_type">
                      <h1>{capitalizeFirstLetter(data?.card?.brand)}</h1>
                  </div>
                </div>
              </div>
            </div>
            ))
          }
          {
            cardList?.length === 0 && <NoData msg="No Saved Cards Found"/>
          }
        </div>
      </div>
      <CommonModal
          show={showModal}
          customClass="wrap_modal"
          handleClose={handleClose}
          title="Are you sure, you want to remove card?"
        >
          <div className="text-center">
            <button
              className="common_btn me-4 form_sbmt_btn"
              style={{ maxWidth: "110px", width: "100%" }}
              onClick={removeCard}
              disabled={loader}
            >
              {loader === true ? <img alt="" src={spin} /> : "Yes"}
            </button>

            <button
              className="common_btn"
              style={{ maxWidth: "110px", width: "100%" }}
            >
              No
            </button>
          </div>
      </CommonModal>
      <CommonModal
        show={cardOpen}
        customClass="wrap_modal payment_modal_save_card"
        handleClose={handleCloseCard}
        title="Card"
      >
      <div className="row cstm_padding">
      <div className="col-lg-12 col-md-12 col-12 cstm_col_label">
          <p className="cstm_stripe_p">ADD NEW CARD</p>
          <form onSubmit={addNewCard}>
              <label>
                Card number
                <CardNumberElement
                  className="cstm_number_element"
                  options={{
                    placeholder: "4242 4242 4242 4242",
                  }}
                />
              </label>
              <div className="cstm_width_pay">
                <label className="label_1_pay">
                  Expiration date
                  <CardExpiryElement
                    options={{
                      placeholder: "MM / YY",
                    }}
                  />
                </label>
                <label className="label_2_pay">
                  CVC
                  <CardCvcElement
                    options={{
                      placeholder: "123",
                    }}
                  />
                </label>
              </div>
              <label>
                Card Holder Name
                <input
                  className=" cstm_input_profile"
                  type="text"
                  placeholder="Enter Card Holder Name"
                  name="cardHolderName"
                  value={cardHolderName}
                  onChange={(e) => {
                    setCardHolderName(e.target.value)
                    setStatusHolder(false)
                  }}
                />
               {statusHolderName === true &&
                  <span className="erro_img">Card holder name is required</span>
                }
              </label>    
              <div className="cstm_set_btn">
                <button
                  className="btn_see_more w-50 form_sbmt_btn"
                  type="submit"
                  disabled={loader}
                >
                  {loader === true ? <img alt="" src={spin} /> : "Add new card"}
                </button>
              </div>
            </form>
        </div>
      </div>
      </CommonModal>
    </div>
  );
};

export default SaveCard;
