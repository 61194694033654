import React, { useEffect, useState } from "react";
import { MdVerified } from "react-icons/md";
import { BsChatText } from "react-icons/bs";
import StarRatings from "react-star-ratings";
import { Table } from "react-bootstrap";
import CommonModal from "../modal/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import { getBooking, setFlag } from "./bookingSlice";
import face_img from "../../assets/images/face1.png";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { RATING_AND_REVIEW } from "../../service/ratingAndReview/ratingMutation";
import { setChat } from "../chat/chatSlice";
import { useNavigate } from "react-router-dom";
import { getDecryptUserDetail } from "../../helper/common";
import { spin } from "../../DefaultImport";
import moment from "moment";

const RateExperience = () => {
  const {flag} = useSelector(getBooking)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getUser = JSON.parse(getDecryptUserDetail());
  const [loader, setLoader] = useState(false);
  const [AddRatingReview] = useMutation(RATING_AND_REVIEW);
  const [showModal, setShowModal] = useState(false);
  const { booking } = useSelector(getBooking);
  const { property, transations, rivewAndRating } = booking || {};
  const { userDetail } = property || {};
  const [review, setMessage] = useState(null);
  const [bookingModal, setBookingModal] = useState(false);
  const [rating, setRating] = useState(0);
  const changeRating = (newRating) => {
    if (rivewAndRating?.length === 0) {
      setShowModal(true);
      setRating(newRating);
    }
  
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const addMessage = (e) => {
    setMessage(e.target.value);
  };

  const createRatingReview = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);
      dispatch(setFlag(flag))
      if (!rating) {
        toast.error("Rating is required");
        return;
      }
      if (!review) {
        toast.error("Review is required");
        return;
      }
      const { data } = await AddRatingReview({
        variables: {
          input: {
            propertyId: property._id,
            rating: rating,
            review: review,
          },
        },
      });
      
      if (data) {
        toast.success("success");
        setShowModal(false);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };

  const bookingRequest = () => {
    const { images, amount, address, rating, title, _id } = property;
    const detail = {
      images: images[0],
      amount: amount,
      address: address,
      rating: rating,
      title: title,
      id: _id,
    };

    navigate("/booking_request", {
      state: {
        detail: detail,
        date: moment(booking?.moveInDate).format("YYYY-MM-DD"),
        leaselength: booking?.leaseLength,
      },
    });
  };

  const closeBookingModal = () => {
    setBookingModal(false);
  };

  const navigateChat = () => {
    dispatch(
      setChat({
        propertyDetails: property,
        receiverId: property?.userDetail?._id,
        senderId: getUser?._id,
        receiverdata: property?.userDetail,
      })
    );
    navigate("/chat", {
      state: { detail: property },
    });
  };
  const navigateMyBooking = (type) => {
    if (type === "Rate") {
      navigate("/my_booking");
    }
  }

  window.addEventListener("popstate", function (event) {
    navigateMyBooking("Rate")
  });


  return (
    <div className="inner_chat_card wrap_booking_cancel wrap_payment_details">
      <div className="card_chat">
        <div className="wrap_chat_img">
          <figure>
            <img
              src={userDetail?.image ? userDetail?.image : face_img}
              alt=""
            />
          </figure>
          {
            <div className="hosted">
              <p>Entire Property hosted by</p>
              <h5>
                {userDetail?.name}{" "}
                {userDetail?.isUserIdProofVerify && <MdVerified />}
              </h5>
            </div>
          }
        </div>
        {/* <div className="wrap_chat_btn">
          <button className="chat_now_btn" onClick={navigateChat}>
            <BsChatText />
            Chat Now
          </button>
        </div> */}
      </div>
      <div className="wrap_message text-center wrap_request_message">
          {rivewAndRating?.length === 0 &&
          <p>Rate your Experience</p>

          }
            {rivewAndRating?.length > 0 &&
          <p>Your Review</p>

          }
          <div className="wrap_star_dots">
            <StarRatings
              rating={rivewAndRating?.length > 0 ? rivewAndRating[0]?.rating : rating}
              starRatedColor="#ffb229"
              starHoverColor="#fca120"
              changeRating={changeRating}
              numberOfStars={5}
              name="rating"
            />
          </div>
         
        </div>
        {rivewAndRating?.length > 0 &&
           <div className="wrap_message wrap_request_message">
           <p>Review</p>
           <span>
              {
                rivewAndRating[0]?.review
         }
           </span>
         </div>
          }
        <div className="lease mb-4">
          <div className="wrap_detail_amount">
            <h5>Payment Details</h5>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Mode</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {transations?.map((transation) => (
                  <tr>
                    <td>
                      {moment(transation?.paymentDate).format("DD MMM YYYY")}
                    </td>
                    <td>$ {transation?.paymentAmount}</td>
                    <td>
                      {transation?.paymentMode
                        ? transation?.paymentMode
                        : "N/A"}
                    </td>
                    <td
                      className={`${
                        transation?.paymentStatus ? "paid" : "pending"
                      }`}
                    >
                      {transation?.paymentStatus ? "Paid" : "Pending"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="btn_book_now">
          <button
            className="btn_see_more btn_request_cancel"
            onClick={() => setBookingModal(true)}
          >
            Request Again
          </button>
        </div>
      <CommonModal
        show={showModal}
        customClass="wrap_modal_review"
        handleClose={handleClose}
        title="Rate your Experience"
      >
        <div className="wrap_review">
          <form onSubmit={createRatingReview}>
            <div className="wrap_star_dots">
              <StarRatings
                rating={rating}
                starRatedColor="#ffb229"
                starHoverColor="#fca120"
                changeRating={changeRating}
                numberOfStars={5}
                name="rating"
              />
            </div>
            <div className="wrap_textarea">
              <p>
                Review <span>(Max 200 words)</span>
              </p>
              <textarea
                id="review"
                className="cstm_input_profile"
                name="review"
                rows="4"
                placeholder="Describe your experience..."
                value={review}
                onChange={addMessage}
              ></textarea>
            </div>
            <button type="submit" className="form_sbmt_btn mt-4">
              {loader === true ? <img alt="" src={spin} /> : "Submit"}
            </button>
          </form>
        </div>
      </CommonModal>
      <CommonModal
        show={bookingModal}
        customClass="wrap_modal"
        handleClose={closeBookingModal}
        title="Request Booking Again"
      >
        <div className="text-center">
          <div className="mb-4">
            <h5>Are you sure want to request again for this property</h5>
          </div>
          <button
            className="common_btn me-4"
            style={{ maxWidth: "110px", width: "100%" }}
            onClick={bookingRequest}
          >
            Yes
          </button>

          <button
            className="common_btn"
            style={{ maxWidth: "110px", width: "100%" }}
            onClick={()=>setBookingModal(false)}
          >
            No
          </button>
        </div>
      </CommonModal>
    </div>
  );
};

export default RateExperience;
