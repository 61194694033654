import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registerMailPopup: false,
  loginMailPopup: false,
  forgetPasswordMailPopup: false,
  title: "",
  status: "idle",
  userDetail: {
    userType: "user",
    name: "",
    email: "",
    marital_status: "",
    gender: "",
    password: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleRegisterMailPopup: (state, action) => {
      state.registerMailPopup = action.payload;
    },
    handleLoginMailPopup: (state, action) => {
      state.loginMailPopup = action.payload;
    },
    handleForgetPasswordMailPopup: (state,action) => {
      state.forgetPasswordMailPopup = action.payload
    },
    setMailTitle: (state, action) => {
      state.title = action.payload;
    },
    setUserDetail: (state, action) => {
      const { userType, name, email, marital_status, gender, password } = action.payload;
      state.userDetail = {
        userType,
        name,
        email,
        marital_status,
        gender,
        password,
      };
    },
  },
});

export const {
  handleRegisterMailPopup,
  handleLoginMailPopup,
  handleForgetPasswordMailPopup,
  setMailTitle,
  setUserDetail,
} = authSlice.actions;
export const getMailPopupState = (state) => state.auth;
export default authSlice.reducer;
