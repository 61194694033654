import React, { useState, useEffect } from "react";
import "./profile.scss";
import { camera, spin } from "../../../DefaultImport";
import { toast } from "react-toastify";
import { handleApiCall } from "../../../service/restApi";
import { useQuery } from "@apollo/client";
import { GET_USER_DETAIL } from "../../../service/auth/authQuery";
import face_img from "../../../assets/images/face1.png";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const MyProfile = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { data, refetch, error } = useQuery(GET_USER_DETAIL);
  const { getUserData } = data || {};
  const { name, email, image, marital_status, gender } = getUserData || {};
  if (error?.graphQLErrors[0]?.extensions?.response?.statusCode === 403) {
    toast.error(error?.graphQLErrors[0]?.extensions?.response?.message);
    navigate("/auth");
  }
  const [formData, setFormData] = useState({
    name: "",
    image: "",
    email: "",
    gender: "",
    marital_status: "",
  });

  const handleInputChange = (e) => {
    e.preventDefault();

    const { name, value, files } = e.target;
    if (name === "image") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();
      const form = new FormData();
      if (formData.name) form.append("name", formData.name);
      if (formData.image) form.append("image", formData.image);
      if (formData.gender) form.append("gender", formData.gender);
      if (formData.marital_status)
        form.append("marital_status", formData.marital_status);

      setLoader(true);
      const { data } = await handleApiCall("post", "/user/edit", form);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
      refetch();
    }
    // Perform the image upload logic here
  };

  useEffect(() => {
    // This will run when the component mounts
    setFormData({
      name: name || "",
      image: "",
      email: email || "",
      gender: gender || "",
      marital_status: marital_status || "",
    });
    refetch()
  }, [name, email, marital_status, gender,refetch]);

  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };

  return (
    <div className="wrap_profile_details">
      <div className="head_setting">
        <span className="cross_bar" onClick={addNavigationClass}>
          <FaBars className="cstm_bars" />
        </span>
        <h6>Personal Information</h6>
      </div>
      <div className="form_profile_set">
        <label className="user_image" htmlFor="userImage">
          <input
            type="file"
            id="userImage"
            accept="image/x-png,image/jpeg,image/jpg"
            name="image"
            className="cstm_profile_img"
            onChange={handleInputChange}
          />
          <figure className="img_profile">
            <img
              className="cstm_nav_icon"
              src={
                formData.image
                  ? URL.createObjectURL(formData.image)
                  : image || face_img
              }
              alt=" "
            />
          </figure>
          <figure className="img_cstm_prof">
            <img className="cstm_nav_icon" src={camera} alt=" " />
          </figure>
        </label>
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="wrap_form_fields">
                <label>Full Name</label>
                <input
                  name="name"
                  className="cstm_input_profile"
                  placeholder="Enter Full Name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="wrap_form_fields">
                <label>Enter email address</label>
                <input
                  name="email"
                  className="cstm_input_profile"
                  placeholder="Enter email address"
                  type="email"
                  value={formData.email}
                  disabled
                />
              </div>
            </div>
          </div>
          {/* Gender Dropdown */}
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="wrap_form_fields">
                <label>Gender</label>
                <select
                  name="gender"
                  className="cstm_input_profile"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
          </div>
          {/* Marital Status Dropdown */}
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="wrap_form_fields">
                <label>Marital Status</label>
                <select
                  name="marital_status"
                  className="cstm_input_profile"
                  value={formData.marital_status}
                  onChange={handleInputChange}
                >
                  <option value="">Select Marital Status</option>
                  <option value="1">Single</option>
                  <option value="2">Married</option>
                  <option value="3">Divorced</option>
                  <option value="4">Widowed</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-2 col-md-4 col-sm-12">
              <div className="">
                <button
                  className="common_btn w-100 form_sbmt_btn"
                  type="submit"
                >
                  {loader === true ? <img alt="" src={spin} /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyProfile;
