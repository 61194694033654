// CommonModal.js
import React from "react";
import "./modal.scss"
import { Modal } from "react-bootstrap";

const CommonModal = ({ show, handleClose, children, title,customClass }) => {
  return (
    <Modal show={show} onHide={handleClose} className={customClass} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default CommonModal;
