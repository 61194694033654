import React, { useState } from "react";
import "../../dashboard.scss";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../../service/auth/autMutation";
import { toast } from "react-toastify";
import { spin } from "../../../DefaultImport";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import CommonModal from "../../../components/modal/CommonModal";

const ChangePassword = () => {
  // State to manage form input values
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [ChangePassword] = useMutation(CHANGE_PASSWORD);
  const [validation, setInputValidation] = useState(false);
  const [pswrd_2, setPswrd_2] = useState(false);
  const [pswrd_1, setPswrd_1] = useState(false);
  const [pswrd_3, setPswrd_3] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") setPswrd_2(false);
    if (name === "confirmPassword") {
      setPswrd_3(false)
      setConfirmPasswordError(false);
    };
    if (name === "oldPassword") setPswrd_1(false);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();
      setInputValidation(true);
      setLoader(true);
      let checkStatus = false;
      const passwordRegex = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/;
      if (!formData.oldPassword) {
        setPswrd_1(true);
        checkStatus = true;
      }
      if (!passwordRegex.test(formData.newPassword)) {
        setPswrd_2(true);
        checkStatus = true;
      }
      if (!formData.confirmPassword) {
        setPswrd_3(true);
        checkStatus = true;
      }
      if (formData.newPassword !== formData.confirmPassword) {
        setConfirmPasswordError(true);
        checkStatus = true;
      }
      if (checkStatus === true) return;

      const { data } = await ChangePassword({
        variables: {
          input: formData,
        },
      });
      toast.success(data.changePassword);
      setShowModal(true);
    } catch (error) {
      toast.error(error.message);
      if (error.message === "Session expired!") {
        navigate("/auth");
      }
    } finally {
      setLoader(false);
    }
  };

  const logoutUser = () => {
    localStorage.clear();
    navigate("/auth");
  };

  return (
    <>
      <div className="wrap_profile_details">
        <div className="head_setting">
          <span className="cross_bar" onClick={addNavigationClass}>
            <FaBars className="cstm_bars" />
          </span>
          <h6>Change Password</h6>
        </div>
        <div className="form_profile_set">
          <p className="cstm_color">
            To change the password, enter the password below
          </p>
          <form onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div
                  className={`wrap_form_fields ${
                    validation === true && pswrd_1 && "cstm_error"
                  }`}
                >
                  <label>Old Password</label>
                  <input
                    name="oldPassword"
                    className="cstm_input_profile"
                    placeholder="Enter Old Password"
                    type="password"
                    value={formData.oldPassword}
                    onChange={handleInputChange}
                  />
                  {validation === true && pswrd_1 === true && (
                    <span className="erro_img">
                      * Please enter old password
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div
                  className={`wrap_form_fields ${
                    validation === true && pswrd_2 && "cstm_error"
                  }`}
                >
                  <label>New Password</label>
                  <input
                    name="newPassword"
                    className="cstm_input_profile"
                    placeholder="Enter New Password"
                    type="password"
                    value={formData.newPassword}
                    onChange={handleInputChange}
                  />
                  {validation === true && pswrd_2 === true && (
                    <span className="erro_img">
                      * Password should be alphanumeric with a minimum of 8
                      characters and include at least one special character.
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div
                  className={`wrap_form_fields ${
                    validation === true && pswrd_3 === true && "cstm_error"
                  }`}
                >
                  <label>Confirm New Password</label>
                  <input
                    name="confirmPassword"
                    className="cstm_input_profile"
                    placeholder="Enter Confirm New Password"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  {validation === true && confirmPasswordError === true && (
                    <span className="erro_img">
                      * new password and confirm password must be same
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-2 col-md-4 col-sm-12">
                <div className="">
                  <button
                    className="common_btn w-100 form_sbmt_btn"
                    type="submit"
                  >
                    {loader === true ? <img alt="" src={spin} /> : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <CommonModal
        show={showModal}
        customClass="wrap_modal cross_btn_hide"
        title="Change Password Successfully"
      >
        <div className="text-center">
          <button
            className="common_btn me-4"
            style={{ maxWidth: "110px", width: "100%" }}
            onClick={() => logoutUser()}
          >
            Go to Login
          </button>
        </div>
      </CommonModal>
    </>
  );
};

export default ChangePassword;
