import React from "react";
import { NavLink } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import { BsInfoCircle } from "react-icons/bs";
import { MdLockOutline } from "react-icons/md";
import { FaRegQuestionCircle } from "react-icons/fa";
import { PiAddressBookBold } from "react-icons/pi";
import { FiFileText } from "react-icons/fi";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

import "./sidebar.scss";

const Sidebar = () => {
  const sidebarMenu = [
    {
      id: 1,
      name: "My Profile",
      icon: <FaRegUserCircle />,
      url: "/my_profile",
    },
    {
      id: 2,
      name: "About Ycasa",
      icon: <BsInfoCircle />,
      url: "/about_us",
    },
    // {
    //   id: 3,
    //   name: "Subcriptions",
    //   icon: <TfiCrown />,
    //   url: "/subcriptions",
    // },
    {
      id: 4,
      name: "Change Password",
      icon: <MdLockOutline />,
      url: "/change_password",
    },
    {
      id: 5,
      name: "FAQ’s",
      icon: <FaRegQuestionCircle />,
      url: "/faq",
    },
    {
      id: 6,
      name: "Help & Support",
      icon: <PiAddressBookBold />,
      url: "/Help_Support",
    },
    {
      id: 7,
      name: "Privacy Policy",
      icon: <MdOutlinePrivacyTip />,
      url: "/privacy_policy",
    },
    {
      id: 8,
      name: "Terms & Conditions",
      icon: <FiFileText />,
      url: "/Terms_Conditions",
    },
    {
      id: 9,
      name: "Saved Cards",
      icon: <CiCreditCard1 />,
      url: "/save_card"
    }
  ];

  const navigation = () => {
    document.body.classList.remove("innerside_toogle");
  };
  return (
    <div className="wrapper_accont_setting">
      <div className="head_setting">
        <h6>Account Settings</h6>
        <span className="me-0" onClick={navigation}>
          <RxCross2 />
        </span>
      </div>
      <div className="wrap_nav">
        <ul type="none">
          {sidebarMenu.map((data) => (
            <li onClick={navigation}>
              <NavLink to={"/account_setting" + data.url}>
                {data.icon}
                <span>{data.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
