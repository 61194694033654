import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../chat/chat.scss";
import { File, Pdf_icon } from "../../DefaultImport";
import { socket } from "../../socket";
import { useSelector } from "react-redux";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { FaBars } from "react-icons/fa";
import face_img from "../../assets/images/face1.png";
import { spin } from "../../DefaultImport";

import { formatTimeStamp, getDecryptUserDetail, getLastIndexName } from "../../helper/common";
import { handleApiCall } from "../../service/restApi";
import { DisputeChatList, NoChatFound } from "../../DefaultImportComponent";
import { getDisputState } from "../../components/dispute/disputeSlice";
import { useQuery } from "@apollo/client";
import { GET_DISPUTE_CHAT } from "../../service/dispute/disputeQuery";
import { toast } from "react-toastify";

const DisputeChat = () => {
  const [loader, setLoader] = useState(false);
  const { bookingId, owner, property, disputeTopic,ticketId } =
    useSelector(getDisputState);
  const { data, refetch } = useQuery(GET_DISPUTE_CHAT, {
    variables: {
      input: {
        bookingId: bookingId,
        page: 1,
      },
    },
  });
  const { getDisputeChat } = data || {};
  const { disputeMessageList,dispute } = getDisputeChat || {};
  const [images, setImage] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const getUser = JSON.parse(getDecryptUserDetail());
  const scrollRef = useRef(null);
  const [msg, setMsg] = useState(null);
  const [message, setMessage] = useState([]);
  socket.on("receiveMessages", (chat) => {
    const { result } = chat;
    setMessage(result);
  });


  const inputRef = useRef(null);
  const handleChange = (e) => {
    setMsg(e.target.value);
  };

  const handleImageChange = async (e) => {
    try {
      setLoader(true)
      const formData = new FormData();
      formData.append("bookingId", bookingId);
      formData.append("disputeTopic", disputeTopic);
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("attachments", e.target.files[i]);
      }

      const { data } = await handleApiCall("post", "/dispute/add", formData);
      if (data) {
        refetch();
        
      }
    } catch (error) {
    } finally {
      setLoader(false)
    }
  };

  

  const sendMessage = async(e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append("bookingId", bookingId);
      formData.append("disputeTopic", disputeTopic);
      formData.append("description", msg)
      const { data } = await handleApiCall("post", "/dispute/add", formData);
      if (data.success === false) {
        toast.error(data.message)
        return
      }
      if (data) {
        refetch();
        
      }
      setMsg("");
    } catch (error) {
      toast.error(error.message)
    }
  };
  const openPhotoGallery = (imageArray) => {
    setOpen(true);
    if (imageArray.length > 0) {
      const updatedImageArray = imageArray.map((image) => ({
        src: image,
      }));
      setImage(updatedImageArray);
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    refetch();
  }, [message, bookingId, refetch]);

  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };

  return (
    <div className="wrap_chat_message">
      <div className="wrapside_chat">
        <DisputeChatList />
      </div>
      <div className="wrap_chat_content">
        {!disputeMessageList && <NoChatFound />}
        {disputeMessageList && (
          <div
            className="wrap_profile_details wrap_dispute_details"
            ref={scrollRef}
          >
            <div className="head_setting">
              <div className="wrap_owner">
                <div className="wrap_dispute_head">
                  <div className="wrap_toogle_icon">
                    <span className="cross_bar" onClick={addNavigationClass}>
                      <FaBars className="cstm_bars" />
                    </span>
                  </div>
                  <figure>
                    <img src={owner?.image ? owner?.image: face_img} alt="" />
                  </figure>
                  <div className="ticket_id">
                    <h6>{owner?.name}</h6>
                    <span>Ticket ID {ticketId }</span>
                  </div>
                </div>
                <div className="wrap_btn">
                  <button
                    className="head_btn"
                    onClick={() =>
                      navigate(`/property_detail/${property?._id}`)
                    }
                  >
                    View Property Details
                  </button>
                </div>
              </div>
            </div>
            <div className="form_profile_set" ref={scrollRef}>
          

              {disputeMessageList?.map((data, index) => (
                <div key={index}>
                  {data?.senderId === getUser?._id && (
                    <div className="chat_right">
                      <div className="_chat">
                        <div className="wrap_card_chat">
                          {data?.description && <span>{data?.description}</span>}
                          {data?.images?.length !== 0 &&
                            data?.images?.length >= 1 &&
                            data?.images?.length <= 3 &&
                            data?.images?.map((image) => (
                              <figure
                                className="single_img"
                                onClick={() => openPhotoGallery(data?.images)}
                              >
                                {<img src={image} alt="" />}
                              </figure>
                            ))}
                          {data?.files?.length !== 0 &&
                            data?.files?.map((image) => (
                              <Link to={image} target="blank">
                                 <div className="cstm_pdf_style">
                                <div className="wra_Pfix">
                                  <div className="wrap_pdf">
                                    <figure>
                                      <img src={Pdf_icon} alt="" />
                                    </figure>
                                  </div>
                                  <div className="details_pdf_wrap">
                                    <p>ycasa2513651246512.pdf</p>
                                  </div>
                                </div>
                              </div>
                              </Link>
                            ))}
                          {data?.images?.length !== 0 &&
                            data?.images?.length >= 4 && (
                              <div className="double_img_set">
                                {data?.images
                                  ?.slice(0, 4)
                                  .map((image, index) => (
                                    <figure
                                      className={`double_img ${
                                        index === 3 && "cstm_more_image"
                                      }`}
                                      key={index}
                                      onClick={() =>
                                        openPhotoGallery(data?.images)
                                      }
                                    >
                                      <img src={image} alt="" />
                                      <span className="image_count_cstm">
                                        {" "}
                                        + {data?.images?.length}
                                      </span>
                                    </figure>
                                  ))}
                              </div>
                            )}
                          {data?.videos?.length !== 0 && (
                            <div className="single_img">
                              {data?.videos?.map((video, videoIndex) => (
                                <video
                                  key={videoIndex}
                                  controls
                                  className="cstm_video_chat"
                                >
                                  <source src={video} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ))}
                            </div>
                          )}
                          <span className="time_card">
                            {formatTimeStamp(data?.createdAt)}
                          </span>
                        </div>
                        <figure>
                          <img src={getUser?.image ? getUser?.image :face_img} alt="" />
                        </figure>
                      </div>
                    </div>
                  )}
                   {data?.senderId !== getUser?._id && (
                    <div className="chat_left">
                      <div className="_chat">
                      <figure>
                          <img src={owner?.image ? owner?.image: face_img} alt="" />
                        </figure>
                        <div className="wrap_card_chat">
                        {data?.description && <span>{data?.description}</span>}
                          {data?.images?.length !== 0 &&
                            data?.images?.length >= 1 &&
                            data?.images?.length <= 3 &&
                            data?.images?.map((image) => (
                              <figure
                                className="single_img"
                                onClick={() => openPhotoGallery(data?.images)}
                              >
                                {<img src={image} alt="" />}
                              </figure>
                            ))}
                          {data?.files?.length !== 0 &&
                            data?.files?.map((image) => (
                              <Link to={image} target="blank">
                                 <div className="cstm_pdf_style">
                                <div className="wrap_pdf">
                                  <figure>
                                    <img src={Pdf_icon} alt="" />
                                  </figure>
                                </div>
                                <div className="details_pdf_wrap">
                                  <p>{ getLastIndexName(image)}</p>
                                </div>
                              </div>
                              </Link>
                            ))}
                          {data?.images?.length !== 0 &&
                            data?.images?.length >= 4 && (
                              <div className="double_img_set">
                                {data?.images
                                  ?.slice(0, 4)
                                  .map((image, index) => (
                                    <figure
                                      className={`double_img ${
                                        index === 3 && "cstm_more_image"
                                      }`}
                                      key={index}
                                      onClick={() =>
                                        openPhotoGallery(data?.images)
                                      }
                                    >
                                      <img src={image} alt="" />
                                      <span className="image_count_cstm">
                                        {" "}
                                        + {data?.images?.length}
                                      </span>
                                    </figure>
                                  ))}
                              </div>
                            )}
                          {data?.videos?.length !== 0 && (
                            <div className="single_img">
                              {data?.videos?.map((video, videoIndex) => (
                                <video
                                  key={videoIndex}
                                  controls
                                  className="cstm_video_chat"
                                >
                                  <source src={video} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ))}
                            </div>
                          )}
                          <span className="time_card">
                            {formatTimeStamp(data?.createdAt)}
                          </span>
                        </div>
                        
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {dispute?.disputeStatus === 0 &&
               <form>
               <div className="wrap_type">
                 <div className="wrap_flex_typeing">
                   <input
                     type="text"
                     className="cstm_input"
                     placeholder="Send a message..."
                     name="message"
                     value={msg}
                     onChange={handleChange}
                   />
                   <div className="wrap_upload">
                     <input
                       type="file"
                       className="hidden_input"
                       ref={inputRef}
                       onChange={handleImageChange}
                       accept="image/x-png,image/jpeg,image/jpg"
                       
                     />
                     <img
                       src={File}
                       alt=""
                       className="size_img"
                       onClick={() => {
                         inputRef?.current?.click();
                       }}
                     />
                     <button
                       onClick={sendMessage}
                        className="form_sbmt_btn_light form_sbmt_btn"
                        disabled={loader}
                     >
                        {loader === true ? <img alt="" src={spin} /> : "Send"}
                     </button>
                   </div>
                 </div>
               </div>
             </form>
           }
          </div>
        )}
      </div>
      <Lightbox
        open={open}
        plugins={[Thumbnails, Zoom]}
        close={() => {
          setOpen(false);
          setImage([]);
        }}
        slides={images}
      />
    </div>
  );
};

export default DisputeChat;
