import { gql } from "@apollo/client";

export const GET_USER_DETAIL = gql`
  query {
    getUserData {
      name
      email
      image
      marital_status
      gender
      status
    }
  }
`;
