import React, { useState } from "react";
import "./nodata.scss";
import { Nodata, spin } from "../../../DefaultImport";
import { useMutation } from "@apollo/client";
import { NOTIFY_PROPERTY } from "../../../service/property/propertyMutation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getFilterProperty } from "../../property/filterSlice";

const NoData = (props) => {
  const [loader,setLoader] = useState(false)
  const { coordinate } = useSelector(getFilterProperty)
  const [Notify] = useMutation(NOTIFY_PROPERTY)
  const notifyProperty = async() => {
    try {
      setLoader(true)
      const {latitude,longitude} = coordinate
      const { data } = await Notify({
        variables: {
          input: {
            latitude: latitude,
            longitude: longitude
          }
        }
      })
      const {notifyMe} = data || {}
      toast.success(notifyMe)
    } catch (error) {
      setLoader(false)
      toast.error(error.message)
    } finally {
      setLoader(false)
    }
  }
  return (
    <div className="wrapper_nodata_found">
      <figure>
        <img src={Nodata} alt="" />
      </figure>
      <h2>{props?.msg ? props?.msg : "No Property Found"}</h2>
      {!props?.msg && (
        <div className="cstm_no_property">
          <button className="nav_btn_cstms form_sbmt_btn" onClick={notifyProperty}>{loader === true ? <img alt="" src={spin} /> : "Notify Me"}</button>
        </div>
      )}
    </div>
  );
};

export default NoData;
