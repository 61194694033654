import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { toast } from "react-toastify";
import { spin } from "../../../DefaultImport";
import { handleApiCall } from "../../../service/restApi";

const PrivacyPolicy = () => {
  const [loader, setLoader] = useState(false);
  const [privacypolicyData,setPrivacyPolicyData]=useState("");
  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };


    const fetchPrivacyPolicy = async () => {
      try {
        setLoader(true);
        const response = await handleApiCall(
          "get",
          "/user/privacypolicy",
        );
        if (response.data.success === false) {
          toast.error(response.data.message);
          return;
        }
        console.log(response.data,"Response");
        setPrivacyPolicyData(response?.data);
      } catch (error) {
        toast.error(error.response.data.message[0]);
      } finally {
        setLoader(false)
      }
	};
	
	// const bodyList = () => {
	// 	const bdyClass = document.querySelector('body');
	// 	bdyClass.classList.add("removeBodyCss");
	// }

  useEffect(()=>{
	  fetchPrivacyPolicy()
	//   bodyList();
  }, []);
  useEffect(() => {
	document.body.className = 'removeBodyCss';
	return () => { document.body.className = ''; }
  });

  return (
    <div className="wrap_profile_details">
        <div className="head_setting">
        <span className="cross_bar" onClick={addNavigationClass}>
          <FaBars className="cstm_bars" />
        </span>
        <h6>Privacy Policy</h6>
      </div>
      {/* <div className="head_setting">
        <span className="cross_bar" onClick={addNavigationClass}>
          <FaBars className="cstm_bars" />
        </span>
        <h6>Privacy Policy</h6>
      </div>
      {loader === true ? <img alt="" src={spin} /> : "Save"}
      <div className="form_profile_set wrap_privacy_policy">
        <p className="cstm_color_second">Last updated on 25 Oct 2023</p>
        <h6>1. Purpose</h6>
        <p className="cstm_P_privacy">
          This privacy policy (“Privacy Policy”) describes the types of
          information Pinkai Health, we collect through online services, mobile
          and other applications and social networking platforms on which it is
          posted (together “Platform”) and how We will use, disclose and protect
          this information once it is collected. You may be able to opt-out of
          some of our uses and disclosures of your information. By visiting this
          Platform, you agree to the terms of this Privacy Policy as they may be
          amended from time to time.
        </p>
        <p className="cstm_P_privacy">
          We may share your Personal Information with third parties for their
          marketing purposes. You can elect for us not to do so by emailing us
          at info@pinaaki.com. Please make sure to include your name, mailing
          address/es and other contact details.
        </p>
        <h6>2. Effective Date</h6>
        <p className="cstm_P_privacy">
          The Terms are effective as of 02 September 2020 (“Effective Date”). It
          is important to keep personal data we hold about you accurate and
          current. Please keep your personal data on the Platform current during
          your relationship with Us.
        </p>
        <h6>3. Data we collect about you</h6>
        <p className="cstm_P_privacy">
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data). We
          may collect, use, store and transfer different ki
        </p>
      </div> */}

<div className="form_profile_set cstm_wrap_dynamic_data">
      <p className="scroll_cstm_set" dangerouslySetInnerHTML={{ __html: privacypolicyData }} />
    </div>

    </div>
  );
};

export default PrivacyPolicy;
