import React, { useEffect, useState } from "react";
import "./mywishlist.scss";
import { FaHeart, FaSearch, FaStar } from "react-icons/fa";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { MdLocationPin } from "react-icons/md";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useMutation, useQuery } from "@apollo/client";
import { GET_WISHLIST_PROPERTY } from "../../service/property/propertyQuery";
import { FaRegHeart } from "react-icons/fa6";
import { ADD_REMOVE_WISHLIST } from "../../service/property/propertyMutation";
import { GET_JSON_DATA } from "../../service/home/homeQuery";
import { filterJsonData } from "../../helper/common";
import { NoData } from "../../DefaultImportComponent";
import { GoDotFill } from "react-icons/go";
import face_img from "../../assets/images/face1.png";
import PropertyListShimmer from "../../components/shimmer/PropertyListShimmer";

const MywishList = () => {
  const [page, setPage] = useState(1);
  const [AddRemoveWishList] = useMutation(ADD_REMOVE_WISHLIST);
  const { FurnishingType } = GET_JSON_DATA || {};
  const [search, setSearch] = useState(null);
  const { data, refetch, loading } = useQuery(GET_WISHLIST_PROPERTY, {
    variables: {
      input: {
        page: page,
        limit: 8,
        search: search,
      },
    },
  });

 
  const addRemoveWishList = async (propertyId) => {
    await AddRemoveWishList({
      variables: {
        input: {
          propertyId: propertyId,
        },
      },
    });
    refetch();
  };

  const { myWishList } = data || {};
  const { list, total } = myWishList || {}
  const pageCount = Math.ceil((total || 0) / 6);

  const prevPage = () => {
    if (page > 1) setPage(page - 1);
  };
  const nextPage = () => {
    if (page < pageCount) setPage(page + 1);
  };
  const handlePageChange = (event) => {
    if (!event.target.value || event.target.value > pageCount) {
      setPage(1);
    } else {
      setPage(Number(event.target.value));
    }
  };
  const testcrousal = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const searchWishListPropery = (e) => {
    setSearch(e.target.value);
  };
  const array = [1, 2, 3, 4, 5, 6, 7, 8]
  useEffect(() => {
    refetch()
  },[refetch])

  return (
    <div className="wrapper_wishlist">
      <div className="row mb-4 cstm_row_whislist">
        <div className="col-lg-4 col-md-4">
          <div className="wrap_heading">
            <h5>My Wishlist</h5>
          </div>
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="wrap_search_bar">
            <input
              type="search"
              className="cstm_input"
              placeholder="Search your favourite properties..."
              onChange={searchWishListPropery}
            />
            <FaSearch className="cstm_nav_icon" />
            <button className="nav_btn_cstms">
              {" "}
              <FaSearch /> Search
            </button>
          </div>
        </div>
      </div>
  
      <div className="row position_set_pagination">
      {loading && array?.map(() => (
        <PropertyListShimmer/>
      ))}
        {list?.length === 0 && <NoData msg="No Property Found" />}
        {list?.map((property) => (
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="wrap_listing_card ">
              <div className="recommend_list">
                <Slider {...testcrousal}>
                  {property?.Property?.images?.map((image) => (
                    <figure>
                      <Link to={"/property_detail/" + property?.Property?._id}>
                        <img alt="" src={image}></img>
                      </Link>
                      <span>House</span>
                      {property?.Property?.isWishlist === true ? (
                        <FaHeart
                          onClick={() =>
                            addRemoveWishList(property?.Property._id)
                          }
                          className="cstm_fill"
                        />
                      ) : (
                        <FaRegHeart
                          onClick={() =>
                            addRemoveWishList(property?.Property._id)
                          }
                          className="cstm_unfill"
                        />
                      )}
                    </figure>
                  ))}
                </Slider>
                <div className="cstm_wrap_card_list">
                  <div className="wrap_value">
                    <b>
                      $ {property?.Property?.amount}
                      <span>/Month</span>
                    </b>
                    {property?.Property?.rating > 0 &&
                       <span className="wrap_rating">
                       <FaStar />
                       {property?.Property?.rating}
                     </span>
                   }
                  </div>
                  <span>
                    {property?.Property?.beds} Beds | {property?.Property?.bath}{" "}
                    Baths <GoDotFill className="dot_cstm_fill" />{" "}
                    {FurnishingType &&
                      filterJsonData(
                        FurnishingType,
                        property?.Property?.furnishingType
                      ).name}
                  </span>
                  <span className="property_location">
                    <MdLocationPin /> {property?.Property?.address}{" "}
                  </span>
                  <div className="wrap_dealer_name">
                    <figure>
                      <img
                        src={
                          property?.Property?.userDetail?.image ||
                          face_img
                        }
                        alt=""
                        className="wrap_dealer"
                      />
                    </figure>
                    <div className="name_wrap">
                      <p>
                        <b>{property?.Property?.title}</b>
                      </p>
                      <span>{property?.Property?.userDetail?.name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {!loading && list?.length !== 0 && (
          <div className="wrap_pagination">
            <div className="wrap_input_no">
              <span> Page </span>&nbsp;
              <input
                className="wrap_input_pagination"
                type="number"
                min={1}
                value={page}
                  onChange={(e) => handlePageChange(e)}
              />
              &nbsp; <span> Of {pageCount} </span>
            </div>
            <div className="wrap_next_prev">
              <button
                className="btn_see_more_pagination me-4"
                  onClick={() => prevPage()}
              >
                <MdKeyboardArrowLeft /> Previous
              </button>
              <button
                className="btn_see_more_pagination next_btn"
                  onClick={() => nextPage()}
              >
                Next Page <MdKeyboardArrowRight />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MywishList;
