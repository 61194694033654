import React from "react";
import { Noimg } from "../../DefaultImport";
import { FaBars } from "react-icons/fa";

const NoChatFound = () => {
  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };
  return (
    <div className="fresh_page_chat">
      <div className="wrap_toogle_icon">
        <span className="cross_bar" onClick={addNavigationClass}>
          <FaBars className="cstm_bars" />
        </span>
      </div>
      <figure className="img_set_chat">
        <img src={Noimg} alt="" />
      </figure>
      <div>
        <h5>No Conversation Yet</h5>
        <p>when you have message you'll see them here</p>
      </div>
    </div>
  );
};

export default NoChatFound;
