import React, { useEffect, useState } from "react";
import "./subscription.scss";
import { NavLink } from "react-router-dom";
import { right_checkbox } from "../../../DefaultImport";
import { FaBars } from "react-icons/fa";

const Subscription = () => {
  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };
  return (
    <div className="wrap_profile_details">
      <div className="head_setting">
        <span className="cross_bar" onClick={addNavigationClass}>
          <FaBars className="cstm_bars" />
        </span>
        <h6>My Subscriptions</h6>
      </div>
      <div className="form_profile_set wrap_subscription">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="wrap_card_subsription">
              <NavLink>
                <span className="plan_tittle">CURRENT PLAN</span>
                <h4>
                  <span className="cstm_dolar">$</span> 0.
                  <span className="cstm_zero">00</span>
                </h4>
                <h5>Free</h5>
                <ul>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>Search properties and check details</p>
                  </li>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>Mark properties as favourite</p>
                  </li>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>Manage profiles and apply settings</p>
                  </li>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>Check owner detail page except contact details </p>
                  </li>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>
                      Content based features and options like FAQ's and more.
                    </p>
                  </li>
                </ul>
              </NavLink>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="wrap_card_subsription">
              <NavLink>
                <span className="plan_tittle">RECOMMENDED</span>
                <h4>
                  <span className="cstm_dolar">$</span>149.
                  <span className="cstm_zero">00</span>
                </h4>
                <h5>Super</h5>
                <ul>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>All features available in free plan</p>
                  </li>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>Chat and communication with owner</p>
                  </li>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>Book properties and related functionality</p>
                  </li>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>Raise a dispute and get refund </p>
                  </li>
                  <li>
                    <img src={right_checkbox} alt="" />
                    <p>View owner profile including contact details</p>
                  </li>
                </ul>
              </NavLink>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="">
                <button
                  className="common_btn cstm_free_plan w-100"
                  type="button"
                >
                  Continue to Check out
                </button>
                <button className="common_btn w-100" type="button">
                  Continue to Check out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
