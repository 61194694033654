import React, { useState } from "react";
import { MdVerified } from "react-icons/md";
import { BsChatText } from "react-icons/bs";
import face_img from "../../assets/images/face1.png";
import { getBooking } from "./bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { setChat } from "../chat/chatSlice";
import { getDecryptUserDetail } from "../../helper/common";
import CommonModal from "../modal/CommonModal";

const RequestAgain = () => {
  const param = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { booking } = useSelector(getBooking);
  const getUser = JSON.parse(getDecryptUserDetail());
  const [showModal, setShowModal] = useState(false);
  const { property } = booking || {};
  const { userDetail } = property || {}
  const bookingRequest = () => {
    const { images, amount, address, rating, title, _id } = property;

    const detail = {
      images: images[0],
      amount: amount,
      address: address,
      rating: rating,
      title: title,
      id: _id,
    };

    navigate("/booking_request", {
      state: {
        detail: detail,
        date: moment(booking?.moveInDate).format('YYYY-MM-DD'),
        leaselength: booking?.leaseLength,
      },
    });
  };

  const navigateChat = () => {
    dispatch(
      setChat({
        propertyDetails: property,
        receiverId: property?.userDetail?._id,
        senderId: getUser?._id,
        receiverdata: property?.userDetail,
      })
    );
    navigate("/chat", {
      state: { detail: property },
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const navigateMyBooking = (type) => {
    if (type === "Rejected") {
      navigate("/my_booking");
    }
  }

  window.addEventListener("popstate", function (event) {
    navigateMyBooking("Rejected")
  });


  return (
    <div className="inner_chat_card wrap_booking_cancel">
      <div className="card_chat">
        <div className="wrap_chat_img">
          <figure>
          <img src={userDetail?.image ? userDetail?.image: face_img} alt="" />
          </figure>
          <div className="hosted">
            <p>Entire Property hosted by</p>
            <h5>
              {userDetail?.name} {userDetail?.isUserIdProofVerify && <MdVerified />}
            </h5>
          </div>
        </div>
        <div className="wrap_chat_btn">
          <button className="chat_now_btn" onClick={navigateChat}>
            <BsChatText />
            Chat Now
          </button>
        </div>
      </div>
      {param?.type.toLowerCase() === "rejected" &&
           <div className="wrap_message wrap_request_message">
           <p>Reason for rejection</p>
           <span>
             {booking?.reasonOfRejectionBooking}
           </span>
         </div>
   }
      <div className="lease">
        <div className="wrap_detail_amount">
          <h5>Price Details</h5>
          <ul>
            {/* <li>
              <span>Accommodation</span> <span>$ 2499</span>
            </li>
            <li>
              <span>Ycasa Service fee</span> <span>$ 199</span>
            </li>
            <li>
              <span>Taxes</span> <span>$ 249</span>
            </li> */}
          </ul>
          <div className="total_amount">
            <span>Total { `(Include all taxes)`}</span> <span>$ {booking?.totalRentAmount}</span>
          </div>
        </div>
      </div>
      <div className="btn_book_now">
        <button
          className="btn_see_more btn_request_cancel"
          onClick={() => setShowModal(true)}
        >
          Request Again
        </button>
      </div>
      <CommonModal
      show= {showModal}
      customClass="wrap_modal"
      handleClose={handleClose}
      title="Request Booking Again"
      >
      
        <div className="text-center">
          <div className="mb-4">
            <h5>Are you sure want to request again for this property</h5>
          </div>
            <button
              className="common_btn me-4"
            style={{ maxWidth: "110px", width: "100%" }}
            onClick={bookingRequest}
            >
              Yes
            </button>

            <button
              className="common_btn"
              style={{ maxWidth: "110px", width: "100%" }}
            >
              No
            </button>
          </div>
      </CommonModal>
    </div>
  );
};

export default RequestAgain;
