import React from "react";
import "./header.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { HeaderLogo } from "../../../DefaultImport";
import { setLogin, setRegister } from "./headerSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateAuthPage = (stateCallback) => {
    dispatch(stateCallback());
    navigate("/auth");
  };

  return (
    <header>
      <div className="wrapper_header">
        <div className="Custom_container">
          {["md"].map((expand) => (
            <Navbar
              key={expand}
              expand={expand}
              className="bg-body-tertiary mb-3"
            >
              <Navbar.Brand >
                <Link to="/">
                <img src={HeaderLogo} alt=" logo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                className="mobile_menu"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    YCASA
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    {/* <Nav.Link to="#action1">About us</Nav.Link> */}
                    {/* <Nav.Link to="#action4">Contact us</Nav.Link> */}
                  </Nav>
                  <div className="wrapper_nav_btn">
                    <button
                      className="nav_btn me-3"
                      onClick={() => navigateAuthPage(setLogin)}
                    >
                      Log in
                    </button>
                    <button
                      className="nav_btn_cstm"
                      onClick={() => navigateAuthPage(setRegister)}
                    >
                      Register
                    </button>
                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
