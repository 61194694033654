import React from "react";

const PropertyDetailShimmer = () => {
  return (
    <div className="wrap_image_property">
      <div className={`row shimmer-container`}>
        <div className="col-lg-6 col-md-6 padding-right_0 ">
          <div className="wrap_property_section shimmer">
            <figure className={`large_property`}></figure>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 padding_right_add">
          <div className="row margin_top">
            <div className="col-lg-6 col-md-6 col-sm-6 padding-right_0">
              <div className="wrap_property_section_half ">
                <figure className={`large_property shimmer`}></figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 ">
              <div className="wrap_property_section_half ">
                <figure className={`large_property shimmer`}></figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 padding-right_0">
              <div className="wrap_property_section_half">
                <figure className={`large_property shimmer`}></figure>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="wrap_property_section_half">
                <figure className={`large_property shimmer`}>
                  <span className="tag_span"></span>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="propert_detailing_list shimmer-container">
        <div className="row">
          <div className="col-lg-8">
            <div className="wrap_inner_card">
              <div className="cstm_flex shimmer">
                <div className="location">
                  <h5>
                    <span className="shimmer"></span>
                  </h5>
                  <span className="location_tag"></span>
                </div>
                <div className="amount">
                  <h5></h5>
                </div>
              </div>
              <div className="cstm_ul shimmer">
                <ul>
                  <li className="shimmer"></li>
                  <li className="shimmer"></li>
                  <li className="shimmer"> </li>
                  <li className="shimmer"> </li>
                </ul>
                <span className="cstm_btn"></span>
              </div>
              <div className="about_property shimmer"></div>
              <div className="wrap_overview shimmer">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                    <div className="wrap_over_card"></div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                    <div className="wrap_over_card"></div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                    <div className="wrap_over_card">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrap_overview wrap_facilities shimmer">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 mb-4">
                    <div className="wrap_over_card">
                      <div className="wrap_img_txt">
                        <figure></figure>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrap_overview wrap_facilities shimmer">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 mb-4">
                    <div className="wrap_over_card">
                      <div className="wrap_img_txt">
                        <figure className="shimmer"></figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrap_overview wrap_ratings">
                <div className="wrap_div shimmer">
                  <div>
                    <span className="location_tag"></span>
                  </div>
                  <div className="cstm_select"></div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="wrap_review shimmer">
                      <span></span>
                      <div className="wrap_review_user">
                        <figure></figure>
                        <div className="wrap_review_star">
                          <p></p>
                          <div className="wrap_star_dots">
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_see_wrapper ">
                    <button className="btn_see_more shimmer"></button>
                  </div>
                </div>
              </div>
              <div className="wrap_overview wrap_Things ">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 ">
                    <div className="wrap_things shimmer">
                      <p></p>
                      <span></span>
                    </div>
                    {/* <div className="btn_see_wrapper">
                        <button className="btn_see_more">Learn more</button>
                      </div> */}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 top_space">
                    <div className="wrap_things shimmer">
                      <p></p>
                      {/* <span>
                          We ask every guest to remember a few simple things
                          about what makes a great guest.
                        </span> */}
                      <ul></ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="inner_chat_card">
              <div className="card_chat shimmer">
                <div className="wrap_chat_img">
                  <figure className="shimmer"></figure>
                  <div className="hosted">
                    <p className="shimmer"></p>
                    <h5></h5>
                  </div>
                </div>
                <div className="wrap_chat_btn"></div>
              </div>
              <div className="lease">
                <div className="wrap_label"></div>
                <div className="wrap_datePicker"></div>
              </div>
              <div className="btn_book_now">
                <button className="btn_see_more shimmer"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailShimmer;
