import React, { useEffect, useState } from "react";
import "../../dashboard.scss";
import "./about_us.scss";
import {
  HeaderLogo,
  about_us_img,
  about_us_side_img,
} from "../../../DefaultImport";
import { FaBars } from "react-icons/fa";
import { handleApiCall } from "../../../service/restApi";
import { toast } from "react-toastify";
import { spin } from "../../../DefaultImport";

const AboutYcasa = () => {
  const [aboutUs, setAboutUs] = useState("");
  const [loader, setLoader] = useState(false);
  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };

  const fetchAboutUs = async () => {
    try {
      setLoader(true);
      const response = await handleApiCall("get", "/user/aboutus");
      if (response.data.success === false) {
        toast.error(response.data.message);
        return;
      }
      // console.log(response.data,"Response");
      setAboutUs(response?.data);
    } catch (error) {
      toast.error(error.response.data.message[0]);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);

  useEffect(() => {
    document.body.className = 'removeBodyCss';
    return () => { document.body.className = ''; }
    });

  return (
    <div className="wrap_profile_details wrap_about_us_wrap">
      <div className="head_setting">
        <span className="cross_bar" onClick={addNavigationClass}>
          <FaBars className="cstm_bars" />
        </span>
        <h6>About Ycasa</h6>
      </div>
      {/* <div className="form_profile_set">
        <figure className="about_us_profile">
          <img src={HeaderLogo} alt="" />
        </figure>
        <p className="cstm_P_about_us">
          Lorem ipsum dolor sit amet, consetetur deed segeg sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
          aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren.
        </p>
        <figure className="about_us_img">
          <img src={about_us_img} alt="" />
        </figure>
        <p className="cstm_P_about_us">
          Lorem ipsum dolor sit amet, consetetur deed segeg sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
          aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit
          amet, consetetur deed segeg sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
          voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
          Stet clita kasd gubergren.
        </p>
        <div className="row">
          <div className="col-lg-3">
            <figure className="wrap_about_side_img">
              <img src={about_us_side_img} alt="" />
            </figure>
          </div>
          <div className="col-lg-9">
            <p className="cstm_P_about_us">
              Lorem ipsum dolor sit amet, consetetur deed segeg sadipscing
              elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
              magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
              justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem
              ipsum dolor sit amet, consetetur deed segeg sadipscing elitr, sed
              diam nonumy eirmod tempor invidunt ut labore et dolore magna
              aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
              duo dolores et ea rebum. Stet clita kasd gubergren.
            </p>
          </div>
        </div>

        <p className="cstm_P_about_us">
          Lorem ipsum dolor sit amet, consetetur deed segeg sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
          aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit
          amet, consetetur deed segeg sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
          voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
          Stet clita kasd gubergren.
        </p>
        <figure className="about_us_img">
          <img src={about_us_img} alt="" />
        </figure>
        <p className="cstm_P_about_us">
          Lorem ipsum dolor sit amet, consetetur deed segeg sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
          aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
          dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit
          amet, consetetur deed segeg sadipscing elitr, sed diam nonumy eirmod
          tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
          voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
          Stet clita kasd gubergren.
        </p>
      </div> */}

      <div className="form_profile_set cstm_wrap_dynamic_data">
        <p
          className="scroll_cstm_set"
          dangerouslySetInnerHTML={{ __html: aboutUs }}
        />
      </div>
    </div>
  );
};

export default AboutYcasa;
