import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  AllBooking,
  PendingBooking,
  AcceptedBooking,
  RejectedBooking,
  CancelledBooking,
} from "../../DefaultImportComponent";
const RequestedBooking = () => {
  return (
    <div className="wrap_booking_heading">
      <div className="wrap_faq_tabs">
        <Tabs
          defaultActiveKey="all"
          id="controlled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="all" title="All">
            <AllBooking />
          </Tab>
          <Tab eventKey="pending" title="Pending">
            <PendingBooking />
          </Tab>
          <Tab eventKey="accepted" title="Accepted">
            <AcceptedBooking />
          </Tab>
          <Tab eventKey="rejected" title="Rejected">
            <RejectedBooking />
          </Tab>
          <Tab eventKey="cancelled" title="Cancelled">
            <CancelledBooking />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default RequestedBooking;
