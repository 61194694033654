import { gql } from "@apollo/client";

export const BOOKING_LIST = gql`
  query GetBookingList($input: BookingListDTO!) {
    userAndOwnerBookingList(input: $input) {
      bookingList {
        owner {
          name
          image
        }
        property {
          _id
          title
          beds
          address
          furnishingType
          houseNumber
          description
          postalCode
          leaselength
          smoking
          accessibilityFeature
          propertyType
          propertyRule
          facilities
          amenities
          images
          bath
          rating
          amount
          userDetail {
            name
            image
          }
        }
        bookingNumber
        bookingStatus
        monthlyRentOfproperty
        moveInDate
        _id
      }
      totalCount
    }
  }
`;

export const BOOKING_DETAIL = gql`
  query BookingDetails($_id: String!) {
    bookingDetails(_id: $_id) {
      property {
        _id
        title
        beds
        address
        furnishingType
        houseNumber
        description
        postalCode
        leaselength
        leaseType
        smoking
        accessibilityFeature
        propertyRule
        facilities
        amenities
        images
        bath
        rating
        amount
        userDetail {
          name
          image
          isUserIdProofVerify
          _id
        }
      }
      reasonOfRejectionBooking
      owner {
        name
        image
      }
      transations{
        _id
        paymentMode
        paymentDate
        paymentStatus
        paymentAmount
        transactionId
      }
      rivewAndRating{
        _id
        propertyId
        rating
        review
        user{
          _id
        }
      }
      leaseLength
      contractDocument
      bookingNumber
      bookingStatus
      moveInDate
      totalRentAmount
      _id
    }
  }
`;

export const CANCEL_AND_REJECT_BOOKING = gql`
  query CancelRejectBooking($input: UpdateBookingStatusDTO!) {
    cancelAndRejectBooking(input: $input)
  }
`;

export const CANCELLATION_POLICY_LIST = gql`
  query CancellationPolicyList($input: ListCancellationPolicyDTO!){
    cancellationPolicyList(input: $input){
      list{
        _id
        cancelPolicy
      }
      total
    }
  }
`;

export const CARD_LIST = gql`
  query CardList{
    cardList{
      billing_details{
        email
        name
        phone
      }
      card{
        brand
        exp_month
        exp_year
        funding
        last4
      }
      type
      id
    }
  }
`