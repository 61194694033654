import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_USER } from "../../service/auth/autMutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import CommonModal from "../modal/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getMailPopupState,
  handleLoginMailPopup,
  setMailTitle,
  setUserDetail,
} from "./authSlice";
import MailMessage from "./MailMessage";
import { setEncryptUserDetail, setToken } from "../../helper/common";
import { spin } from "../../DefaultImport";
import { requestForToken } from "../../firebase";
import { getTabState } from "../shared/header/headerSlice";

const Login = () => {
  const tabState = useSelector(getTabState)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const mailPopup = useSelector(getMailPopupState);
  const [showModal, setShowModal] = useState(mailPopup.loginMailPopup);
  const [loader, setLoader] = useState(false)
  const [id, setId] = useState(null);
  const [key, setKey] = useState(0);
  const [validation, setInputValidation] = useState(false)
  const [emailStatus, setEmailStatus] = useState(false)
  
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    reqType: "user"

  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [Login] = useMutation(LOGIN_USER);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "email") setEmailStatus(false)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setInputValidation(true)
      setLoader(true)
      if (!validateEmail(formData.email)) {
        setEmailStatus(true)
        return
      }
      if(!formData.email || !formData.password ) return
      const { data } = await Login({
        variables: {
          input: formData,
        },
      });
      if (data.loginUser.mailVerify === false) {
        dispatch(handleLoginMailPopup(true));
        setKey(2);
        dispatch(setMailTitle("Check Mail"));
        dispatch(setUserDetail(formData));

        setId(data.loginUser.user._id);
        return;
      }
      if (data) {
        setEncryptUserDetail(JSON.stringify(data.loginUser.user))
        navigate("/property_list")
        setToken(data.loginUser.access_token);

      }
    } catch (error) {
     toast.error(error.message)
    } finally {
      setLoader(false)
    }
  };

  const handleClose = () => {
    dispatch(handleLoginMailPopup(false));
  };
  const getFcmToken = async() => {
    const token = await requestForToken()
    setFormData((prevData) => ({
      ...prevData,
      fcmToken:  token
    }));
  }
  useEffect(() => {
    setShowModal(mailPopup.loginMailPopup);
    getFcmToken()
    setInputValidation(false)
  }, [mailPopup.loginMailPopup,tabState]);


  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="wrap_form_fields">
          <label>Email Address</label>
          <input
            name="email"
            className="cstm_input"
            placeholder="Enter email address"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
          {validation === true && emailStatus === true &&
          <span className="erro_img">* Please enter valid email</span>
          }
        </div>
        <div className={`wrap_form_fields ${validation === true && !formData.password && "cstm_error"}`}>
          <label>Password</label>
          <input
            name="password"
            type={showPassword ? "text" : "password"}
            className="cstm_input"
            placeholder="Enter Password"
            value={formData.password}
            onChange={handleChange}
          />
          <span className="cstm_icons_set" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </span>
          {validation === true && !formData.password &&
          <span className="erro_img">* Password is Required</span>
          }
        </div>
        <div className="wrap_form_fields">
          <Link to="/auth/forget_password">Forgot Password?</Link>
        </div>
        <div className="wrap_form_fields">
          <button type="submit" className="form_sbmt_btn">
            {loader === true ? <img alt="" src={spin} /> : "Login"}
          </button>
        </div>
      </form>
      <CommonModal
        show={showModal}
        customClass="wrap_modal"
        handleClose={handleClose}
        title={mailPopup.title}
      >
        <MailMessage status={key} type="login" id={id} />
      </CommonModal>
    </div>
  );
};

export default Login;
