import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { FaHeart, FaStar } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { GET_JSON_DATA, HOME_PAGE } from "../../service/home/homeQuery";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { useMutation, useQuery } from "@apollo/client";
import { createFilterObject, filterJsonData } from "../../helper/common";
import { useDispatch, useSelector } from "react-redux";
import face_img from "../../assets/images/face1.png";

import {
  getFilterProperty,
  setClearFilter,
  setPageLimit,
  setSearchFilter,
} from "../../components/property/filterSlice";
import { ADD_REMOVE_WISHLIST } from "../../service/property/propertyMutation";
import PropertyListShimmer from "../shimmer/PropertyListShimmer";
import { NoData } from "../../DefaultImportComponent";
import { toast } from "react-toastify";
import CommonModal from "../modal/CommonModal";

const ListProperty = () => {
  const array = [1, 2, 3, 4, 5, 6,7,8,9];
  const dispatch = useDispatch();
  const [showModal,setShowModal] = useState(false)
  const navigate = useNavigate();
  const [AddRemoveWishList] = useMutation(ADD_REMOVE_WISHLIST);
  const { filterObject, page, coordinate } = useSelector(getFilterProperty);
  const { data: jsonData } = useQuery(GET_JSON_DATA);
  const { getJsonData } = jsonData || {};
  const { FurnishingType } = getJsonData || {};

  const { data, refetch, loading, error } = useQuery(HOME_PAGE, {
    variables: {
      input: {
        latitude: coordinate?.latitude,
        longitude: coordinate?.longitude,
        userType: "user",
        limit: 9,
        page: page,
        filter: createFilterObject(filterObject),
      },
    },
  });
  if (error?.graphQLErrors[0]?.extensions?.response?.statusCode === 403) {
    toast.error(error?.graphQLErrors[0]?.extensions?.response?.message);
    navigate("/auth");
  }
  const { homePage } = data || {};
  const { property, total } = homePage || {};
  const pageCount = Math.ceil((total || 0) / 9);
  const testcrousal = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const prevPage = () => {
    if (page > 1) dispatch(setPageLimit(page - 1));
  };
  const nextPage = () => {
    if (page < pageCount) {
      dispatch(setPageLimit(page + 1));
    }
  };

  const handlePageChange = (event) => {
    if (!event.target.value || event.target.value > pageCount) {
      dispatch(setPageLimit(1));
    } else {
      dispatch(setPageLimit(Number(event.target.value)));
    }
  };
  const addRemoveWishList = async (propertyId) => {
    await AddRemoveWishList({
      variables: {
        input: {
          propertyId: propertyId,
        },
      },
    });
    refetch();
  };

  useEffect(() => {
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(
            setSearchFilter({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          );
          localStorage.setItem(
            "coordinate",
            JSON.stringify({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          );
        },
        (error) => {
          setShowModal(true)
          // dispatch(
          //   setSearchFilter({
          //     latitude: 28.623150464683047,
          //     longitude: 77.38004955767013,
          //   })
          // );
          // localStorage.setItem(
          //   "coordinate",
          //   JSON.stringify({
          //     latitude: 28.623150464683047,
          //     longitude: 77.38004955767013,
          //   })
          // );
        }
      );
    } else {
      // Geolocation is not supported
      console.error("Geolocation is not supported by your browser.");
    }
    refetch()
    return () => {
      dispatch(setClearFilter())
    }
  }, []);

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      {/* <NoData /> */}
      <div className="wrap_cards_filters_property">
        <div className="row position_set_pagination">
        {loading && array.map(() => <PropertyListShimmer />)}

        {property?.length === 0 ? <NoData /> : null}
          {property?.map((property) => (
            <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
              <div className="wrap_listing_card">
                <div className="recommend_list">
                  <Slider {...testcrousal}>
                    {property?.images?.map((image) => (
                      <figure>
                        <Link to={"/property_detail/" + property?._id}>
                          <img alt="" src={image}></img>
                        </Link>
                        <span>{property?.propertyType === "1" ? "House" : "Condo" }</span>
                        {property?.isWishlist === true ? (
                          <FaHeart
                            onClick={() => addRemoveWishList(property._id)}
                            className="cstm_fill"
                          />
                        ) : (
                          <FaRegHeart
                            onClick={() => addRemoveWishList(property._id)}
                            className="cstm_unfill"
                          />
                        )}
                      </figure>
                    ))}
                  </Slider>
                  <div className="cstm_wrap_card_list">
                    <div className="wrap_value">
                      <b>
                        $ {property?.amount}
                        <span>/Month</span>
                      </b>
                      { property?.rating > 0 &&
                         <span className="wrap_rating">
                         <FaStar />
                         {property?.rating}
                       </span>
                     }
                    </div>
                    <span>
                      {property?.beds} Beds | {property?.bath} Baths{" "}
                      <GoDotFill className="dot_cstm_fill" />{" "}
                      {FurnishingType &&
                        filterJsonData(FurnishingType, property?.furnishingType)
                          .name}
                    </span>
                    <span className="property_location">
                      <MdLocationPin /> {property?.address?.slice(0,20)}{" "}
                    </span>
                    <div className="wrap_dealer_name">
                      <figure>
                        <img
                          src={property?.userDetail?.image || face_img}
                          alt=""
                          className="wrap_dealer"
                        />
                      </figure>
                      <div className="name_wrap">
                        <p>
                          <b>{property?.title}</b>
                        </p>
                        <span>{property?.userDetail?.name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {!loading && property?.length !== 0 && (
            <div className="wrap_pagination">
              <div className="wrap_input_no">
                <span> Page </span>&nbsp;
                <input
                  className="wrap_input_pagination"
                  type="number"
                  min={1}
                  max={pageCount}
                  value={page}
                  onChange={(e) => handlePageChange(e)}
                />
                &nbsp; <span> Of {pageCount} </span>
              </div>
              <div className="wrap_next_prev">
                <button
                  className="btn_see_more_pagination me-4"
                  onClick={() => prevPage()}
                >
                  <MdKeyboardArrowLeft /> Previous
                </button>
                <button
                  className="btn_see_more_pagination next_btn"
                  onClick={() => nextPage()}
                >
                  Next Page <MdKeyboardArrowRight />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <CommonModal
        show={showModal}
        customClass="wrap_modal"
        handleClose={handleClose}
        title="Location Permission Required"
      >
        <div className="class_wrap">
        <div className="row">
         
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="wrap_modal_txt">
              <span>
                To view the listed properties, enbale the location permissions.
              </span>
             
              
            </div>
          </div>
        </div>
      </div>
      </CommonModal>
    </>
  );
};

export default ListProperty;
