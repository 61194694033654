import React from "react";

const PropertyListShimmer = () => {
  return (
    <div className="col-lg-4 col-md-4 col-sm-4 mb-4 ">
      <div className="wrap_listing_card shimmer-container">
        <div className="recommend_list ">
          <figure className="shimmer">
            <span></span>
          </figure>

          <div className="cstm_wrap_card_list">
            <div className="wrap_value shimmer">
              <b>
                <span></span>
              </b>
              <span className="wrap_rating"></span>
            </div>
            <span></span>
            <span className="property_location"></span>
            <div className="wrap_dealer_name shimmer">
              <figure></figure>
              <div className="name_wrap">
                <p>
                  <b></b>
                </p>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyListShimmer;
