import CryptoJS from "crypto-js";
import moment from 'moment-timezone'
// import moment from "moment";
const SECRET_KEY = "D#4436Bqe2$%$#$%MC&^$@#^*&%I";

const MaritalStatusEnum = {
  Single: 1,
  Married: 2,
  Divorced: 3,
  Widowed: 4,
};

export const encryptdata = (value) => {
  return CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
};

export const getToken = () => {
  const encryptToken = localStorage.getItem("token");
  if (!encryptToken) return;
  return "Bearer " + decryptData(encryptToken);
};

export const setToken = (token) => {
  const encryptToken = encryptdata(token);
  return encryptdata(localStorage.setItem("token", encryptToken));
};

export const setEncryptUserDetail = (data) => {
  const encrypt = encryptdata(data)
  localStorage.setItem("userDetail",encrypt)
}
export const getDecryptUserDetail = () => {
  return decryptData(localStorage.getItem("userDetail"))
}

export const decryptData = (data) => {
  if(data === null) return null
  const decryptedBytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
  return decryptedBytes.toString(CryptoJS.enc.Utf8);
};

export const DateConversion = (inputDateString) => {
  return moment(inputDateString).format("DD MMM YYYY");
};

export const filterJsonData = (jsonArray, filterValue) => {
  const filterData = jsonArray.filter((element) => element.id === filterValue);
  return filterData[0];
};

export const isPdf = (message) => {
  if (message.image && message.image.length > 0) {
    const firstImage = message.image[0];
    if (typeof firstImage === 'string' && firstImage.toLowerCase().endsWith('.pdf')) {
        return true; // The first image is a PDF
    }
}
return false;
}

export const addLeadingZero = (numberString) => {
  const number = parseInt(numberString, 10);
  return number < 10 ? "0" + numberString : numberString;
}

export const capitalizeFirstLetter =(string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}
export const createFilterObject = (filterObject) => {
  const filter = {};

  const propertiesToCheck = [
    "accessibilityFeature",
    "beds",
    "bath",
    "leaseType",
    "furnishingType",
    "leaseLength",
    "propertyType",
    "amenities",
    "facilities",
    "availableFrom",
  ];

  propertiesToCheck.forEach((property) => {
    filter[property] =
      filterObject[property]?.length > 0 ? filterObject[property] : null;
  });
  const checkSmokingBoolean = filterObject.smoking;
  let booleanArray = checkSmokingBoolean.map((item) => item === "true");
  filter["amount"] = filterObject?.amount;
  filter["ownerVerify"] = filterObject?.ownerVerify;
  if (booleanArray.length !== 0) {
    filter["smoking"] = booleanArray;
  } else {
    filter["smoking"] = null;
  }
  return filter;
};

export const getCurrentDate = (availableFrom) => {
  const date = {
    availableFrom: moment(availableFrom).format('YYYY-MM-DD'),
    endDate:  moment(availableFrom).add(120, 'days').format('YYYY-MM-DD')
  }
  return date
};
export const utcConverter = (dateString) => {
  const timezone = getTimezone()
  return dateString
    ? moment(dateString).tz(timezone).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    : ''
  
}
const getTimezone = () => {
  try {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    return timeZone
  } catch (error) {
    console.error('Error retrieving timezone:', error)
  }
}
export const getLastIndexName = (url) => {
  const length = url?.split("/")?.length
  const indexValue = url?.split("/")[length - 1]
  return indexValue
}
export const formatTimeStamp = (date) => {
  const timestamp = date;
  const parsedTimestamp = moment(timestamp);
  return parsedTimestamp.format('h:mm A');
}
export const calculateAmount = (amount, days) => {
  switch (days) {
    case 30:
      return amount;
    case 60:
      return amount * 2;
    case 90:
      return amount * 3;
    case 120:
      return amount * 4;
    default:
      return amount;
  }
};

export const filterBooking = (bookingStatus, value) => {
  
  return bookingStatus?.find((booking) => booking?.value === value);
};

export const filterJson = (jsonArray, filterValue) => {
  return jsonArray?.filter((item) => filterValue?.includes(item.id));
};



export const filterChat = (chatList, textSearch) => {
  return chatList?.filter((item) => textSearch?.includes(item.receiverdata?.name))
}

export const getRecentPayment = (transactionArray) => {
  if(!transactionArray) return null
  for (let transaction of transactionArray) {
    if (transaction?.paymentStatus === false) {
            return transaction
    }       
}
}

export const getTimeStatus = (timestamp) => {
  const timeMoment = moment(timestamp);
  const currentTime = moment();
  const hoursDiff = currentTime.diff(timeMoment, 'hours');

  let formattedTime;

  if (hoursDiff < 24) {
      formattedTime = timeMoment.format('HH:mm');
  } else if (hoursDiff < 48) {
      formattedTime = 'Yesterday';
  } else {
      formattedTime = timeMoment.format('DD MMMM YYYY');
  }

  return formattedTime;
}

export const getRatingReview = (userId, reviewRating) => {
  if (reviewRating) {
    for (const data of reviewRating) {
      if (userId === data?.user?._id) {
        return data;
      }
    }
  }
  return null
}


export const getMaritalStatusLabel = (status) => {
  switch (Number(status)) {
    case MaritalStatusEnum.Single:
      return "single";
    case MaritalStatusEnum.Married:
      return "married";
    case MaritalStatusEnum.Divorced:
      return "divorced";
    case MaritalStatusEnum.Widowed:
      return "widowed";
    default:
      return "unknown";
  }
};

