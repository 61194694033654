import React, { useEffect, useState } from "react";
import "./header.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  HeaderLogo,
  notifimgycasa,
  notiiconcstm,
} from "../../../DefaultImport";
import { Link, useNavigate } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { MdOutlineAccountCircle } from "react-icons/md";
import { LuCalendarCheck } from "react-icons/lu";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { GoBell } from "react-icons/go";
import { BiCheckboxSquare } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import CommonModal from "../../modal/CommonModal";
import face_img from "../../../assets/images/face1.png";
import { useQuery } from "@apollo/client";
import { GET_USER_DETAIL } from "../../../service/auth/authQuery";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  setClearFilter,
  setDefaultCoordinate,
} from "../../property/filterSlice";
import { GET_NOTIFICATION } from "../../../service/notification/notificationQuery";
import { getTimeStatus } from "../../../helper/common";

const UserHeader = () => {
  const [seeMore, setSeeMore] = useState(true);
  const [notificationLimit, setNotificationLimit] = useState(10);
  const { data: notification,refetch: fetchNotification } = useQuery(GET_NOTIFICATION, {
    variables: {
      input: {
        userType: "user",
        page: 1,
        limit: notificationLimit,
      },
    },
  });
  const { notificationListForUserAndOwner } = notification || {};
  const { list } = notificationListForUserAndOwner || {};
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(setClearFilter());
    dispatch(
      setDefaultCoordinate({
        latitude: coordinate?.latitude,
        longitude: coordinate?.longitude,
        default: true,
        place: "",
      })
    );
    localStorage.clear();
    navigate("/auth");
  };
  const { data, refetch, error } = useQuery(GET_USER_DETAIL);
  if (error?.graphQLErrors[0]?.extensions?.response?.statusCode === 403) {
    toast.error(error?.graphQLErrors[0]?.extensions?.response?.message);
    navigate("/auth");
  }
  const { getUserData } = data || {};
  const { name, email, image } = getUserData || {};
  const coordinate = JSON.parse(localStorage.getItem("coordinate"));
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      className="cstm_drop_anchor"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* Render custom icon here */}
      <RiArrowDropDownLine />
      {children}
    </a>
  ));
  const CustomToggler = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* Render custom icon here */}
      <RiArrowDropDownLine />
      {children}
    </a>
  ));

  const handleClose = () => {
    setShowModal(false);
  };

  const defaultCoordinate = () => {
    dispatch(
      setDefaultCoordinate({
        latitude: coordinate?.latitude,
        longitude: coordinate?.longitude,
        default: true,
        place: "",
      })
    );
  };
  const getMoreNotification = () => {
    if (list?.length === notificationLimit) {
      setNotificationLimit(notificationLimit + 5);
    } else {
      setSeeMore(false);
    }
  };



  useEffect(() => {
    refetch();
    fetchNotification()
    if (list?.length < 10) {
      setSeeMore(false);
    }
  }, [fetchNotification, list?.length, refetch]);

  return (
    <header>
      <div className="wrapper_header wrapper_user_header">
        <div className="Custom_container">
          {["md"].map((expand) => (
            <Navbar
              key={expand}
              expand={expand}
              className="bg-body-tertiary mb-3"
            >
              <Navbar.Brand>
                <Link to="/property_list">
                  <img
                    src={HeaderLogo}
                    alt=" logo"
                    onClick={() => defaultCoordinate()}
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                className="mobile_user_view"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    YCASA
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav
                    className="justify-content-end flex-grow-1"
                    
                  >
                    <Nav.Link to="#action1" onClick={() => navigate("/chat")}>
                      <BiMessageRoundedDetail />
                      Messages
                    </Nav.Link>
                  </Nav>
                  <Dropdown className="cstm_dropdown"
                      onClick={() => fetchNotification()}
                      >
                    <Dropdown.Toggle
                      as={CustomToggler}
                      variant="success"
                      id="dropdown-basic"
                      
                    >
                      <GoBell className="cstm_bell"
                      />
                      <span>Notifications</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                      <div className="cstm_Drop">
                        <div className="wrap_fig_drop">
                          <h6>Notifications</h6>
                          {/* <span>Mark as All Read</span> */}
                        </div>
                      </div>
                      <div className="today_notification">
                        {/* <p className="heading_p">TODAY</p> */}
                        {list?.length === 0 &&
                                   <div className="fix_center_noti">
                                   <div className="wrapper_blank_noti">
                                     <figure className="cstm_noti_miss">
                                       <img src={notiiconcstm} alt="" />
                                     </figure>
                                     <h5>No Notification Found!</h5>
                                     <p>
                                       your Notifications will be shown here once they
                                       are listed.
                                     </p>
                                   </div>
                                 </div>
               }
                        {list?.map((notification) => (
                          <Link to="#">
                            <div className="wrap_noti_card">
                              <figure>
                                <img src={notifimgycasa} alt="" />
                              </figure>
                              <div className="noti_with_time">
                                <p>{notification?.message}</p>
                                <span>
                                  {getTimeStatus(notification?.createdAt)}
                                </span>
                              </div>
                            </div>
                          </Link>
                        ))}

                        {seeMore && (
                          <div
                            className="wrap_noti"
                            onClick={() => getMoreNotification()}
                          >
                            <p>See more...</p>
                          </div>
                        )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      variant="success"
                      id="dropdown-basic"
                    >
                      <div className="drop_fig">
                        <figure>
                          <img src={image ? image : face_img} alt="" />
                        </figure>
                        <span>{name}</span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="cstm_Drop">
                        <div className="wrap_fig_drop">
                          <Link to="/account_setting/my_profile">
                            <figure>
                              <img src={image ? image : face_img} alt="" />
                            </figure>
                            <div className="wrap_user_drop">
                              <p>{name}</p>
                              <span>{email}</span>
                            </div>
                          </Link>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="wrap_fig_drop">
                          <Link to="/account_setting/my_profile">
                            <MdOutlineAccountCircle />
                            <p>Account Settings</p>
                          </Link>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="wrap_fig_drop">
                          <Link to="/my_booking">
                            <LuCalendarCheck />
                            <p>Bookings</p>
                          </Link>
                        </div>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#/action-4">
                      <div className="wrap_fig_drop">
                        <TfiCrown />
                        <p>Subscriptions</p>
                      </div>
                    </Dropdown.Item> */}
                      <Dropdown.Item>
                        <div className="wrap_fig_drop">
                          <Link to="/mywish_list">
                            <FaRegHeart />
                            <p>Wishlist</p>
                          </Link>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="wrap_fig_drop">
                          <Link to="/my_dispute">
                            <BiCheckboxSquare />
                            <p>My Disputes</p>
                          </Link>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="log_out"
                        onClick={() => setShowModal(true)}
                      >
                        <div className="wrap_fig_drop">
                          <MdOutlineLogout />
                          <p>Log Out</p>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          ))}
        </div>
        <CommonModal
          show={showModal}
          customClass="wrap_modal logout_modal_cstm"
          handleClose={handleClose}
          title="Are you sure, you want to logout?"
        >
          <div className="text-center">
            <button
              className="common_btn me-4"
              style={{ maxWidth: "110px", width: "100%" }}
              onClick={() => logoutUser()}
            >
              Yes
            </button>

            <button
              className="common_btn"
              style={{ maxWidth: "110px", width: "100%" }}
              onClick={() => handleClose()}
            >
              No
            </button>
          </div>
        </CommonModal>
      </div>
    </header>
  );
};

export default UserHeader;
