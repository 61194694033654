import React, { useEffect, useState } from "react";
import "./helpandsupport.scss";
import { useMutation } from "@apollo/client";
import { HELP_AND_SUPPORT } from "../../../service/helpAndSupport/helpAndSupportMutation";
import { toast } from "react-toastify";
import { spin } from "../../../DefaultImport";
import { FaBars } from "react-icons/fa";

const HelpAndSupport = () => {
  const [HelpSupport] = useMutation(HELP_AND_SUPPORT);
  const [loader, setLoader] = useState(false);
  const [validation, setInputValidation] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmailStatus(false);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);
      setInputValidation(true);
      if (!validateEmail(formData.email)) {
        setEmailStatus(true);
        return;
      }

      await HelpSupport({
        variables: {
          input: formData,
        },
      });
      toast.success("Send Successfully");
      setFormData({ message: "", email: "" });
    } catch (error) {
      toast.error(
        error?.graphQLErrors[0]?.extensions?.response?.message?.join("")
      );
    } finally {
      setLoader(false);
    }
  };

  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };

  return (
    <div className="wrap_profile_details wrap_helpandsupport">
      <div className="head_setting">
        <span className="cross_bar" onClick={addNavigationClass}>
          <FaBars className="cstm_bars" />
        </span>
        <h6>Help & Support</h6>
      </div>
      <div className="form_profile_set">
        <iframe
          className="cstm_map_wrap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2220624931133!2d77.37741997464884!3d28.62310597566982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce557f4e21f59%3A0x281bc490c29189bf!2sRipenApps%20Technologies!5e0!3m2!1sen!2sin!4v1705220154206!5m2!1sen!2sin"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <form>
          <div className="row">
            {/* <div className="col-lg-6 col-md-6 col-sm-6">

            </div> */}
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div
                className={`wrap_form_fields ${
                  validation === true && !formData.email && "cstm_error"
                }`}
              >
                <label>Email Address</label>
                <input
                  name="email"
                  className="cstm_input_profile"
                  placeholder="Enter email address"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {validation === true && emailStatus === true && (
                  <span className="erro_img">* Please enter valid email</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="wrap_form_fields">
                <label>Message</label>
                <textarea
                  className="cstm_input_profile"
                  name="message"
                  rows="4"
                  placeholder="Write Here "
                  cols="50"
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="w-100 text-end">
                <button
                  className="common_btn w-25 form_sbmt_btn"
                  type="button"
                  onClick={handleSubmit}
                >
                  {loader === true ? <img src={spin} alt="" /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HelpAndSupport;
