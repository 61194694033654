import React, { useState } from "react";
import { MyStaysBooking, RequestedBooking } from "../../DefaultImportComponent";
import "./booking.scss";


const MyBooking = () => {
    const [requested,setRequested] = useState("requested")
  return (
    <div className="wrap_booking_request wrap_booking_landing">
      <div className="wrap_head_toogle">
        <div className="wrap_heading">
          <h5>My Bookings</h5>
        </div>
        <div className="btn_toogle">
          <span className={`cstm_span_requested ${requested === "requested" ? "active": ""}`} onClick={()=> setRequested("requested")}>Requested</span>
          <span className={`cstm_span_requested ${requested === "requested" ? "": "active"}`}onClick={()=> setRequested("")} >My Stays</span>
        </div>
      </div>
    {requested === "requested" ? <RequestedBooking/> : <MyStaysBooking/>}
    </div>
  );
};

export default MyBooking;
