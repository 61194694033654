import React from "react";

const BookingShimmer = () => {
  return (
    <div className="col-lg-6 col-md-12 col-sm-12 mb-4 shimmer-container">
    <div className="wrap_listing_card shimmer">
      <div className="recommend_list">
        <figure className="shimmer"></figure>
        <div className="cstm_wrap_card_list">
          <div className="wrap_value">
            <p className="cstm_p shimmer">
              <span></span>
            </p>
            <span className="wrap_rating shimmer"></span>
          </div>
          <div className="wrap_hosted">
            <div className="wrap_fig_img width_full_shimmer shimmer">
              <figure></figure>
            </div>
          </div>
          <div className="wrap_hosted">
            <div className="wrap_fig_img width_full_shimmer shimmer">
              <figure></figure>
            </div>
          </div>
          <div className="wrap_hosted">
            <div className="wrap_fig_img width_full_shimmer shimmer">
              <figure></figure>
            </div>
          </div>
          <div className="wrap_value">
            <p className="cstm_p shimmer">
              <span></span>
            </p>
            <span className="wrap_rating shimmer"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default BookingShimmer;
