import React, { useEffect, useRef, useState } from "react";
import { MdVerified } from "react-icons/md";
import { dispute_img, spin } from "../../DefaultImport";
import { BsChatText } from "react-icons/bs";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import CommonModal from "../modal/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import { getBooking } from "./bookingSlice";
import face_img from "../../assets/images/face1.png";
import { setChat } from "../chat/chatSlice";
import { getDecryptUserDetail, getRecentPayment } from "../../helper/common";
import moment from "moment";
import { handleApiCall } from "../../service/restApi";
import { Form } from "react-bootstrap";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import {
  PAYMENT_REMAINING_RENT,
  SAVE_CARD,
} from "../../service/booking/bookingMutaion";
import { setDispute } from "../dispute/disputeSlice";
import { CARD_LIST } from "../../service/booking/bookingQuery";
const PaymentDetails = () => {
  const [cardHolderName, setCardHolderName] = useState(null);
  const [saveCard, setSaveCard] = useState(null);
  const [PaymentRemainingTransaction] = useMutation(PAYMENT_REMAINING_RENT);
  const stripe = useStripe();
  const elements = useElements();
  const [transationPopup, setTransactionPopUp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const inputRef = useRef(null);
  const [preview, setPreview] = useState("");
  const { booking, disputeTopic } = useSelector(getBooking);
  const { transations } = booking || {};
  const getUser = JSON.parse(getDecryptUserDetail());
  const { property } = booking || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { data,refetch } = useQuery(CARD_LIST);
  const [SaveCard] = useMutation(SAVE_CARD);
  const [statusHolderName,setStatusHolder] = useState(false)
  const { cardList } = data || {};
  const closeTransactionPopup = () => {
    setTransactionPopUp(false);
    setSaveCard(null);
    setIsChecked(false)
    setStatusHolder(false)
  };
  const [imageName, setImageName] = useState([
    {
      imageName: "",
      imageFormat: "",
    }
  ]);
  const [formData, setFormData] = useState({
    disputeTopic: "",
    description: "",
    attachments: [],
  });
  const handleClose = () => {
    setFormData({
      disputeTopic: "",
      description: "",
      attachments: [],
    });
    setImageName([
      {
        imageName: "",
        imageFormat: "",
      }
    ]);
    setPreview("")
    setShowModal(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  
  // const imageHandler = (e) => {
  //   const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
  //   const file = e.target.files[0];
  //   if (validImageTypes.includes(file.type) ) {
  //     setPreview(URL.createObjectURL(file));
  //     setImageName({
  //       imageName: file.name,
  //       imageFormat: `(${file.type})`,
  //     });
  //     setFormData({
  //       ...formData,
  //       attachments: [...formData.attachments, file],
  //     });
      
  //   } else {
  //     toast.error("Only image uploaded")
  //   }
  // };
  console.log("123",preview)
  const imageHandler = (e) => {
    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const files = e.target.files;  
    Array.from(files).forEach((file) => {
      if (validImageTypes.includes(file.type)) {
        const previewURL = URL.createObjectURL(file);
          setPreview((prevPreview) => [...prevPreview, previewURL]);  
        setImageName((prevImageNames) => [
          ...prevImageNames,
          {
            imageName: file.name,
            imageFormat: `(${file.type})`,
          },
        ]);
  
        setFormData((prevFormData) => ({
          ...prevFormData,
          attachments: [...prevFormData.attachments, file],
        }));
      } else {
        toast.error("Only images can be uploaded");
      }
    });
  };
  

  

  console.log(imageName, "Image Name");

  // Image Hanlder Validation 
    //  Files Handler
  // const imageHandler = (e, type) => {
  //     const imageFileRegex = /\.(jpg|jpeg|png|bmp)$/i
  //     let { name, value, files } = e.target;
  //     if (files && files.length > 0) {
  //       // if (type === "img") {
  //         const file = files[0];
  //         if (file && imageFileRegex.test(file.name)) {
  //           setImageName((prevState) => ({
  //             ...prevState,
  //             imageFormat: file,
  //           }));
  //         } else {
  //           e.target.value = "";
  //           setImageName({ ...imageName, imageFormat: "" });
  //           toast(IMAGE_FORMAT, "error", "imageFormat");
  //           toast.error("Invalid format")
  //         }
  //       // }
  //     } else {
  //       setImageName({ ...imageName, imageFormat: "" });
  //     }
  //     if (files && files.length > 0) {
  //       if (type === "doc") {
  //         let file = files[0];
  //         if (file && documentFileRegex.test(file.name)) {
  //           setMedia((prevState) => ({
  //             ...prevState,
  //             document: file,
  //           }));
  //         } else {
  //           e.target.value = "";
  //           setMedia({ ...media, document: "" });
  //           toastMessage(DOC_FORMAT, "error", "docFormat"); // Assuming toastMessage is defined elsewhere
  //         }
  //       }
  //     }
  //     else {
  //       setMedia({ ...media, document: "" });
  //     }
  //   };

  const navigateChat = () => {
    dispatch(
      setChat({
        propertyDetails: property,
        receiverId: property?.userDetail?._id,
        senderId: getUser?._id,
        receiverdata: property?.userDetail,
      })
    );
    navigate("/chat", {
      state: { detail: property },
    });
  };

  const handleCardValidation = async (e) => {
    try {
      setLoader(true);
      e.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      if (!cardHolderName && !saveCard) {
        setStatusHolder(true)
        return
      }

      if (isChecked) {
        const { error: stripeErrors, token } = await stripe.createToken(
          elements.getElement(CardNumberElement),
          {
            name: cardHolderName,
          }
        );
        if (token) {
          await SaveCard({
            variables: {
              input: {
                token: token.id,
              },
            },
          });
        }
      }
      if (saveCard) {
        const saveCardOjbect = {
          transactionId: getRecentPayment(transations)?._id,
          type: "card",
          paymentMethod: null,
          cardId: saveCard.id,
        };

        const { data } = await PaymentRemainingTransaction({
          variables: {
            input: saveCardOjbect,
          },
        });

        if (data) {
          const { payRemainingRent } = data || {};
          const { message } = payRemainingRent || {};
          toast.success(message);
          setIsChecked(false)
          navigate(`/booking/${booking._id}/Ongoing`);
        }
        setTransactionPopUp(false);
        return;
      }
      const { error: stripeError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: cardHolderName,
          },
        });

      if (stripeError) {
        setError(stripeError.message);
      } else {
        const { data } = await PaymentRemainingTransaction({
          variables: {
            input: {
              transactionId: getRecentPayment(transations)?._id,
              paymentMethod: paymentMethod.id,
              type: "direct",
            },
          },
        });
        if (data) {
          navigate(`/booking/${booking._id}/Ongoing`);
        }
        const { payRemainingRent } = data || {};
        const { message } = payRemainingRent || {};
        toast.success(message);
        setIsChecked(false)
        setTransactionPopUp(false);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
      setCardHolderName(null);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData?.disputeTopic) {
      toast.error("Dispute topic is required");
      return;
    }
    if (!formData?.description) {
      toast.error("Description is required");
      return;
    }
    setLoader(true);
    const formDataToSend = new FormData();
    // Append form data to FormData object
    formDataToSend.append("bookingId", booking._id);
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key === "attachments") {
          formData[key].forEach((file) => {
            formDataToSend.append(key, file);
          });
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }
    }
    try {
      const { data: json } = await handleApiCall(
        "post",
        "/dispute/add",
        formDataToSend
      );
      const { message, data, statusCode } = json;
      if (statusCode === 422) {
        toast.error(message);
        return;
      }
      const { ticketId } = data;
      toast.success(message);
      setShowModal(false);
      dispatch(
        setDispute({
          bookingId: booking?._id,
          owner: property?.userDetail,
          disputeTopic: formData?.disputeTopic,
          property: property,
          ticketId: ticketId,
        })
      );
      navigate("/dispute_chat");
    } catch (error) {
      const { data } = error.response;
      toast.error(data?.message.join(" "));
    } finally {
      setLoader(false);
    }
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const navigateMyBooking = (type) => {
    if (type === "ongoing") {
      navigate("/my_booking");
    }
  };

  window.addEventListener("popstate", function (event) {
    navigateMyBooking("ongoing");
  });
  useEffect(() => {
    refetch() 
  }
  ,[refetch])
  return (
    <div className="inner_chat_card wrap_booking_cancel wrap_payment_details">
      <div className="card_chat">
        <div className="wrap_chat_img">
          <figure>
            <img
              src={
                property?.userDetail?.image
                  ? property?.userDetail?.image
                  : face_img
              }
              alt=""
            />
          </figure>
          <div className="hosted">
            <p>Entire Property hosted by</p>
            <h5>
              {property?.userDetail?.name}{" "}
              {property?.userDetail?.isUserIdProofVerify && <MdVerified />}
            </h5>
          </div>
        </div>
        <div className="wrap_chat_btn">
          <button className="chat_now_btn" onClick={() => navigateChat()}>
            <BsChatText />
            Chat Now
          </button>
        </div>
      </div>
      <div className="lease mb-4">
        <div className="wrap_detail_amount">
          <h5>Payment Details</h5>
          <Table responsive size="sm">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Mode</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {transations?.map((transation) => (
                <tr>
                  <td>
                    {moment(transation?.paymentDate).format("DD MMM YYYY")}
                  </td>
                  <td>$ {transation?.paymentAmount}</td>
                  <td>
                    {transation?.paymentMode ? transation?.paymentMode : "N/A"}
                  </td>
                  <td
                    className={`${
                      transation?.paymentStatus ? "paid" : "pending"
                    }`}
                  >
                    {transation?.paymentStatus ? "Paid" : "Pending"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Link to="#" onClick={() => setShowModal(true)}>
        <div className="lease">
          <div className="wrap_message_dispute">
            <img src={dispute_img} alt="" />
            <span>Raise a Dispute</span>
          </div>
          <MdKeyboardArrowRight />
        </div>
      </Link>
      {getRecentPayment(transations)?.paymentAmount && (
        <div className="btn_book_now">
          <button
            className="btn_see_more btn_request_cancel"
            onClick={() => setTransactionPopUp(true)}
          >
            Pay now $ {getRecentPayment(transations)?.paymentAmount}
          </button>
        </div>
      )}
      <CommonModal
        show={showModal}
        customClass="wrap_modal_dispute"
        handleClose={handleClose}
        title="Raise a Dispute"
      >
        <div className="wrap_dispute">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="wrap_card">
                  <label>Topics</label>
                  <select
                    name="disputeTopic"
                    className="cstm_input_profile cstm_error"
                    aria-label="Default select example"
                    value={formData.disputeTopic}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Topic</option>
                    {disputeTopic?.map((list) => (
                      <option value={list?.id}>{list?.name}</option>
                    ))}
                  </select>
                </div>
                <div className="wrap_textarea">
                  <label>
                    Description <span>(Max 200 words)</span>
                  </label>
                  <textarea
                    id="review"
                    className="cstm_input_profile"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    rows="4"
                    placeholder="Write here..."
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="wrap_card_upload">
                  <label>
                    <b>Attachment</b> <span>(Optional)</span>
                  </label>
                  <span className="block">Upload up to 5 attachments</span>
                  <div
                    className="wrap_upload"
                    onClick={() => {
                      inputRef.current.click();
                    }}
                  >
                    <figure>
                      <img src={preview[0]} alt="" />
                    </figure>
                    <input type="file" ref={inputRef} onChange={imageHandler} accept="image/png, image/gif, image/jpeg" multiple={true} />
                    <div className="data_type">
                      <span>
                        {imageName?.imageName
                          ? imageName.imageName
                          : "+ Upload"}
                      </span>
                      <span>
                        {imageName?.imageFormat
                          ? imageName.imageFormat
                          : "(supported file : .jpg, .png, .pdf)"}
                      </span>
                    </div>
                  </div>
                  <div className="wrap_btn_sub mt-4">
                    <button type="submit" className="form_sbmt_btn" disabled={loader}>
                      {loader === true ? <img alt="" src={spin} /> : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </CommonModal>
      <CommonModal
        show={transationPopup}
        customClass="wrap_modal payment_modal_save_card"
        handleClose={closeTransactionPopup}
        title="Pay with"
      >
        <div className="row cstm_padding">
          {cardList?.length > 0 && (
            <div className="col-lg-6 col-md-6 col-12">
              <p className="cstm_stripe_p">SAVED CARDS</p>
              <div className="wrap_save_cards">
                {cardList?.map((data, index) => (
                  <div className="inner_cards" key={index}>
                    <input
                      className="radio_cstm"
                      type="radio"
                      id={`card_${index}`}
                      name="selectedCard"
                      value={data.cardNumber}
                      onClick={(e) => {
                        setSaveCard(data);
                        setIsChecked(false);
                      }}
                    />
                    <label htmlFor={`card_${index}`}>
                      <div>
                        <span className="cstm_space">
                          <span>xxxx xxxx xxxx {data?.card?.last4}</span>
                        </span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div
            className={
              cardList?.length > 0
                ? "col-lg-6 col-md-6 col-12 cstm_col_label"
                : "col-lg-12 col-md-12 col-12 cstm_col_label"
            }
          >
            <p className="cstm_stripe_p">ADD NEW CARD</p>
            <form onSubmit={handleCardValidation}>
              <label>
                Card number
                <CardNumberElement
                  className="cstm_number_element"
                  options={{
                    placeholder: "4242 4242 4242 4242",
                    disabled: saveCard ? true : false,
                  }}
                />
              </label>
              <div className="cstm_width_pay">
                <label className="label_1_pay">
                  Expiration date
                  <CardExpiryElement
                    options={{
                      placeholder: "MM / YY",
                      disabled: saveCard ? true : false,
                    }}
                  />
                </label>
                <label className="label_2_pay">
                  CVC
                  <CardCvcElement
                    options={{
                      placeholder: "123",
                      disabled: saveCard ? true : false,
                    }}
                  />
                </label>
              </div>
              <label>
                Card Holder Name
                <input
                  className=" cstm_input_profile"
                  type="text"
                  placeholder="Enter Card Holder Name"
                  name="cardHolderName"
                  disabled={saveCard ? true : false}
                  value={cardHolderName}
                  onChange={(e) => {
                    setCardHolderName(e.target.value)
                    setStatusHolder(false)
                  }}
                />
                {statusHolderName === true &&
                  <span className="erro_img">Card holder name is required</span>
                }
              </label>
              <div className="check_save_card">
                <Form.Check
                  name="agreement"
                  aria-label="saved card for future payments"
                  checked={isChecked}
                  disabled={saveCard ? true : false}
                  onChange={handleCheckboxChange}
                />
                <span>saved card for future payments.</span>
              </div>
              {error && (
                <div>
                  {" "}
                  <p style={{ color: "red" }}>{error}</p>
                </div>
              )}
              <div className="cstm_set_btn">
                <button
                  className="btn_see_more w-50 form_sbmt_btn"
                  type="submit"
                  disabled={loader}
                >
                  {loader === true ? <img alt="" src={spin} /> : "Pay Now"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default PaymentDetails;
