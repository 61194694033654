import { gql } from "@apollo/client";

export const PROPERTY_VIEW = gql`
  query PropertyView($_id: String!) {
    propertyView(_id: $_id) {
      details {
        _id
        title
        beds
        address
        video
        furnishingType
        houseNumber
        description
        availableFrom
        createdAt
        postalCode
        leaselength
        smoking
        accessibilityFeature
        propertyRule
        facilities
        amenities
        images
        bath
        rating
        isWishlist
        amount
        userDetail {
          name
          image
          _id
          isUserIdProofVerify
        }
      }
      reviewRatingList {
        userId
        rating
        review
        user{
          name
          image
        }
        createdAt
      }
    }
  }
`;


export const GET_WISHLIST_PROPERTY = gql`
  query MyWishList($input: MyWishListDTO!){
    myWishList(input: $input){
      list{
        Property{
          _id
          title
          beds
          address
          houseNumber
          postalCode
          images
          rating
          bath
          amount
          furnishingType
          userDetail {
            name
            image
          }
          isWishlist
        }
      }
      total
    }
  }
`;
