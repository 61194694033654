import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getToken } from "../helper/common";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}`,
});

const authLink = setContext((_, { headers }) => {
  let value = getToken();
  const token = value;
  return {
    headers: {
      ...headers,
      Authorization: token ? `${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
