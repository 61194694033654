import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { toast } from "react-toastify";
import { spin } from "../../../DefaultImport";
import { handleApiCall } from "../../../service/restApi";

const TermConditon = () => {
  const [termCondition, setTermConditon] = useState("");
  const [loader, setLoader] = useState(false);
  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };

  const fetchTermCondition = async () => {
    try {
      setLoader(true);
      const response = await handleApiCall("get", "/user/termandcondition");
      if (response.data.success === false) {
        toast.error(response.data.message);
        return;
      }
      console.log(response.data, "Response");
      setTermConditon(response?.data);
    } catch (error) {
      toast.error(error.response.data.message[0]);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchTermCondition();
  }, []);

  useEffect(() => {
    document.body.className = "removeBodyCss";
    return () => {
      document.body.className = "";
    };
  });

  return (
    <div className="wrap_profile_details">
      <div className="head_setting">
        <span className="cross_bar" onClick={addNavigationClass}>
          <FaBars className="cstm_bars" />
        </span>
        <h6>Terms & Conditions</h6>
      </div>

      {/* <div className="form_profile_set wrap_privacy_policy">
        <p className="cstm_color_second">Last updated on 25 Oct 2023</p>
        <p className="cstm_P_privacy">
          These Terms of Use describe the terms and conditions (the “Terms”) of
          Ycasa, and apply to www.ycasa.com web pages and mobile pplications and
          all related web pages owned and operated by Us or Our affiliates (the
          “Platform”).These Terms also apply to Our online and offline services
          (including Our social media pages) (collectively, the
          “Services”)provided by Us.
        </p>
        <p className="cstm_P_privacy">1. Effective date</p>
        <h6>The Terms are effective as of 30 May 2023 (“Effective Date”)</h6>
        <p className="cstm_P_privacy">
          We may share your Personal Information with third parties for their
          marketing purposes. You can elect for us not to do so by emailing us
          at info@pinaaki.com. Please make sure to include your name, mailing
          address/es and other contact details.
        </p>
        <p className="cstm_P_privacy">2. Your Acceptance of the Terms</p>
        <p className="cstm_P_privacy">
          By using or accessing the Services, you confirm your agreement to be
          bound by these Terms and Conditions which are incorporated herein by
          reference. If you do not agree to these Terms and Privacy Policy,
          please refrain from using or accessing the Services.Certain features
          or software that you use, purchase, or download through the Platform
          may be subjected to additional terms and conditions which will be
          presented to you at the time of use, purchase or download. In the
          cases of contests or other promotions (each a “Promotion”, and
          collectively “Promotions”) which are organized by Us and made
          available through the Platform, such Promotions may be subject to
          rules that are separate from these Terms. In the event of discrepancy
          between the rules of a Promotion and the Terms, the rules of the
          Promotion shall supersede the Terms. Furthermore, part of the Services
          may also be subject to additional terms and conditions of use from
          third parties’ websites such as Apple Store or Google Play Store.
        </p>
        <p className="cstm_P_privacy">
          We reserve the right, in Our sole discretion to change, modify, add,
          or remove portions of these Terms at any time, and by using or
          accessing the Services, you agree to be bound by such modifications.
          It is your responsibility to check periodically the Terms because any
          changes will be binding on you.
        </p>
      </div> */}

      <div className="form_profile_set cstm_wrap_dynamic_data">
        <p
          className="scroll_cstm_set"
          dangerouslySetInnerHTML={{ __html: termCondition }}
        />
      </div>
    </div>
  );
};

export default TermConditon;
