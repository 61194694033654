import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IoIosSearch } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { GET_DISPUTE_CHAT_LIST } from "../../service/dispute/disputeQuery";
import face_img from "../../assets/images/face1.png";
import { setDispute } from "./disputeSlice";
import { Solvedicon, openicon } from "../../DefaultImport";
import { GET_JSON_DATA } from "../../service/home/homeQuery";
import { filterBooking } from "../../helper/common";

// import { MdKeyboardArrowLeft } from "react-icons/md";

const DisputeChatList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data, refetch } = useQuery(GET_DISPUTE_CHAT_LIST, {
    variables: {
      input: {
        page: 1,
        search: searchQuery,
        userType: "user"
      },
    },
  });
  const { disputeListUserAndOwner } = data || {};
  const { disputeList } = disputeListUserAndOwner || {};
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const { data: jsonData } = useQuery(GET_JSON_DATA);
  const { getJsonData } = jsonData || {};
  const {  DisputeTopic } = getJsonData || {};
  const navigateChate = (e, dispute, i) => {
    try {
      e.preventDefault();
      setActive(i);
      const { bookingId, owner, property, disputeTopic, ticketId } = dispute;
      dispatch(
        setDispute({ bookingId, owner, property, disputeTopic, ticketId })
      );
    } catch (error) {
      toast.error("Something Went wrong");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    refetch();
  }, [refetch]);
  const navigation = () => {
    document.body.classList.remove("innerside_toogle");
  };
  return (
    <div className="wrapper_accont_setting">
      <div className="head_setting">
        <h6>My Disputes</h6>
        <span className="me-0" onClick={navigation}>
          <RxCross2 />
        </span>
      </div>
      <div className="wrap_nav">
        <div className="search_chat">
          <IoIosSearch />
          <input
            type="search"
            className="cstm_input_profile chat_input"
            placeholder="Search messages..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <ul type="none">
          {disputeList?.map((data, i) => (
            <li key={i} onClick={navigation}>
              <div
                className={`cstm_active_wrap ${active === i && "active"}`}
                onClick={(e) => navigateChate(e, data, i)}
              >
                <div className="wrap_img">
                  <figure>
                    <img
                      src={data?.owner?.image ? data?.owner?.image : face_img}
                      alt=""
                    />
                  </figure>
                  <div className="wrap_today">
                    <div className="flex_tag_span">
                      <p>{data?.owner?.name} ({data?.property?.title})</p>
                      {data?.disputeStatus === 1 && (
                        <span className="Open_tag">
                          <figure>
                            <img src={Solvedicon} alt="" />
                          </figure>
                          Solved
                        </span>
                      )}
                      {data?.disputeStatus === 0 && (
                        <span className="Solved_tag">
                          <figure>
                            <img src={openicon} alt="" />
                          </figure>
                          Open
                        </span>
                      )}
                    </div>
                    <span> {
                        filterBooking(DisputeTopic,data?.disputeTopic)?.name
                      }</span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DisputeChatList;
