import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation loginUser($input: UserLoginDTO!) {
    loginUser(input: $input) {
      access_token
      mailVerify
      user {
        _id
        name
        email
        image
      }
    }
  }
`;

export const RESEND_MAIL = gql`
  mutation resendMail($input: ResendMailDTO!) {
    resendEmailVerify(input: $input) {
      message
      user {
        _id
        mailVerify
      }
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation forgetPassword($input: UserForgotPasswordDTO!) {
    forgotPassword(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: UserChangePasswordDTO!) {
    changePassword(input: $input)
  }
`;
