import React, { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { MdLocationPin } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Form } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { MdVerified } from "react-icons/md";
import { FaRegImages } from "react-icons/fa";
import { BsChatText } from "react-icons/bs";
import "./propertydetails.scss";
import { FaStar } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { PROPERTY_VIEW } from "../../service/property/propertyQuery";
import { GET_JSON_DATA } from "../../service/home/homeQuery";
import face_img from "../../assets/images/face1.png";
import Video from "yet-another-react-lightbox/plugins/video";
import DatePicker from "react-datepicker";
import {
  DateConversion,
  filterJson,
  filterJsonData,
  getCurrentDate,
  getDecryptUserDetail,
  getToken,
} from "../../helper/common";
import CommonModal from "../../components/modal/CommonModal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setChat } from "../../components/chat/chatSlice";
import { CANCELLATION_POLICY_LIST } from "../../service/booking/bookingQuery";
import PropertyDetailShimmer from "../../components/shimmer/PropertyDetailShimmer";
import { ADD_REMOVE_WISHLIST } from "../../service/property/propertyMutation";
import { calendarcstm } from "../../DefaultImport";

const PropertyDetail = () => {
  const [showAllReviews, setShowAllReviews] = useState(false);
  const initialDisplayCount = 4; // Initial number of reviews to display
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const [AddRemoveWishList] = useMutation(ADD_REMOVE_WISHLIST);
  const dispatch = useDispatch();
  const getUser = JSON.parse(getDecryptUserDetail());
  const [errorDateState, setErrorDateState] = useState(false)
  const [errorStateLeaseLength,setErrorStateLeaseLength] = useState(false)
  const param = useParams();
  const navigate = useNavigate();
  const [images, setImage] = useState([]);
  const [leaselength, setLeaseLength] = useState("");
  // const [showFullText, setShowFullText] = useState(false);
  const { data, loading, refetch } = useQuery(PROPERTY_VIEW, {
    variables: {
      _id: param.id,
    },
  });
  const { propertyView } = data || {};
  const { details, reviewRatingList } = propertyView || {};
  const { userDetail } = details || {};
  const { data: jsonData } = useQuery(GET_JSON_DATA);
  const { getJsonData } = jsonData || {};
  const [date, setDate] = useState("");
  const { data: policy } = useQuery(CANCELLATION_POLICY_LIST, {
    variables: {
      input: {
        page: 1,
        limit: 10,
      },
    },
  });
  const { cancellationPolicyList } = policy || {};
  const { list } = cancellationPolicyList || {};
  const {
    FurnishingType,
    LeaseLength,
    Amenities,
    AccessibilityFeatures,
    Facilities,
  } = getJsonData || {};
  const filterLeaseLength = LeaseLength?.filter((item) =>
    details?.leaselength?.includes(item.id)
  );
  const filterAmenites = Amenities?.filter((item) =>
    details?.amenities?.includes(item.id)
  );
  const filterAccessibility = AccessibilityFeatures?.filter((item) =>
    details?.accessibilityFeature?.includes(item.id)
  );
  const filterFacilitiesLength = Facilities?.filter((item) =>
    details?.facilities?.includes(item.id)
  );
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };
  const openPhotoGallery = (imageArray, videoUrl) => {
    setOpen(true);
    if (imageArray.length > 0) {
      const updatedImageArray = imageArray.map((image, index) => ({
        src: image,
        type: "image",
      }));
      if (details?.video) {
        updatedImageArray.push({
          type: "video",
          width: 1280,
          height: 720,
          sources: [
            {
              src: details?.video,
              type: "video/mp4",
            },
          ],
        });
      }

      setImage(updatedImageArray);
    }
  };
  const bookingRequest = () => {
    const { images, amount, address, rating, title, _id } = details;
    if (!date || !leaselength) {
      toast.warn("Please select required fields");
      if (!date) setErrorDateState(true)
      if(!leaselength) setErrorStateLeaseLength(true)
      return;
    }
    setErrorDateState(false)
    setErrorStateLeaseLength(false)
    if (!getToken()) {
      setShowModal(true);
      return;
    }
    const detail = {
      images: images[0],
      amount: amount,
      address: address,
      rating: rating,
      title: title,
      id: _id,
      propertyRule: details?.propertyRule,
    };

    navigate("/booking_request", {
      state: { detail: detail, date, leaselength },
    });
  };
  const navigateChat = () => {
    if (!getToken()) {
      setShowModal(true);
      return;
    }
    dispatch(
      setChat({
        propertyDetails: details,
        receiverId: details?.userDetail?._id,
        senderId: getUser?._id,
        receiverdata: details?.userDetail,
      })
    );

    navigate("/chat", {
      state: { detail: details },
    });
  };
  const addRemoveWishList = async (propertyId) => {
    await AddRemoveWishList({
      variables: {
        input: {
          propertyId: propertyId,
        },
      },
    });
    refetch();
  };
  // const toggleText = () => {
  //   setShowFullText(!showFullText);
  // };
  const toggleShowAllReviews = () => {
    setShowAllReviews(!showAllReviews);
    if (!showAllReviews) {
      setDisplayCount(reviewRatingList.length);
    } else {
      setDisplayCount(initialDisplayCount);
    }
  };
  // useEffect(() => {
  //   setDate(getCurrentDate(details?.availableFrom)?.availableFrom)
  // },[details?.availableFrom])

  return (
    <div>
      {loading && <PropertyDetailShimmer />}
      <div className="wrap_image_property">
        <div className={`row`}>
          <div className="col-lg-6 col-md-6 padding-right_0">
            <div className="wrap_property_section">
              <figure
                className={`large_property`}
                onClick={() => openPhotoGallery(details?.images)}
              >
                <img src={details?.images[0]} alt="" />
              </figure>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 padding_right_add">
            <div className="row margin_top">
              <div className="col-lg-6 col-md-6 col-sm-6 padding-right_0">
                <div className="wrap_property_section_half">
                  <figure
                    className={`large_property`}
                    onClick={() => openPhotoGallery(details?.images)}
                  >
                    <img src={details?.images[1]} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="wrap_property_section_half">
                  <figure
                    className={`large_property`}
                    onClick={() => openPhotoGallery(details?.images)}
                  >
                    <img src={details?.images[2]} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 padding-right_0">
                <div className="wrap_property_section_half">
                  <figure
                    className={`large_property`}
                    onClick={() => openPhotoGallery(details?.images)}
                  >
                    <img src={details?.images[3]} alt="" />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="wrap_property_section_half">
                  <figure
                    className={`large_property`}
                    onClick={() => openPhotoGallery(details?.images)}
                  >
                    <img src={details?.images[4]} alt="" />
                    <span className="tag_span">
                      <FaRegImages /> Show more
                    </span>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="propert_detailing_list">
          <div className="row">
            <div className="col-lg-8">
              <div className="wrap_inner_card">
                <div className="cstm_flex">
                  <div className="location">
                    <h5>
                      {details?.title}{" "}
                      {details?.rating > 0 && (
                        <span>
                          <FaStar />
                          {details?.rating}
                        </span>
                      )}
                    </h5>
                    <span className="location_tag">
                      <MdLocationPin />
                      {details?.houseNumber} {details?.address} {" "}
                      {details?.postalCode}
                    </span>
                  </div>
                  <div className="amount">
                    <h5>
                      $ {details?.amount}
                      <span>/Month</span>
                    </h5>
                  </div>
                </div>
                <div className="cstm_ul">
                  <ul>
                    <li>
                      <GoDotFill className="svg_dots" />
                      {details?.beds} Beds
                    </li>
                    <li>
                      <GoDotFill /> {details?.bath} Bathroom
                    </li>
                    <li>
                      {" "}
                      <GoDotFill /> Bachelor
                    </li>
                    <li>
                      {" "}
                      <GoDotFill />{" "}
                      {FurnishingType &&
                        filterJsonData(FurnishingType, details?.furnishingType)
                          ?.name}
                    </li>
                  </ul>
                  <span
                    className="cstm_btn"
                    onClick={() => addRemoveWishList(details?._id)}
                  >
                    {details?.isWishlist === false && <FaRegHeart />}
                    {details?.isWishlist === true && (
                      <FaHeart className="cstm_fill_heart" />
                    )}
                    Wishlist
                  </span>
                </div>
                <div className="about_property">
                  <h5>About Property</h5>
                  <p>{details?.description}</p>
                  {/* <p className="clr">
                    {showFullText
                      ? details?.description
                      : `${details?.description.slice(0, 200)}...`}
                  </p> */}
                  {/* {!showFullText && (
                    <button className="btn_see_more" onClick={toggleText}>
                      Read More
                    </button>
                  )} */}
                </div>
                <div className="wrap_overview">
                  <h6>Overview</h6>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                      <div className="wrap_over_card">
                        <p>Lease Length</p>
                        {filterLeaseLength?.map((data, index) => (
                          <span key={index}>
                            {data?.name}{" "}
                            {index !== filterLeaseLength.length - 1 ? "," : ""}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                      <div className="wrap_over_card">
                        <p>Smoking</p>
                        <span>{details?.smoking === true ? "Yes" : "No"}</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                      <div className="wrap_over_card">
                        <p>Accessibility</p>
                        {filterAccessibility?.map((data) => (
                          <span> {data?.name}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrap_overview wrap_facilities">
                  <h6>Facilities</h6>
                  <div className="row">
                    {filterFacilitiesLength?.map((data) => (
                      <div className="col-lg-4 col-md-4 col-sm-6 col-6 mb-4">
                        <div className="wrap_over_card">
                          <div className="wrap_img_txt">
                            <figure>
                              <img src={data.image} alt="" />
                            </figure>
                            <span>{data.name}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* <div>
                      <button className="btn_see_more">Read More</button>
                    </div> */}
                  </div>
                </div>
                <div className="wrap_overview wrap_facilities">
                  <h6>Amenities</h6>
                  <div className="row">
                    {filterAmenites?.map((data) => (
                      <div className="col-lg-4 col-md-4 col-sm-6 col-6 mb-4">
                        <div className="wrap_over_card">
                          <div className="wrap_img_txt">
                            <figure>
                              <img src={data.image} alt="" />
                            </figure>
                            <span>{data.name}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* <div>
                      <button className="btn_see_more">Read More</button>
                    </div> */}
                  </div>
                </div>
                {reviewRatingList?.length > 0 && (
                  <div className="wrap_overview wrap_ratings">
                    <div className="wrap_div">
                      <div>
                        <h6>Rating & Reviews</h6>
                        <span className="location_tag">
                          <FaStar />
                          {details?.rating}{" "}
                          <span className="location_tag">
                            ({reviewRatingList?.length} Reviews)
                          </span>
                        </span>
                      </div>
                      <div className="cstm_select">
                        <Form.Select
                          name="gender"
                          className="btn_see_more cstm_select_btn"
                          aria-label="Default select example"
                        >
                          <option>Sort by</option>
                          <option value="male">Ratings</option>
                          <option value="female">Date</option>
                        </Form.Select>
                      </div>
                    </div>
                    <div className="row">
                      {reviewRatingList
                        ?.slice(0, displayCount)
                        .map((data, index) => (
                          <div
                            key={index}
                            className="col-lg-6 col-md-6 col-sm-6"
                          >
                            <div className="wrap_review">
                              <span>{data?.review}</span>
                              <div className="wrap_review_user">
                                <figure>
                                  <img
                                    alt=""
                                    src={
                                      data?.user?.image
                                        ? data?.user?.image
                                        : face_img
                                    }
                                    className="wrap_dealer"
                                  />
                                </figure>
                                <div className="wrap_review_star">
                                  <p>{data?.user?.name}</p>
                                  <div className="wrap_star_dots">
                                    <StarRatings
                                      rating={data.rating}
                                      starRatedColor="#ffb229"
                                      starHoverColor="#fca120"
                                      numberOfStars={5}
                                      name={`rating-${index}`}
                                    />
                                    <span>
                                      <GoDotFill />{" "}
                                      {DateConversion(data?.createdAt)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      {!showAllReviews && (
                        <div className="btn_see_wrapper">
                          <button
                            className="btn_see_more"
                            onClick={toggleShowAllReviews}
                          >
                            Show All {reviewRatingList?.length} Reviews
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="wrap_overview wrap_Things">
                  <h6>Things to Know</h6>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="wrap_things">
                        <p>House Rules</p>
                        <span>{details?.propertyRule}</span>
                      </div>
                      {/* <div className="btn_see_wrapper">
                        <button className="btn_see_more">Learn more</button>
                      </div> */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 top_space">
                      <div className="wrap_things">
                        <p>Cancellation Policy</p>
                        {/* <span>
                          We ask every guest to remember a few simple things
                          about what makes a great guest.
                        </span> */}
                        <ul>
                          {list?.map((policy) => (
                            <li>{policy?.cancelPolicy}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="inner_chat_card">
                <div className="card_chat">
                  <div className="wrap_chat_img">
                    <figure>
                      <img
                        src={!userDetail?.image ? face_img : userDetail?.image}
                        alt=""
                      />
                    </figure>
                    <div className="hosted">
                      <p>Entire Property hosted by</p>
                      <h5>
                        {userDetail?.name}{" "}
                        {userDetail?.isUserIdProofVerify && <MdVerified />}
                      </h5>
                    </div>
                  </div>
                  <div className="wrap_chat_btn">
                    <button
                      className="chat_now_btn"
                      onClick={() => navigateChat()}
                    >
                      <BsChatText />
                      Chat Now
                    </button>
                  </div>
                </div>
                <div className="lease">
                  <p>Lease Length {errorStateLeaseLength && <span className="cstm_error_clr">*</span> }</p>
                  <div className="wrap_label">
                    {LeaseLength &&
                      filterJson(LeaseLength, details?.leaselength)?.map(
                        (data) => (
                          <label>
                            <input
                              
                              type="radio"
                              name="radio"
                              onClick={() => setLeaseLength(data?.daysCount)}
                            />
                            <span>{data?.name}</span>
                          </label>
                        )
                      )}
                  </div>
                  <div className="wrap_datePicker">
                    <p>Move-in Date</p>
                    {/* <input
                      className="cstm_input_profile"
                      type="date"
                      placeholder="Select Date"
                      min={
                        getCurrentDate(details?.availableFrom)?.availableFrom
                      }
                      value={date}
                      max={getCurrentDate(details?.availableFrom)?.endDate}
                      onChange={(event) => setDate(event.target.value)}
                    /> */}
                    <DatePicker  onChange={(date) => setDate(date)}
                      placeholderText="Select a date"
                      selected={date}
                      minDate={getCurrentDate(details?.availableFrom)?.availableFrom}
                      maxDate={getCurrentDate(details?.availableFrom)?.endDate}
                      className={`cstm_input_profile ${errorDateState && "cstm_errors"}`}
                    />
                    <figure className="calendar_icon">
                      <img src={calendarcstm} alt="" />
                    </figure>

                  </div>
                </div>
                <div className="btn_book_now">
                  <button
                    className="btn_see_more"
                    onClick={() => bookingRequest()}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Lightbox
          open={open}
          plugins={[Thumbnails, Zoom, Video]}
          close={() => {
            setOpen(false);
            setImage([]);
          }}
          slides={images}
        />
      </div>
      <CommonModal
        show={showModal}
        customClass="wrap_modal"
        handleClose={handleClose}
        title="Please Login"
      >
        <div className="text-center">
          <button className="common_btn" onClick={() => navigate("/auth")}>
            Go to login
          </button>
        </div>
      </CommonModal>
    </div>
  );
};

export default PropertyDetail;
