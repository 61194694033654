import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { filterChat, getDecryptUserDetail } from "../../helper/common";
import { socket } from "../../socket";
import { useDispatch, useSelector } from "react-redux";
import { getChatState, setArchivedStatus, setChat, setViewProperty } from "./chatSlice";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { Archived } from "../../DefaultImport";
import { IoIosSearch } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import face_img from "../../assets/images/face1.png";

// import { MdKeyboardArrowLeft } from "react-icons/md";

const ChatUserList = () => {
  const {handleMountState,archiveStatus} = useSelector(getChatState)
  const dispatch = useDispatch();
  const getUser = JSON.parse(getDecryptUserDetail());
  const { state } = useLocation();
  const [userList, setUserList] = useState([]);
  // const [archivedStatus, setArchiveStatus] = useState(false);
  console.log("archived",archiveStatus)
  const [archivedChat, setArchiveChat] = useState([]);
  const [active, setActive] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [payload, setPayload] = useState({
    senderId: getUser?._id,
    receiverId: state?.detail?.userDetail._id,
    property: state?.detail?._id,
    userType: "user",
  });
  socket.on("chatUserList", (user) => {
    const { result } = user;
    const { chats, archived } = result;
    console.log("archived123",archived,getUser?._id)
    setArchiveChat(archived);
    setUserList(chats);
    setChatList(chats);
  });

  const navigateChate = (e, user, i) => {
    try {
      e.preventDefault();
      setActive(i);
      const { roomId, senderId, receiverId, propertyDetails, receiverdata } =
        user;
      dispatch(
        setChat({
          roomId,
          senderId,
          receiverId,
          propertyDetails,
          receiverdata,
        })
      );
      socket.emit("chatMessage", {
        roomId,
        senderId,
      });
      dispatch(setViewProperty(true));
    } catch (error) {
      toast.error("Something Went wrong");
    }
  };
  useEffect(() => {
    socket.connect();
    // if (payload?.receiverId) socket.emit("initiateChat", payload);
    socket.emit("chatList", { senderId: getUser?._id });
  }, [getUser?._id, payload,handleMountState]);

  const navigation = () => {
    document.body.classList.remove("innerside_toogle");
  };
  const searchChat = (e) => {
    const searchTerm = e.target.value.trim().toLowerCase();
    if (searchTerm === "") {
      // If search input is empty, bind original userList
      setUserList(chatList);
    } else {
      // Filter the original userList based on search term
      const filteredList = userList.filter((user) =>
        user.receiverdata.name.toLowerCase().includes(searchTerm)
      );
      setUserList(filteredList);
    }
  };
  console.log("userList",userList)

  return (
    <div className="wrapper_accont_setting">
      <div className="head_setting">
        <h6>
          {archiveStatus === true && (
            <FaAngleLeft
              className="cstm_left_arrow"
              onClick={() => dispatch(setArchivedStatus(archiveStatus))}
            />
          )}
          {archiveStatus === false ? "Messages" : "Archive Chats"}
        </h6>
        <span className="me-0" onClick={navigation}>
          <RxCross2 />
        </span>
      </div>
      <div className="wrap_nav">
        <div className="search_chat">
          <IoIosSearch />
          <input
            onChange={searchChat}
            type="search"
            className="cstm_input_profile chat_input"
            placeholder="Search messages..."
          />
        </div>
        {archiveStatus === false && (
          <div className="wrap_archived">
            <NavLink to="#" onClick={() => dispatch(setArchivedStatus(archiveStatus))}>
              <div className="wrap_img">
                <figure>
                  <img src={Archived} alt="" />
                </figure>
                <span>Archived Chats</span>
              </div>
              <FaAngleRight />
            </NavLink>
          </div>
        )}
        <ul type="none">
          {archiveStatus === false &&
            userList?.map((data, i) => {
              // Check if data.chatMessges is empty
              if (!data?.lastMessage) {
                return null; // Skip rendering if chat messages are empty
              }
              return (
                <li key={i} onClick={navigation}>
                  <div
                    className={`cstm_active_wrap ${active === i && "active"}`}
                    onClick={(e) => navigateChate(e, data, i)}
                  >
                    <div className="wrap_img">
                      <figure>
                        <img
                          src={
                            data?.receiverdata?.image
                              ? data?.receiverdata?.image
                              : face_img
                          }
                          alt=""
                        />
                      </figure>
                      <div className="wrap_today">
                        <div className="flex_tag_span">
                          <p>{data?.receiverdata?.name} ({data?.propertyDetails?.title})</p>
                          <span>{data.day}</span>
                        </div>
                        <span>{data?.lastMessage.substring(0, 40)}</span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}

          {archiveStatus === true &&
             archivedChat?.map((data, i) => {
              // Check if data.chatMessges is empty
              if (!data?.lastMessage) {
                return null; // Skip rendering if chat messages are empty
              }
              return (
                <li key={i} onClick={navigation}>
                  <div
                    className={`cstm_active_wrap ${active === i && "active"}`}
                    onClick={(e) => navigateChate(e, data, i)}
                  >
                    <div className="wrap_img">
                      <figure>
                        <img
                          src={
                            data?.receiverdata?.image
                              ? data?.receiverdata?.image
                              : face_img
                          }
                          alt=""
                        />
                      </figure>
                      <div className="wrap_today">
                        <div className="flex_tag_span">
                          <p>{data?.receiverdata?.name} ({data?.propertyDetails?.title})</p>
                          <span>{data.day}</span>
                        </div>
                        <span>{data?.lastMessage.substring(0, 40)}</span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default ChatUserList;
