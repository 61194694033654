import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { MdAccessTimeFilled } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import {
  Logo,
  facebook,
  twitter,
  instagram,
  footer_gallery,
} from "../../../DefaultImport";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="Custom_container">
          <div className="wrap_footer">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="footer_card_wrap">
                  <figure className="logo_foot">
                    <img src={Logo}></img>
                  </figure>
                  <p>
                  At Ycasa, we believe that every home has a story to tell, and every traveler deserves a unique and authentic experience. We're here to make your stay in Austin unforgettable, whether you're visiting for business or pleasure.



                  </p>
                  {/* <div className="social_icons_wrap">
                    <Link to="#">
                      <img className="me-2" src={facebook}></img>
                    </Link>
                    <Link to="#">
                      <img className="me-2" src={twitter}></img>
                    </Link>
                    <Link to="#">
                      <img src={instagram}></img>
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="footer_card_wrap">
                  <p className="cstm_p">Contact</p>
                  <span className="span_cstm"></span>
                  <ul>
                    <li>
                      <MdLocationPin />
                      2464 Royal Ln. Mesa, New Jersey 45463
                    </li>
                    <li>
                      <FaPhone />
                      <Link to="tel:+1 752 256 5968">+1 752 256 5968</Link>
                    </li>
                    <li>
                      <IoMdMail />
                      <Link to="mailto:info@ycasa.com">info@ycasa.com</Link>
                    </li>
                    <li>
                      <MdAccessTimeFilled />
                      Mon-Fri: 9:00 am – 6:00 pm
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="footer_card_wrap">
                  <p className="cstm_p">Usefull Links</p>
                  <span className="span_cstm"></span>
                  <ul>
                    <li>
                      <Link to="/property_list">Home</Link>
                    </li>
                    <li>
                      <Link to="/account_setting/about_us">About Us</Link>
                    </li>
                    {/* <li>
                      <Link to="#">Testimonials</Link>
                    </li>
                    <li>
                      <Link to="#">Blogs</Link>
                    </li> */}
                    <li>
                      {/* <Link to="#">Contact Us</Link> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div className="footer_card_wrap">
                  <p className="cstm_p">AWESOME GALLERY</p>
                  <span className="span_cstm"></span>
                  <ul>
                    <li>
                      <figure className="wrap_gallery">
                        <img src={footer_gallery}></img>
                      </figure>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
