import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  Completed,
  Ongoing,
} from "../../DefaultImportComponent";
const MyStaysBooking = () => {
  return (
    <div className="wrap_booking_heading">
      <div className="wrap_faq_tabs">
        <Tabs
          defaultActiveKey="completed"
          id="controlled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="completed" title="Completed">
            <Completed />
          </Tab>
          <Tab eventKey="pending" title="Ongoing">
            <Ongoing />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default MyStaysBooking;
