import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	roomId: null,
	receiverId: null,
	senderId: null,
	propertyDetail: null,
	receiverdata: null,
	viewPropertyStatus: true,
	handleMountState: true,
	archiveStatus: false
}

export const chatSlice = createSlice({
	name: "chat",
	initialState,
	reducers: {
		setChat: (state, action) => {
			const { roomId, receiverId, senderId, propertyDetails, receiverdata } = action.payload
			state.roomId = roomId
			state.receiverId = receiverId
			state.senderId = senderId
			state.propertyDetail = propertyDetails
			state.receiverdata = receiverdata
		},
		setViewProperty: (state, action) => {
			state.viewPropertyStatus = action.payload
		},
		setEmpty: (state, action) => {
			state.roomId = null
			state.receiverId = null
			state.propertyDetail = null
			state.receiverdata = null
			state.viewPropertyStatus = true
		},
		setHandleMountState: (state, action) => {
			state.handleMountState = !action.payload
		},
		setArchivedStatus: (state, action) => {
			state.archiveStatus = !action.payload
		}
	}
})

export const {setChat,setViewProperty,setEmpty,setHandleMountState,setArchivedStatus} = chatSlice.actions
export const getChatState = (state) => state.chat
export default chatSlice.reducer
