import React, { useState } from "react";
import Slider from "react-slick";
import { Figmahouse } from "../../DefaultImport";

const Corousel = () => {
  const [imagesLoaded, setImagesLoaded] = useState([false, false, false]);

  const handleImageLoad = (index) => {
    setImagesLoaded((prevImagesLoaded) =>
      prevImagesLoaded.map((value, i) => (i === index ? true : value))
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="wrap_crousel">
      <Slider {...settings}>
        {[0, 1, 2].map((index) => (
          <div className="item" key={index}>
            <figure>
              <img
                alt=""
                src={Figmahouse}
                onLoad={() => handleImageLoad(index)}
              />
            </figure>
            <div className="wrap_card_txt">
              {imagesLoaded[index] ? (
                <>
                  <h5>Interact with Verified Property Owners</h5>
                  <p>
                  Directly connect with trusted property owners for secure transactions and hassle-free communication. Your satisfaction guaranteed!
                  </p>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Corousel;
