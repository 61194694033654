import React, { useEffect, useState } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { FaStar } from "react-icons/fa6";
import { MdLocationPin } from "react-icons/md";
import { useQuery } from "@apollo/client";
import { BOOKING_LIST } from "../../service/booking/bookingQuery";
import { GET_JSON_DATA } from "../../service/home/homeQuery";
import { filterBooking } from "../../helper/common";
import moment from "moment";
import { BookingShimmer, NoData } from "../../DefaultImportComponent";
import { useNavigate } from "react-router-dom";
import face_img from "../../assets/images/face1.png";

const AllBooking = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, refetch,loading } = useQuery(BOOKING_LIST, {
    variables: {
      input: {
        page: page,
        limit: 6,
        userType: "user",
      },
    },
  });
  const { userAndOwnerBookingList } = data || {};
  const { bookingList, totalCount } = userAndOwnerBookingList || {};
  const { data: jsonData } = useQuery(GET_JSON_DATA);
  const { getJsonData } = jsonData || {};
  const pageCount = Math.ceil((totalCount || 0) / 6);

  const { BookingStatus } = getJsonData || {};

  const navigateBookingDetail = (id, type) => {
    const bookingStatus = filterBooking(BookingStatus, type);
    navigate(`/booking/${id}/${bookingStatus?.name}`);
  };
  const prevPage = () => {
    if (page > 1) setPage(page - 1);
  };
  const nextPage = () => {
    if (page < pageCount) setPage(page + 1);
  };
  const handlePageChange = (event) => {
    if (!event.target.value || event.target.value > pageCount) {
      setPage(1);
    } else {
      setPage(Number(event.target.value));
    }
  };
  useEffect(() => {
    refetch();
  }, [refetch]);
  const shimmerMap = [1,2,3,4,5,6]
  return (
    <div className="wrap_tabs_card">
     
      <div className="row position_set_pagination">
      {loading && shimmerMap?.map(() => (
        <BookingShimmer />
      ))}
        {bookingList?.map((booking) => (
          <div
            className="col-lg-6 col-md-12 col-sm-12 mb-4"
            key={booking._id}
            onClick={() =>
              navigateBookingDetail(booking._id, booking?.bookingStatus)
            }
          >
            <div className="wrap_listing_card">
              <div className="recommend_list">
                <figure>
                  <img alt="" src={booking.property.images[0]}></img>
                  <span>{booking?.property?.propertyType === "1" ? "House": "Condo"}</span>
                </figure>
                <div className="cstm_wrap_card_list">
                  <div className="wrap_value">
                    <p className="cstm_p">
                      Booking ID <span> {booking?.bookingNumber} </span>
                    </p>
                    {booking.property.rating > 0 &&
                      <span className="wrap_rating">
                      <FaStar /> {booking.property.rating}
                    </span>
                    }
                  </div>
                  <p>
                    <b>$ {booking?.monthlyRentOfproperty} </b> <span> /month</span>
                  </p>
                  <span className="property_location">
                    by {booking.owner.name}{" "}
                    <span>
                      {" "}
                      <GoDotFill className="god_cstm_fill" /> Today
                    </span>
                  </span>
                  <span className="property_location">
                    <MdLocationPin /> {booking.property.address}
                  </span>
                  <span className="property_location">
                    <FaRegCalendarAlt /> Starts from{" "}
                    {moment(booking?.moveInDate).format("D MMM")}
                  </span>
                  <div className="wrap_hosted">
                    <div className="wrap_fig_img">
                      <figure>
                        <img src={booking?.property?.userDetail?.image || face_img} alt="" />
                      </figure>
                      <span>Hosted by {booking?.property?.userDetail?.name}</span>
                    </div>
                    <span
                      className={filterBooking(
                        BookingStatus,
                        booking?.bookingStatus
                      )?.name?.toLowerCase()}
                    >
                      {
                        filterBooking(BookingStatus, booking?.bookingStatus)
                          ?.name
                      }
                    </span>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {bookingList?.length === 0 && <NoData />}
        {bookingList?.length !== 0 && (
          <div className="wrap_pagination">
            <div className="wrap_input_no">
              <span> Page </span>&nbsp;
              <input
                className="wrap_input_pagination"
                type="number"
                min={1}
                max={pageCount}
                value={page}
                onChange={(e) => handlePageChange(e)}
              />
              &nbsp; <span> Of {pageCount} </span>
            </div>
            <div className="wrap_next_prev">
              <button
                className="btn_see_more_pagination me-4"
                onClick={() => prevPage()}
              >
                <MdKeyboardArrowLeft /> Previous
              </button>
              <button
                className="btn_see_more_pagination next_btn"
                onClick={() => nextPage()}
              >
                Next Page <MdKeyboardArrowRight />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllBooking;
