import React from "react";
import { Header } from "../../../DefaultImportComponent";
import { Link } from "react-router-dom";

const StaticPolicy = () => {
  return (
    <div className="wrapper-static_terms">
      <Header />
      <div className="wrap_static_terms">
        <div className="tittle">
          <h1>Privacy Policy</h1>
          <p>Legal Terms - Last update on 20 Jun 2024</p>
        </div>

        <div className="wrap_content">
          <div className="Custom_container">
            <div className="wrap_terms_content">
              <div className="wrap_txt">
                {/* <h5>TERMS OF SERVICE AGREEMENT</h5> */}
                <p>
                  Thank you for choosing to be part of our community at
                  CloakUnity LLC, a Texas limited liability company doing
                  business as “YCasa”, (“Company”, “we”, “us”, or “our”). We are
                  committed to protecting your personal information and your
                  right to privacy. If you have any questions or concerns about
                  our policy, or practices with regards to your personal
                  information, please contact us at estevan@cloakunity.com.
                </p>
                <p>
                  When you visit our website Ycasa.io, mobile application, or
                  use our services, you trust us with your personal information.
                  We take your privacy very seriously. In this privacy notice,
                  we describe our Privacy Policy. We seek to explain to you in
                  the clearest way possible what information we collect, how we
                  use it, and what rights you have in relation to it. We hope
                  you take some time to read through it carefully, as it is
                  important. If there are any terms in this Privacy Policy that
                  you do not agree with, please discontinue our Sites, Apps, and
                  our services.
                </p>
                <p>
                  This Privacy Policy applies to all information collected
                  through our website (such as Ycasa.io), mobile application
                  (“Apps”), and/or any related services, sales, marketing, or
                  events (we refer to them collectively in this Privacy Policy
                  as the “Sites”).
                </p>
                <p>
                  Please read this Privacy Policy carefully as it will help you
                  make informed decisions about sharing your personal
                  information with us.
                </p>
              </div>
              <div className="wrap_txt">
                <h5>TABLE OF CONTENTS</h5>
                <ul className="number_order">
                  <li>
                    <p>WHAT INFORMATION DO WE COLLECT?</p>
                  </li>
                  <li>
                    <p>HOW DO WE USE YOUR INFORMATION</p>
                  </li>
                  <li>
                    <p>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
                  </li>
                  <li>
                    <p>WHO WILL YOUR INFORMATION BE SHARED WITH?</p>
                  </li>
                  <li>
                    <p>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
                  </li>
                  <li>
                    <p>HOW LONG DO WE KEEP YOUR INFORMATION?</p>
                  </li>
                  <li>
                    <p>HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
                  </li>
                  <li>
                    <p>WHAT ARE YOUR PRIVACY RIGHTS?</p>
                  </li>
                  <li>
                    <p>CONTROLS FOR DO-NOT-TRACK FEATURES?</p>
                  </li>
                  <li>
                    <p>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
                  </li>
                  <li>
                    <p>DO WE MAKE UPDATES TO THIS POLICY?</p>
                  </li>
                  <li>
                    <p>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</p>
                  </li>
                </ul>
                <h5 className="mb-4">1. WHAT INFORMATION DO WE COLLECT?</h5>
                <p>
                  <b> Personal information you disclose to us</b>
                </p>
                <p>
                  <b>In Short:</b> We collect personal information that you
                  provide to us such as name, address, contact information,
                  passwords and security data, and payment information.
                </p>
                <p>
                  We collect personal information that you voluntarily provide
                  to us when registering at the Sites or Apps, expressing an
                  interest in obtaining information about us or our products and
                  services, when participating in activities on the Sites, Apps,
                  or otherwise contacting us.
                </p>
                <p>
                  The personal information that we collect depends on the
                  context of your interactions with us and the Site or Apps, the
                  choice you make, and the products and features you use. The
                  personal information we collect can include the following:
                </p>
                <p>
                  <b>Name and Contact Data.</b> We collect your first and last
                  name, email address, postal address, phone number, and other
                  similar contact data.
                </p>
                <p>
                  <b>Credentials.</b> We collect passwords, password hints, and
                  similar security information used for authentication and
                  account access.
                </p>
                <p>
                  <b>Payment Data.</b> We collect data necessary to process your
                  payment if you make purchases, such as your payment instrument
                  number (such as credit card number), and the security code
                  associated with your payment instrument. All payment data is
                  stored by our payment processor, and you should review its
                  privacy policies and contact the payment processor directly to
                  respond to your questions.
                </p>
                <p>
                  All personal information that you provide to us must be true,
                  complete and accurate, and you must notify us of any changes
                  to such personal information.
                </p>
              </div>
              <div className="wrap_txt">
                <p>
                  <b>Information automatically collected</b>
                </p>
                <p>
                  <b>In Short:</b> Some information – such as IP address and/or
                  browser and device characteristics – is collected
                  automatically when you visit our Sites or Apps.
                </p>
                <p>
                  We automatically collect certain information when you visit,
                  use, or navigate the Sites or Apps. This information does not
                  reveal your specific identify (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our Sites or Apps and other technical information. This
                  information is primarily needed to maintain the security and
                  operating of our Sites or Apps, and for our internal analytics
                  and reporting purposes.
                </p>
                <p>
                  Like many businesses, we also collect information through
                  cookies and similar technologies.
                </p>
              </div>
              <div className="wrap_txt">
                <p>
                  <b>Information collected through our Apps</b>
                </p>
                <p>
                  <b>In Short:</b> We may collect information regarding your
                  geo-location, mobile device, push notifications, when you use
                  our apps.
                </p>
                <p>
                  If you use our Apps, we may also collect the following
                  information:
                </p>
                <ul className="cstm_list_dots">
                  <li>
                    <p>
                      Geo-Location Information. We may request access or
                      permission to and track location-based information from
                      your mobile device, either continuously or while you are
                      using our mobile application, to provide location-based
                      services. If you wish to change our access or permissions,
                      you may do so in your device’s settings.
                    </p>
                  </li>
                  <li>
                    <p>
                      Mobile Device Access. We may request access or permission
                      to certain features from your mobile device, including
                      your mobile device’s Bluetooth, calendar, camera,
                      contacts, microphone, reminders, sensors, sms messages,
                      social media accounts, storage, and other features. If you
                      wish to change our access or permissions, you may do so in
                      your device’s settings.
                    </p>
                  </li>
                  <li>
                    <p>
                      Mobile Device Data. We may atomically collect device
                      information (such as your mobile device ID, model, and
                      manufacturer), operating system, version information, and
                      IP address.
                    </p>
                  </li>
                  <li>
                    <p>
                      Push Notifications. We may request to send you push
                      notifications regarding your account for the mobile
                      application. If you wish to opt-out from receiving these
                      types of communications, you may turn them off in your
                      device’s settings.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <p>
                  <b>Information collected from other sources</b>
                </p>
                <p>
                  <b>In Short:</b> We may collect limited data from public
                  databases, marketing partners, and other outside sources.
                </p>
                <p>
                  We may obtain information about you from other sources, such
                  as public databases, joint marketing partners, as well as from
                  other third-parties. Examples of the information we receive
                  from other sources include: social media profile information;
                  marketing leads, and search results and links, including paid
                  listings (such as sponsored links).
                </p>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">2. HOW DO WE USE YOUR INFORMATION?</h5>
                <p>
                  <b>In Short:</b> We process your information for purposes
                  based on legitimate business interests, the fulfillment of our
                  contract with you, compliance with our legal obligations,
                  and/or your consent.
                </p>
                <p>
                  We use personal information collected via our Sites or Apps
                  for a variety of businesses purposes described below. We
                  process your personal information for these purposes in
                  reliance on our legitimate business interests, in order to
                  enter into or perform a contract with you, with your consent,
                  and/or for compliance with our legal obligations. We indicate
                  the specific processing grounds we rely on next to each
                  purpose listed below.
                </p>
                <p>We use the information we collect or receive:</p>
                <ul className="cstm_list_dots">
                  <li>
                    <p>
                      <b>To facilitate account creation and logon process.</b>
                      If you choose to link your account with us to a
                      third-party account, such as your Google or Facebook
                      account, we use the information you allowed us to collect
                      from those third-parties to facilitate account creation
                      and logon process.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        To send you marketing and promotional communications.
                      </b>
                      We and/or our third-party marketing partners may use the
                      personal information you send to us for our marketing
                      purposes, if this is in accordance with your marketing
                      preferences. You can opt-out of our marketing emails at
                      any time ( see WHAT ARE YOUR PRIVACY RIGHTS below).
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>To send administrative information to you.</b>
                      We may use your personal information to send you product,
                      service, and new feature information and/or information
                      about changes to our terms, conditions, and policies.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Fulfill and manage your orders.</b>
                      We may use your information to fulfill and manage your
                      orders, payments, returns, and exchanges made through the
                      Sites or Apps.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>To protect our Sites.</b>
                      We may use your information as part of our efforts to keep
                      our Sites or Apps free and secure (for example, of fraud
                      monitoring and prevention).
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>o enforce our terms, conditions, and policies.</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>To respond to legal requests and prevent harm.</b>
                      If we receive a subpoena or other legal request, we may
                      need to inspect the data we hold to determine how to
                      respond.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>For other Business Purposes.</b>
                      We may use your information for other Business Purposes,
                      such as data analysis, identifying usage trends,
                      determining the effectiveness of our promotional campaigns
                      and to evaluate and improve our Sites or Apps, products,
                      services, marketing, and your experience.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">
                  3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                </h5>
                <p>
                  <b>In Short:</b> We only share information with your consent,
                  to comply with laws, to protect your rights, or to fulfill
                  business obligations.
                </p>
                <p>
                  We may process or share data based on the following legal
                  basis:
                </p>
                <ul className="cstm_list_dots">
                  <li>
                    <p>
                      <b>Consent:</b>
                      We may process your data if you have given us specific
                      consent to use your personal information in a specific
                      purpose.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Legitimate Interests:</b>
                      We may process your data when it is reasonably necessary
                      to achieve our legitimate business interests.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Performance of a Contract.</b>
                      Where we have entered into a contract with you, we may
                      process your personal information to fulfill the terms of
                      our contract.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Legal Obligations:</b>
                      We may disclose your information where we are legally
                      required to do so in order to comply with applicable law,
                      governmental requests, a judicial proceeding, court order,
                      or legal process, such as in response to a court order or
                      a subpoena (including in response to public authorities to
                      meet national security or law enforcement requirements).
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Vital Interests:</b>
                      We may disclose your information where we believe it is
                      necessary to investigate, prevent, or take action
                      regarding potential violations or our policies, suspected
                      fraud, situations involving potential threats to the
                      safety of any persona and illegal activities, or as
                      evidence in litigation in which we are involved.
                    </p>
                  </li>
                </ul>
                <p>
                  More specifically, we may need to process your data or share
                  your personal information in the following situations:
                </p>
                <ul className="cstm_list_dots">
                  <li>
                    <p>
                      <b>
                        Vendors, Consultants, and Other Third-Party Service
                        Providers.
                      </b>
                      We may share your data with third-party vendors, service
                      providers, contractors or agents who perform services for
                      us or on our behalf and require access to such information
                      to do that work. Examples include: payment processing,
                      data analysis, email delivery, hosting services, customer
                      service, and marketing efforts. We may allow selected
                      third-parties to use tracking technology on the Sites or
                      Apps, which will enable them to collect data about how you
                      interact with the Sites or Apps over time. This
                      information may be used to, among other things, analyze
                      and track data, determine the popularity of certain
                      content, and better understand online activity. Unless
                      described in this Policy, we do not share, sell, rent, or
                      trade any of your information with third-parties for their
                      promotional purposes.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Business Transfers.</b>
                      We may share or transfer your information in connection
                      with, or during negotiations of, any merger, sale of
                      company assets, financing, or acquisition of all or a
                      portion of our business to another company.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Business Partners.</b>
                      We may share your information with our business partners
                      to offer you certain products, service, or promotions.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">
                  4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                </h5>
                <p>
                  <b>In Short:</b> We only share information with the following
                  third-parties.
                </p>
                <p>
                  We only share and disclose your information with the following
                  third-parties. We have categorized each party so that you may
                  easily understand the purpose of our data collection and
                  processing practices. If we have processed your data based on
                  your consent, and you wish to revoke your consent, please
                  contact us.
                </p>
                <ul className="cstm_list_dots">
                  <li>
                    <p>
                      <b>Advertising, Direct Marketing, and Lead Generation:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Affiliate Marketing Programs:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>
                        Allow Users to Connect to their Third-Party Accounts:
                      </b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Communicate and Chat with Users:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Content Optimization:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Data Backup and Security:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Functionality and Infrastructure Optimization:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Invoice and Billing:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Retargeting Platforms:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Social Media Sharing and Advertising:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>User Account Registration and Authentication:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>User Commenting and Forms:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Web and Mobile Analytics:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Website Hosting:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Website Performance Monitoring:</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Website Testing:</b>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">
                  5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </h5>
                <p>
                  <b>In Short:</b> We may use cookies and other tracking
                  technologies to collect and store your information.
                </p>
                <p>
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information. Specific
                  information about how we use such technologies and how you can
                  refuse certain cookies is set out in our Cookie Policy.
                </p>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">
                  6. HOW LONG DO WE KEEP YOUR INFORMATION?
                </h5>
                <p>
                  <b>In Short:</b>
                  We keep your information for as long as necessary to fulfill
                  the purposes outlined in this Privacy Policy, unless otherwise
                  required by law.
                </p>
                <p>
                  We will only keep your personal information for as long as it
                  is necessary for the purposes set out in this Privacy Policy,
                  unless a longer retention period is required or permitted by
                  law (such as tax, accounting, or other legal requirements) No
                  purpose in this policy will require us keeping your personal
                  information for longer than the period of time in which users
                  have an account with us.
                </p>
                <p>
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  it, or, if this is not possible (for example, because your
                  personal information has been stored in backup achieves), then
                  we will securely store your personal information and isolate
                  it from any further processing until deletion is possible.
                </p>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">
                  7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h5>
                <p>
                  <b>In Short:</b>
                  We aim to protect your personal information through a system
                  of organizational and technical security measures.
                </p>
                <p>
                  We have implemented appropriate technical and organizational
                  security measures designed to protect the security of any
                  personal information we process. However, despite our
                  safeguards and efforts to secure your information, no
                  electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorized third parties will not be able to defeat
                  our security, and improperly collect, access, steal, or modify
                  your information. Although we will do our best to protect your
                  personal information, transmission of personal information to
                  and from our Website is at your own risk. You should only
                  access the Website within a secure environment.
                </p>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">8. WHAT ARE YOUR PRIVACY RIGHTS?</h5>
                <p>
                  <b>In Short :</b>
                  In some regions, such as the European Economic Area, you have
                  rights that allow you greater access to and control over your
                  personal information. You may review, change, or terminate at
                  any time.
                </p>
                <p>
                  In some regions (like the European Economic Area), you have
                  certain rights under applicable data protection laws. These
                  may include the right (i) to request and obtain a copy of your
                  personal information; (ii) to request rectification or
                  erasure; (iii) to restrict the processing of your personal
                  information; and (iv) if applicable, to data portability. In
                  certain circumstance, you may also have the right to object to
                  the processing of your personal information. To make such a
                  request, please see HOW CAN YOU CONTACT US ABOUT THIS POLICY
                  below. We will consider and act upon any request in accordance
                  with applicable data protection laws.
                </p>
                <p>
                  If we are relying on your consent to process your personal
                  information, you have the right to withdraw your consent at
                  any time. Please note however, that this will not affect the
                  lawfulness of the processing before its withdrawal.
                </p>
                <p>
                  If you are resident in the European Economic Area and you
                  believe we are unlawfully processing your personal
                  information, you also have the right to complain to your local
                  data protection supervisory authority. You can find their
                  contact details here:
                  <Link
                    target="_blank"
                    to={
                      "http://ec.europa.eu/justice/data-protection/bodies/authoriteis/index_en.html"
                    }
                  >
                    http://ec.europa.eu/justice/data-protection/bodies/authoriteis/index_en.html
                  </Link>
                </p>
                <p>
                  <b>Account Information</b>
                </p>
                <p>
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you
                  can:
                </p>
                <ul className="cstm_list_dots">
                  <li>
                    <p>
                      Log into your account settings and update your user
                      account.
                    </p>
                  </li>
                </ul>
                <p>
                  Upon your request to terminate your account, we will
                  deactivate or delete your account and information from our
                  active databases. However, some information may be retained in
                  our files to prevent fraud, troubleshoot problems, assist with
                  any investigations, enforce our Terms of Use and/or comply
                  with legal requirements.
                </p>
                <p>
                  <b>Cookies and similar technologies:</b>
                  Most Web browsers are set to accept cookies by default. If you
                  prefer, you can usually choose to set your browser to remove
                  cookies and to reject cookies. If you choose to remove cookies
                  or reject cookies, this could affect certain features or
                  services of our Sites or Apps. To opt-out of interest-based
                  advertising by advertisers on our Sites or Apps visit
                  <Link to={"https://www.aboutads.info/choices/"}>
                    https://www.aboutads.info/choices/
                  </Link>
                </p>
                <p>
                  <b>Opting out of email marketing:</b>
                  You can unsubscribe from our marketing email list at any time
                  by clicking on the unsubscribe link in the emails that we send
                  or by contacting us using the details provided below. You will
                  then be removed from the marketing email list – however, we
                  will still need to send you service-related emails that are
                  necessary for the administration and use of your account. To
                  otherwise opt-out, you may: I
                </p>
                <ul className="cstm_list_dots">
                  <li>
                    <p>Access your account settings and update preferences.</p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h5>

                <p>
                  Most Web browsers and some mobile operating systems and mobile
                  applications include a Do-Not-Track (“DNT”) feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. No uniform technology standard for recognizing and
                  implementing DNT signals has been finalized. As such, we do
                  not currently respond to DNT browser signals or any other
                  mechanism that automatically communicates your choice not to
                  be tracked online. If a standard for online tracking is
                  adopted that we must follow in the future, we will inform you
                  about that practice in a revised version of this Privacy
                  Policy
                </p>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">
                  10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </h5>
                <p>
                  <b>In Short:</b>
                  Yes, if you are a resident of California, you are granted
                  specific rights regarding access to your personal information.
                </p>
                <p>
                  California Civil Code Section 1798.83, also known as the
                  “Shine the Light” law, permits our users who are California
                  residents to request and obtain from us, once a year, and free
                  of charge, information about categories of personal
                  information (if any) we disclosed to third-parties for direct
                  marketing purposes and the names and addresses of all
                  third-parties with which we shared personal information in the
                  immediately preceding calendar year. If you are a California
                  resident and would like to make such a request, please submit
                  your request in writing to us using the contact information
                  provided below.
                </p>
                <p>
                  If you are under 18 years of age, reside in California, and
                  have a registered account with the Sites or Apps, you have the
                  right to request removal of unwanted data that you publicly
                  post on the Sites or Apps. To request removal of such data,
                  please contact us using the contact information provided
                  below, and include the email address associated with your
                  account and a statement that you reside in California. We will
                  make sure the data is not publicly displayed on the Sites or
                  Apps, but please be aware that the data may not be completely
                  or comprehensively removed from our systems.
                </p>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">11. DO WE MAKE UPDATES TO THIS POLICY?</h5>
                <p>
                  <b>In Short:</b>
                  Yes, we will update this policy as necessary to stay compliant
                  with relevant laws.
                </p>
                <p>
                  We may update this Privacy Policy from time to time. The
                  updated version will be indicated by an updated “Revised” date
                  and the updated version will be effective as soon as it is
                  accessible. If we make material changes to this Privacy
                  Policy, we may notify you either by prominently posting a
                  notice of such changes or by directly sending you a
                  notification. We encourage you to review this Privacy Policy
                  frequently to be informed of how we are protecting your
                  information.
                </p>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-4">
                  12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                </h5>
                <p>
                  If you have questions or comments about this Privacy Policy,
                  you may contact our Data Protection Officer (DPO), Estevan
                  Camarena, by email at estevan@cloakunity.com, by phone at
                  (253) 380-4385, or by post to:
                </p>
                <ul>
                  <li>
                    CloakUnity LLC, doing business as “YCasa” <br /> 13208
                    Bourbon Street
                  </li>
                  <li>Austin, TX 78727</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticPolicy;
