import React, { useState } from "react";
import { mail_img, spin } from "../../DefaultImport";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../shared/header/headerSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  getMailPopupState,
  handleForgetPasswordMailPopup,
  handleLoginMailPopup,
  handleRegisterMailPopup,
  setMailTitle,
} from "./authSlice";
import { useMutation } from "@apollo/client";
import { RESEND_MAIL } from "../../service/auth/autMutation";
import { toast } from "react-toastify";
import { handleApiCall } from "../../service/restApi";
const MailMessage = (data) => {
  const getUser = useSelector(getMailPopupState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id,setId] = useState(data.id)
  const [key, setKey] = useState(data.status);
  const [email, setEmail] = useState(data.email);
  const [ResendMail] = useMutation(RESEND_MAIL);
  const [loader, setLoader] = useState(false);
  const [isSelectedEmail, setIsSelectedEmail] = useState(false);
  const [validation, setValidation] = useState(false);
  const handleEmailChange = (event) => {
    setIsSelectedEmail(false);
    setEmail(event.target.value);
  };
  const backToSignIn = (stateCallback) => {
    dispatch(stateCallback());
    if (data.type === "register") dispatch(handleRegisterMailPopup(false));
    if (data.type === "login") dispatch(handleLoginMailPopup(false));
    if (data.type === "forgetPassword") {
      dispatch(handleForgetPasswordMailPopup(false));
      navigate("/auth");
    }
  };
  const tryAnotherEmail = (title, status) => {
    setKey(status);
    dispatch(setMailTitle(title));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoader(true);
      setValidation(true);
      if (!validateEmail(email)) {
        setIsSelectedEmail(true);
        return; // Update checkStatus if email validation fails
      }
      const updatedUserDetail = {
        ...getUser.userDetail,
        email: email,
        id: id, // Update the email with the new value
      };
      const response = await handleApiCall(
        "post",
        "/user/signUp",
        updatedUserDetail
      );
      if (response.data.success === false) {
        toast.error(response.data.message);
        return;
      }
      setEmail(response.data.data.user.email)
      setId(response.data.data.user._id)
      tryAnotherEmail("Verify Email Address", 0);
    } catch (error) {
      toast.error(error.response.data.message[0]);
    } finally {
      setLoader(false)
    }
  };

  const resendMail = async () => {
    try {
      setLoader(true);
      const response = await ResendMail({
        variables: {
          input: {
            email: getUser.userDetail.email,
            id: id,
          },
        },
      });
      toast.success(response.data.resendEmailVerify.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };

  if (key === 2) {
    return (
      <div className="class_wrap">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <figure className="modal_left_fig">
              <img src={mail_img} alt="" />
            </figure>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="wrap_modal_txt">
              <span>
                The verification link has been sent to your email address.
                Kindly verify your email to proceed further
              </span>
              <button className="cstm_btn" onClick={resendMail}>
                {loader === true ? <img alt="" src={spin} /> : "Resend Link"}
              </button>
              <button
                onClick={() => backToSignIn(setLogin)}
                className="cstm_btn"
              >
                Back to Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (key === 0) {
    return (
      <div className="class_wrap">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <figure className="modal_left_fig">
              <img src={mail_img} alt="" />
            </figure>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="wrap_modal_txt">
              <h3>Check your Mail</h3>
              <span>
                Please check your email inbox. Email verification instructions
                sent to the associated email address.
              </span>
              <button
                onClick={() => backToSignIn(setLogin)}
                className="cstm_btn"
              >
                Back to Sign In
              </button>
              <span>Didn’t receive an email? Check your spam filter or </span>
              <Link onClick={() => tryAnotherEmail("Edit Email Address", 1)}>
                Try another email address
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (key === 1) {
    return (
      <div className="wrap_another_mail">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <figure className="modal_left_fig">
              <img src={mail_img} alt="" />
            </figure>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="wrap_modal_txt">
              <div className="wrap_form_fields">
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  className="cstm_input"
                  placeholder="Enter email address"
                  value={email} // Bind the input value to the state
                  onChange={handleEmailChange} // Handle input changes
                />
                {validation === true && isSelectedEmail === true && (
                  <span className="erro_img">* Please enter valid email</span>
                )}
              </div>
              <button className="cstm_btn" onClick={handleSubmit}>
                {loader === true ? <img alt="" src={spin} /> : "Send"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (key === 3) {
    return (
      <div className="class_wrap">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <figure className="modal_left_fig">
              <img src={mail_img} alt="" />
            </figure>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="wrap_modal_txt mt-4">
              <span>
                If this email address is register in out store, you will recieve
                a link to reset password
              </span>
              <button
                onClick={() => backToSignIn(setLogin)}
                className="cstm_btn"
              >
                Back to Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default MailMessage;
