import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { handleApiCall } from "../../service/restApi";
import { toast } from "react-toastify";
import CommonModal from "../modal/CommonModal";
import MailMessage from "./MailMessage";
import {
  getMailPopupState,
  handleRegisterMailPopup,
  setMailTitle,
  setUserDetail,
} from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import { spin } from "../../DefaultImport";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { getTabState } from "../shared/header/headerSlice";

const Register = () => {
  const tabState = useSelector(getTabState);
  const dispatch = useDispatch();
  const mailPopup = useSelector(getMailPopupState);
  const [showModal, setShowModal] = useState(mailPopup.registerMailPopup);
  const [id, setId] = useState(null);
  const [email, setEmail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [validation, setValidation] = useState(false);
  const [isSelectedPassword, setIsSelectedPassword] = useState(false);
  const [isSelectedEmail, setIsSelectedEmail] = useState(false);
  const [isSelectedMaritalStatus, setIsSelectedMaritalStatus] = useState(false);
  const [isSelectedGender, setIsSelectedGender] = useState(false);
  const [isSelectedName, setIsSelectedName] = useState(false);

  const [formData, setFormData] = useState({
    userType: "user",
    name: "",
    email: "",
    marital_status: "",
    gender: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    // Capitalize the first character of the name input
    if (name === "name" && value.length > 0) {
      updatedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }

    if (name === "password") setIsSelectedPassword(false);
    if (name === "email") setIsSelectedEmail(false);
    if (name === "marital_status") setIsSelectedMaritalStatus(false);
    if (name === "gender") setIsSelectedGender(false);
    if (name === "name") setIsSelectedName(false);

    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setValidation(true);
      setLoader(true);

      let checkStatus = true; // Initialize checkStatus to true

      const passwordRegex = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/;

      if (!formData.name) {
        setIsSelectedName(true);
        checkStatus = false; // Update checkStatus if name validation fails
      }

      if (!validateEmail(formData.email)) {
        setIsSelectedEmail(true);
        checkStatus = false; // Update checkStatus if email validation fails
      }

      if (!passwordRegex.test(formData.password)) {
        setIsSelectedPassword(true);
        checkStatus = false; // Update checkStatus if password validation fails
      }

      if (!formData.marital_status) {
        setIsSelectedMaritalStatus(true);
        checkStatus = false; // Update checkStatus if marital status validation fails
      }

      if (!formData.gender) {
        setIsSelectedGender(true);
        checkStatus = false; // Update checkStatus if gender validation fails
      }

      if (!checkStatus) {
        // Validation failed, return or handle accordingly
        return;
      }

      const { data } = await handleApiCall("post", "/user/signUp", formData);

      if (data.success === false) {
        toast.error(data.message);
        return;
      }

      dispatch(handleRegisterMailPopup(true));
      dispatch(setUserDetail(formData));
      dispatch(setMailTitle("Verify your Email"));
      setId(data.data.user._id);
      setEmail(data.data.user.email);
      setFormData({
        userType: "user",
        name: "",
        email: "",
        marital_status: "",
        gender: "",
        password: "",
      });
    } catch (error) {
      if (error.response.data.message.length > 1) {
        toast.error(error.response.data.message[0]);
        return;
      }
      toast(error.message);
    } finally {
      setLoader(false);
    }
  };

  const handleClose = () => {
    dispatch(handleRegisterMailPopup(false));
  };

  useEffect(() => {
    setShowModal(mailPopup.registerMailPopup);
    setValidation(false);
  }, [mailPopup.registerMailPopup, tabState]);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="wrap_form_fields">
          <label>Full Name</label>
          <input
            type="text"
            name="name"
            className="cstm_input"
            placeholder="Enter Full Name"
            value={formData.name}
            onChange={handleChange}
          />
          {validation === true && isSelectedName && (
            <span className="erro_img">* Required</span>
          )}
        </div>
        <div className="wrap_form_fields">
          <label>Email Address</label>
          <input
            type="email"
            name="email"
            className="cstm_input"
            placeholder="Enter email address"
            value={formData.email}
            onChange={handleChange}
          />
          {validation === true && isSelectedEmail === true && (
            <span className="erro_img">* Please enter valid email</span>
          )}
        </div>
        <div className="wrap_form_fields wrap_form_flex">
          <div className="wrap_select">
            <label>Marital Status</label>
            <Form.Select
              name="marital_status"
              className="cstm_input"
              aria-label="Default select example"
              value={formData.marital_status}
              onChange={handleChange}
            >
              <option>Select Marital Status</option>
              <option value="1">Single</option>
              <option value="2">Married</option>
              <option value="3">Divorced</option>
              <option value="4">Widowed</option>
            </Form.Select>
            {validation === true && isSelectedMaritalStatus && (
              <span className="erro_img">* Required</span>
            )}
          </div>
          <div className="wrap_select">
            <label>Gender</label>
            <Form.Select
              name="gender"
              className="cstm_input"
              aria-label="Default select example"
              value={formData.gender}
              onChange={handleChange}
            >
              <option>Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">LGBTQ+</option>
            </Form.Select>
            {validation === true && isSelectedGender && (
              <span className="erro_img">* Required</span>
            )}
          </div>
        </div>
        <div
          className={`wrap_form_fields mb-1 ${
            validation === true && isSelectedPassword && ""
          }`}
        >
          <label>Password</label>
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            className="cstm_input"
            placeholder="Enter Password"
            value={formData.password}
            onChange={handleChange}
          />
          <span className="cstm_icons_set" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
        {validation === true && isSelectedPassword === true && (
          <span className="erro_img">
            * It should be alphanumeric with min 8 characters & at least 1
            special character.
          </span>
        )}
        <div className="wrap_form_fields wrap_check_flex">
          {/* <Form.Check
          name="agreement"
          aria-label="Agree to terms and conditions"
          checked={formData.agreement}
          onChange={handleChange}
        /> */}
          <span>
            By creating an account, you agree to all the{" "}
            <Link
              // to={`${process.env.REACT_APP_REST_API_URL}/user/termAndCondition`}
              to={"/terms-condition"}
              target="blank"
            >
              Terms & Conditions
            </Link>
            and
            <Link
              // to={`${process.env.REACT_APP_REST_API_URL}/user/privacyPolicy`}
              to={"/privacy-policy"}
              target="blank"
            >
              Privacy Policy.
            </Link>
          </span>
        </div>
        <div className="wrap_form_fields">
          <button type="submit" className="form_sbmt_btn">
            {loader === true ? <img alt="" src={spin} /> : "Sign up"}
          </button>
        </div>
      </form>
      <CommonModal
        show={showModal}
        customClass="wrap_modal"
        handleClose={handleClose}
        title={mailPopup.title}
      >
        <MailMessage status={0} type="register" id={id} email={email} />
      </CommonModal>
    </div>
  );
};

export default Register;
