import React, { useState, useEffect, useRef } from "react";
import "./landing.scss";
import Autocomplete from "react-google-autocomplete";

import {
  playstore,
  appstore,
  About_us,
  About_inner,
  About_arrow,
  About_home,
  About_traced,
  About_Butterfly,
  About_time,
  property_owner,
  test_coats,
  newsteller,
  spin,
} from "../../DefaultImport";
import {
  FaChevronLeft,
  FaChevronRight,
  FaHeart,
  FaStar,
} from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { MdLocationPin } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { IoIosArrowRoundForward, IoIosStar } from "react-icons/io";
import { Footer, Header, PropertyShimmer } from "../../DefaultImportComponent";
import { useQuery } from "@apollo/client";
import {
  BEFORE_LOGIN_PROPERTY,
  GET_JSON_DATA,
} from "../../service/home/homeQuery";
import { filterJsonData, getToken } from "../../helper/common";
import { GoDotFill } from "react-icons/go";
const LandingPage = () => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const url = useLocation();
  const [location, setLocation] = useState(null);
  const { data, loading } = useQuery(BEFORE_LOGIN_PROPERTY, {
    variables: {
      input: {
        latitude: location?.latitude,
        longitude: location?.longitude,
        userType: "user",
        reqType: "123",
        limit: 10,
        page: 1,
      },
    },
  });
  const { data: jsonData } = useQuery(GET_JSON_DATA);
  const { getJsonData } = jsonData || {};
  const { FurnishingType } = getJsonData || {};
  const { homePage } = data || {};
  const { newestProperty, property } = homePage || {};
  const array = [1, 2, 3, 4, 5];

  const NextArrow = ({ onClick }) => (
    <button className="custom-arrow next" onClick={onClick}>
      <FaChevronRight />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button className="custom-arrow prev" onClick={onClick}>
      <FaChevronLeft />
    </button>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 432,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonial = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 868,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 432,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const testcrousal = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handlePlaceSelect = (place) => {
    if (!place || !place?.geometry || !place?.geometry?.location) {
      // Handle the case when place or its geometry is not available
      console.error("Invalid place selected");
      // You can display an error message or take appropriate action
      return;
    }

    const { geometry } = place;
    const { location } = geometry;

    if (!location || !location?.lat || !location?.lng) {
      // Handle the case when latitude or longitude is not available
      console.error("Invalid location selected");
      // You can display an error message or take appropriate action
      return;
    }

    const { lat, lng } = location;

    // Now, dispatch the valid latitude and longitude
    setLocation({
      latitude: lat(),
      longitude: lng(),
    });

    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const isLogin = getToken();
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          // Handle location retrieval error
        }
      );
    } else {
      // Geolocation is not supported
      console.error("Geolocation is not supported by your browser.");
    }
    if (isLogin && url.pathname === "/") {
      navigate("/property_list");
    }
  }, [navigate, url]);

  const navigateHandler=()=>{
navigate("/auth");
  }

  return (
    <div>
      <Header />
      <section className="wrapper_find_and_choose">
        <div className="Custom_container">
          <div className="wrapper_txt_image">
            <div className="wrapper_txt">
              <h1>
                Find <span className="font_light">and</span>{" "}
                <span className="font_heavy">Choose</span>
                <br></br>
                <span className="font_light">Where you will</span> live
              </h1>
              <p className="heading_para">Let’s find a best property for you</p>
              <div className="wrap_play_store">
                <Link className="me-2" to="#">
                  <img alt="" src={appstore}></img>
                </Link>
                <Link to="#">
                  <img alt="" src={playstore}></img>
                </Link>
              </div>
            </div>
            <div className="wrapper_search">
              <div className="cstm_searchbar">
                {/* <div className="wrap_location">
                  <label>Location</label>
                  <Form.Select
                    className="cstm_input"
                    aria-label="Default select example"
                  >
                    <option>Select Location Status</option>
                    <option value="1">Noida</option>
                    <option value="2">Delhi</option>
                  </Form.Select>
                </div> */}
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  className="cstm_input"
                  onPlaceSelected={handlePlaceSelect}
                  options={{ types: [] }}
                  placeholder="Search by locality, type, landmark etc..."
                />
                <button className="nav_btn_cstms form_sbmt_btn">
                  {loading === true ? (
                    <img alt="" src={spin} />
                  ) : (
                    <>
                      <FaSearch /> Search
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrap_about_us">
        <div className="Custom_container">
          <div className="wrap_about_section">
            <div className="row">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div className="wrap_card">
                  <figure className="wrap_fig_img">
                    <img src={About_us}></img>
                  </figure>
                  <figure className="wrap_inner_img">
                    <img src={About_inner}></img>
                  </figure>
                  <figure className="wrap_arrow_img">
                    <img src={About_arrow}></img>
                  </figure>
                </div>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-12">
                <div className="wrap_card-txt">
                  <h6>ABOUT US</h6>
                  <h3>Welcome to Ycasa. Where Every Casa Feels Like Home</h3>
                  <p>
                    At Ycasa, we believe that every home has a story to tell,
                    and every traveler deserves a unique and authentic
                    experience. We're here to make your stay in Austin
                    unforgettable, whether you're visiting for business or
                    pleasure.
                  </p>
                  <ul>
                    <li>
                      <figure>
                        <img src={About_home}></img>
                      </figure>
                      <span>Smart Home Design</span>
                    </li>
                    <li>
                      <figure>
                        <img src={About_Butterfly}></img>
                      </figure>
                      <span>Beautiful Scene Around</span>
                    </li>
                    <li>
                      <figure>
                        <img src={About_time}></img>
                      </figure>
                      <span>Exceptional Lifestyle</span>
                    </li>
                    <li>
                      <figure>
                        <img src={About_traced}></img>
                      </figure>
                      <span>Complete 24/7 Security</span>
                    </li>
                  </ul>

                  <p>
                    Our mission is simple: to provide a seamless and hassle-free
                    platform for homeowners to rent out their spaces and for
                    travelers to find the perfect accommodations in Austin. We
                    want to connect people with the magic of Austin, allowing
                    them to immerse themselves in the vibrant culture, eclectic
                    neighborhoods, and unparalleled hospitality that the city
                    has to offer.
                  </p>

                  <button className="nav_read_more" onClick={navigateHandler}>
                    Read More <IoIosArrowRoundForward />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrap_crousal_landing" ref={sectionRef}>
        {newestProperty?.length !== 0 && newestProperty !== undefined && (
          <div className="Custom_container">
            <div className="parent_wrap_crousal">
              <h5>Newest listings</h5>
              <p>See the most up-to-date listings</p>
              {newestProperty && (
                <Slider {...settings}>
                  {newestProperty?.map((property) => (
                    <div className="wrap_listing_card">
                      <div className="recommend_list">
                        <Slider {...testcrousal}>
                          {property?.images?.map((image) => (
                            <figure>
                              <Link to={"/auth"}>
                                <img alt="" src={image}></img>
                                <span>House</span>
                                <FaHeart className="cstm_unfill" />
                              </Link>
                            </figure>
                          ))}
                        </Slider>
                        <div className="cstm_wrap_card_list">
                          <div className="wrap_value">
                            <b>
                              $ {property?.amount}
                              <span>/Month</span>
                            </b>
                            {property?.rating > 0 && (
                              <span className="wrap_rating">
                                <FaStar />
                                {property?.rating}
                              </span>
                            )}
                          </div>
                          <span>
                            {property?.beds} Beds | {property?.bath} Baths{" "}
                            <GoDotFill className="dot_cstm_fill" />{" "}
                            {FurnishingType &&
                              filterJsonData(
                                FurnishingType,
                                property?.furnishingType
                              ).name}
                          </span>
                          <span className="property_location">
                            <MdLocationPin /> {property?.address}{" "}
                          </span>
                          <div className="wrap_dealer_name">
                            <figure>
                              <img
                                src={
                                  property?.userDetail?.image || property_owner
                                }
                                alt=""
                                className="wrap_dealer"
                              />
                            </figure>
                            <div className="name_wrap">
                              <p>
                                <b>{property?.title}</b>
                              </p>
                              <span>{property?.userDetail?.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
              {
                <Slider {...settings}>
                  {loading && array.map((data) => <PropertyShimmer />)}
                </Slider>
              }
            </div>
          </div>
        )}
      </section>
      <section className="wrap_crousal_landing">
        {property?.length !== 0 && property !== undefined && (
          <div className="Custom_container">
            <div className="parent_wrap_crousal">
              <h5>Recommended listings</h5>
              <p>See the most up-to-date listings</p>
              {property && (
                <Slider {...settings}>
                  {property?.map((property) => (
                    <div className="wrap_listing_card">
                      <div className="recommend_list">
                        <Slider {...testcrousal}>
                          {property?.images?.map((image) => (
                            <figure>
                              <Link to={"/auth"}>
                                <img alt="" src={image}></img>
                                <span>House</span>
                                <FaHeart className="cstm_unfill" />
                              </Link>
                            </figure>
                          ))}
                        </Slider>
                        <div className="cstm_wrap_card_list">
                          <div className="wrap_value">
                            <b>
                              $ {property?.amount}
                              <span>/Month</span>
                            </b>
                            {property?.rating > 0 && (
                              <span className="wrap_rating">
                                <FaStar />
                                {property?.rating}
                              </span>
                            )}
                          </div>
                          <span>
                            {property?.beds} Beds | {property?.bath} Baths{" "}
                            <GoDotFill className="dot_cstm_fill" />{" "}
                            {FurnishingType &&
                              filterJsonData(
                                FurnishingType,
                                property?.furnishingType
                              ).name}
                          </span>
                          <span className="property_location">
                            <MdLocationPin /> {property?.address}{" "}
                          </span>
                          <div className="wrap_dealer_name">
                            <figure>
                              <img
                                src={
                                  property?.userDetail?.image || property_owner
                                }
                                alt=""
                                className="wrap_dealer"
                              />
                            </figure>
                            <div className="name_wrap">
                              <p>
                                <b>{property?.title}</b>
                              </p>
                              <span>{property?.userDetail?.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
              {
                <Slider {...settings}>
                  {loading && array.map((data) => <PropertyShimmer />)}
                </Slider>
              }
            </div>
          </div>
        )}
      </section>
      {/* <section className="wrap_Client_saying wrap_crousal_landing">
        <div className="Custom_container">
          <div className="wrap_client_section">
            <h6>Testimonial</h6>
            <h2>What our client saying</h2>
            <Slider {...testimonial}>
              {array.map(() => (
                <div className="wrapadd_padding">
                  <div className="wrap-testimonial">
                    <figure className="coats_wrap_img">
                      <img src={test_coats} alt="" />
                    </figure>
                    <ul>
                      <li>
                        <IoIosStar />
                      </li>
                      <li>
                        <IoIosStar />
                      </li>
                      <li>
                        <IoIosStar />
                      </li>
                      <li>
                        <IoIosStar />
                      </li>
                      <li>
                        <IoIosStar />
                      </li>
                    </ul>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi{" "}
                    </p>
                    <div className="wrap_dealer_name">
                      <figure>
                        <img src={property_owner} className="wrap_dealer" />
                      </figure>
                      <div className="name_wrap">
                        <p>Susan Harris</p>
                        <span>Designer</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="subscribe_footer">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="wrap_col_subs">
                    <h4>Subscribe Newsletter</h4>
                    <p>
                      Signup Your email address to subscribe our newsletter to
                      get latest post and news about our product and company
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <form>
                    <div className="wrap_input_btn">
                      <input
                        type="text"
                        className="cstm_input"
                        placeholder="Enter Your Email"
                      />
                      <button className="nav_read_more">Subscribe</button>
                    </div>
                  </form>
                </div>
              </div>
              <figure className="cstm_subscribe_right">
                <img src={newsteller} />
              </figure>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </div>
  );
};

export default LandingPage;
