import React, { useState } from "react";
import "./booking.scss";
import { FaStar } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import {
  calculateAmount,
  getCurrentDate,
  utcConverter,
} from "../../helper/common";
import moment from "moment";
import CommonModal from "../../components/modal/CommonModal";
import { useMutation, useQuery } from "@apollo/client";
import { GET_JSON_DATA } from "../../service/home/homeQuery";
import { REQUEST_BOOKING } from "../../service/booking/bookingMutaion";
import { toast } from "react-toastify";
import { calendarcstm, spin } from "../../DefaultImport";
import { CANCELLATION_POLICY_LIST } from "../../service/booking/bookingQuery";
import DatePicker from "react-datepicker";
const BookingRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { detail, leaselength, date } = state;
  const [showModal, setShowModal] = useState(false);
  const { data: jsonData } = useQuery(GET_JSON_DATA);
  const { getJsonData } = jsonData || {};
  const [selectedLeaseLength, setSelectedLeaseLength] = useState(leaselength);
  const [bookDate, setBookDate] = useState(date);
  const { LeaseLength } = getJsonData || {};
  const [loader, setLoader] = useState(false);
  const [RequestBooking] = useMutation(REQUEST_BOOKING);
  const { data: policy } = useQuery(CANCELLATION_POLICY_LIST, {
    variables: {
      input: {
        page: 1,
        limit: 10,
      },
    },
  });
  const { cancellationPolicyList } = policy || {};
  const { list } = cancellationPolicyList || {};
  const handleClose = () => {
    setShowModal(false);
  };
  const handleRadioChange = (e, daysCount) => {
    setSelectedLeaseLength(daysCount);
  };
  const handleDateChange = (value) => {
    setBookDate(value);
  };
  const requestBooking = async (e) => {
    try {
      setLoader(true);
      e.preventDefault();
      const { data } = await RequestBooking({
        variables: {
          input: {
            property: detail.id,
            moveInDate: utcConverter(bookDate),
            cancellationPolicy: `Free cancellation before ${moment(
              bookDate
            ).format("D MMM")}. cancel before check-in on ${moment(bookDate)
              .add(selectedLeaseLength, "days")
              .format("D MMM")} for a partial refund.`,
            leaseLength: selectedLeaseLength,
            totalRentAmount: calculateAmount(detail?.amount, leaselength),
          },
        },
      });
      const { requestBooking } = data || {};
      const { booking, message } = requestBooking || {};
      toast.success(message);
      navigate(`/booking/${booking._id}/Pending`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };
  return (
    <div className="wrap_booking_request">
      <div className="wrap_heading">
        <h5>Request to Book</h5>
      </div>
      <div className="wrap_booking_heading">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="wrap_listing_card cstm-list_mobile">
              <div className="recommend_list">
                <figure>
                  {/* <Link to="#"> */}
                  <img alt="" src={detail?.images}></img>
                  {/* </Link> */}
                </figure>
                <div className="cstm_wrap_card_list">
                  <div className="wrap_value">
                    <h5>{detail?.title}</h5>
                    {detail?.rating > 0 && (
                      <span className="wrap_rating">
                        <FaStar /> {detail?.rating}
                      </span>
                    )}
                  </div>
                  <span className="property_location">
                    <MdLocationPin /> {detail?.address}
                  </span>
                  <p>
                    <b>$ {detail?.amount} </b> <span> /month</span>
                  </p>
                </div>
                <div className="wrap_detail_amount">
                  <h5>Price Details</h5>
                  <ul>
                    {/* <li>
                      <span>Accommodation</span> <span>$ 2499</span>
                    </li>
                    <li>
                      <span>Ycasa Service fee</span> <span>$ 199</span>
                    </li>
                    <li>
                      <span>Taxes</span> <span>$ 249</span>
                    </li> */}
                  </ul>
                  <div className="total_amount">
                    <span>Total (Include all taxes)</span>{" "}
                    <span>
                      $ {calculateAmount(detail?.amount, selectedLeaseLength)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-6 col-sm-6">
            <div className="wrap_request_book">
              <div className="booking_details_date">
                <h5>Booking Details</h5>
                <ul className="details_ul">
                  <li>
                    <div className="wrap_date_details">
                      <p>Dates</p>
                      <span>
                        Starts from {moment(bookDate).format("D MMM")}
                      </span>
                    </div>
                    <button
                      className="btn_booking_details"
                      onClick={() => setShowModal(true)}
                    >
                      Change
                    </button>
                  </li>
                  <li>
                    <div className="wrap_date_details">
                      <p>Lease Length</p>
                      <span>{selectedLeaseLength} days</span>
                    </div>
                    <button
                      className="btn_booking_details"
                      onClick={() => setShowModal(true)}
                    >
                      Change
                    </button>
                  </li>
                </ul>
                <div className="wrap_overview">
                  <h5>Things to Know</h5>
                  <div className="wrap_things">
                    <p>House Rules</p>
                    <span>{detail?.propertyRule}</span>
                    {/* <button className="btn_see_more">Learn more</button> */}
                  </div>
                  <div className="wrap_things mt-4">
                    <p>Cancellation Policy</p>
                    <span>
                      Free cancellation before{" "}
                      {moment(bookDate).format("D MMM")}. cancel before check-in
                      on {moment(bookDate).add("15", "days").format("D MMM")}{" "}
                      for a partial refund.
                    </span>
                    <ul>
                      {list?.map((policy) => (
                        <li>{policy?.cancelPolicy}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="pay_details">
                  {/* <h5>Pay with</h5> */}
                  <form onSubmit={requestBooking}>
                    {/* <div className="form_area_input">
                      <label>Card Number</label>
                      <input
                        className="cstm_input_profile"
                        type="number"
                        placeholder="xxxx xxxx xxxx xxxx"
                      />
                    </div>
                    <div className="form_area_input form_input_flex">
                      <div className="wrap_label_inpu">
                        <label>Expiration</label>
                        <input
                          className="cstm_input_profile"
                          type="number"
                          placeholder="MM/YY"
                        />
                      </div>
                      <div className="wrap_label_inpu">
                        <label>CVV</label>
                        <input
                          className="cstm_input_profile"
                          type="number"
                          placeholder="3 digits"
                        />
                      </div>
                    </div>
                    <div className="form_area_input">
                      <label>Card Holder Name</label>
                      <input
                        className="cstm_input_profile"
                        type="number"
                        placeholder="Enter cardholder name"
                      />
                    </div> */}
                    <div className="btn_book_now ">
                      <button
                        type="submit"
                        className="btn_see_more form_sbmt_btn"
                      >
                        {loader === true ? (
                          <img alt="" src={spin} />
                        ) : (
                          "Request to Book"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        show={showModal}
        customClass="wrap_modal"
        handleClose={handleClose}
        title="Change Date And Lease Length"
      >
        <div className="lease">
          <p>Lease Length</p>
          <div className="wrap_label">
            {LeaseLength?.map((data) => (
              <label>
                <input
                  type="radio"
                  name="radio"
                  value={data?.daysCount}
                  onChange={(e) => handleRadioChange(e, data?.daysCount)}
                  checked={selectedLeaseLength === data?.daysCount}
                />
                <span>{data?.name}</span>
              </label>
            ))}
          </div>
          <div className="wrap_datePicker">
            <p>Move-in Date</p>
            {/* <input
              className="cstm_input_profile"
              type="date"
              placeholder="Select Date"
              min={getCurrentDate(detail?.availableFrom)?.availableFrom}
              max={getCurrentDate(detail?.availableFrom)?.endDate}
              value={bookDate}
              onChange={(e) => handleDateChange(e)}
            /> */}
            <DatePicker
              onChange={(date) => setBookDate(date)}
              placeholderText="Select a date"
              selected={bookDate}
              minDate={getCurrentDate(detail?.availableFrom)?.availableFrom}
              maxDate={getCurrentDate(detail?.availableFrom)?.endDate}
              className="cstm_input_profile"
            />
            <figure className="calendar_icon">
              <img src={calendarcstm} alt="" />
            </figure>
          </div>
          <div className="btn_book_now">
            <button className="btn_see_more" onClick={() => handleClose()}>
              Submit
            </button>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default BookingRequest;
