import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import App from "./App";
import { client } from "./service/graphql";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "yet-another-react-lightbox/styles.css";
import { store } from "./store";
import { Provider } from "react-redux";
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
    <ToastContainer />
  </ApolloProvider>
);
