import React, { useState, useEffect } from "react";
import "./auth.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  getTabState,
  setLogin,
  setRegister,
} from "../../components/shared/header/headerSlice";
import {
  Corousel,
  Header,
  Login,
  Register,
} from "../../DefaultImportComponent";

const Auth = () => {
  const dispatch = useDispatch();
  const tabState = useSelector(getTabState);
  const [key, setKey] = useState(tabState);
  const handleStateChange = (state) => {
    if (state === "login") dispatch(setLogin());
    if (state === "register") dispatch(setRegister());
  };
  useEffect(() => {
    setKey(tabState);
  }, [tabState]);
  return (
    <div>
      <Header />
      <section className="wrap_auth">
        <div className="wrap_crousel_form">
          <Corousel />
          <div className="wrap_login_register_form">
            <div className="wrap_form_login">
              <h3>
                {key === "login" ? "Login to YCASA" : "Register to YCASA"}
              </h3>
              <span className="cstm_font_increase">
              Welcome to Ycasa. Where Every Casa Feels Like Home.

              </span>
              <div className="wrap_tabs_pills">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => handleStateChange(k)}
                  className="mb-3"
                >
                  <Tab eventKey="login" title="Log In">
                    <Login />
                  </Tab>
                  <Tab eventKey="register" title="Register">
                    <Register />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Auth;
