import { gql } from "@apollo/client";

export const GET_DISPUTE_CHAT_LIST = gql`
	query GetDisputeChatList($input: DisputeListDTO!){
		disputeListUserAndOwner(input: $input){
			disputeList{
				disputeStatus
				senderId
				receiverId
				ticketId
				disputeTopic
				property{
					_id
					images
					title
				}
				transaction{
					paymentAmount
				}
				bookingId
				owner{
					name
					image
				}
			}
			totalCount
		}
	}
`

export const GET_DISPUTE_CHAT = gql`
	query GetDisputeChat($input: DisputeMessageListDTO!){
		getDisputeChat(input: $input){
			disputeMessageList{
				description
				senderId
				images
				videos
				files
				createdAt
			}
			dispute{
				disputeStatus
			}
		}
	}
`

export const GET_DISPUTE_LIST_USER = gql`
	query GetDisputeListUser($input: DisputeListDTO!){
		disputeListUserAndOwner(input: $input){
			totalCount
			disputeList{
				_id
				ticketId
				disputeTopic
				disputeStatus
				property{
					title
				}
				createdAt
				transaction{
					paymentAmount
				}
			}
		}
	}
`
