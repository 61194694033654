import { gql } from "@apollo/client";

export const REQUEST_BOOKING = gql`
  mutation RequestBooking($input: BookingDTO!) {
    requestBooking(input: $input) {
      message
      booking {
        _id
      }
    }
  }
`;

export const BOOKING_TRANSACTION = gql`
  mutation BookingTransaction($input: BookingTransactionDTO!) {
    bookingTransaction(input: $input) {
      message
    }
  }
`;

export const PAYMENT_REMAINING_RENT = gql`
  mutation PaymentRemainingRent($input: PayRemainingDTO!) {
    payRemainingRent(input: $input) {
      message
    }
  }
`;
export const SAVE_CARD = gql`
  mutation SaveCard($input: SaveCardDTO!) {
    saveCard(input: $input)
  }
`;

export const DELETE_RATING = gql`
  mutation DeleteCard($input: CardDTO!){
    deleteCard(input: $input)
  }
`