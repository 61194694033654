import React, { useEffect, useState } from "react";
import "./dispute.scss";
import { Form, Table } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import { GET_DISPUTE_CHAT_LIST } from "../../service/dispute/disputeQuery";
import { DateConversion } from "../../helper/common";
import { useNavigate } from "react-router-dom";
import { setDispute } from "../../components/dispute/disputeSlice";
import { useDispatch } from "react-redux";
import { NoData } from "../../DefaultImportComponent";

const Dispute = () => {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState("")
  const dispatch = useDispatch()
  const [sort,setSort] = useState("dsc")
  const { data,refetch } = useQuery(GET_DISPUTE_CHAT_LIST, {
    variables: {
      input: {
        page: 1,
        limit: 10,
        search: searchQuery,
        userType: "user",
        sort: sort
      }
    }
  })
  const handleSearch = (e) => {
    setSearchQuery(e.target.value)
  }
  const { disputeListUserAndOwner } = data || {}
  const { disputeList } = disputeListUserAndOwner || {}
  const navigateDisputeChat = (e, dispute) => {
    e.preventDefault()
    const { bookingId, owner, property, disputeTopic,ticketId } = dispute;
    dispatch(setDispute({ bookingId, owner, property, disputeTopic,ticketId }));
    navigate("/dispute_chat")
  }
  const handleSort = (e) => {
    setSort(e.target.value)
  }
  useEffect(() => {
    refetch()
  },[refetch])
  return (
    <div className="wrapper_wishlist wrapper_my_dispute">
      <div className="row cstm_row_whislist">
        <div className="col-lg-4 col-md-4">
          <div className="wrap_heading">
            <h5>My Disputes</h5>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="wrap_search_bar">
            <input
              type="search"
              className="cstm_input"
              placeholder="Search by property name, dispute id and reason..."
              onChange={handleSearch}
            />
            <FaSearch className="cstm_nav_icon" />
            <button className="nav_btn_cstms">
              <FaSearch /> Search
            </button>
          </div>
        </div>
        <div className="col-lg-2 col-md-2">
          <div className="wrap_select">
            <Form.Select
              name="gender"
              className="cstm_input_profile"
              aria-label="Default select example"
              onChange={handleSort}
            >
              <option>Sort By</option>
              <option value="dsc">Ascending</option>
              <option value="asc">Descending</option>
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="row cstm_table_wrap">
        <div className="col-lg-12">
          {disputeList?.length === 0  && <NoData msg="No Dispute Yet"/>}
          <div className="wrap_table">
            {disputeList?.length > 0 &&
                 <Table responsive size="lg">
                 <thead>
                   <tr>
                     <th>Dispute ID</th>
                     <th>Property name</th>
                     <th>Reason</th>
                     <th>Escalation Date</th>
                     <th>Refund Amount</th>
                     <th>Status</th>
                   </tr>
                 </thead>
                 <tbody>
                   {disputeList?.map((list) => (
                        <tr onClick={(e)=>navigateDisputeChat(e,list)}>
                       <td>{ list?.ticketId}</td>
                       <td>{ list?.property?.title}</td>
                        <td style={{ maxWidth: "300px" }}>
                         {list?.disputeTopic}
                        </td>
                       <td>{ DateConversion(list?.createdAt)}</td>
                       <td>$ {list?.transaction?.paymentAmount }</td>
                       <td className={`${list?.disputeStatus === 0 ? "pending": "accepted" }`}>{ list?.disputeStatus === 0 ? "Open" : "Solved"}</td>
                      </tr>
                   ))}
                  
                   
                 </tbody>
               </Table>
         }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dispute;
