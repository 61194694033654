import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  booking: null,
  flag: false,
  disputeTopic: null 
};
export const bookingSlice = createSlice({
	name: "booking",
	initialState,
  reducers: {
    setBookingDetail: (state, action) => {
      state.booking = action.payload;
    },
    setFlag: (state, action) => {
      state.flag = !action.payload
    },
    setDispute: (state, action) => {
      state.disputeTopic = action.payload
    }
  },
});

export const { setBookingDetail,setFlag,setDispute } = bookingSlice.actions;
export const getBooking = (state) => state.booking;
export default bookingSlice.reducer;
