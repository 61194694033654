import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterObject: {
    propertyType: [],
    beds: [],
    bath: [],
    leaseType: [],
    furnishingType: [],
    leaseLength: [],
    smoking: [],
    accessibilityFeature: [],
    amenities: [],
    facilities: [],
    amount: {
      min: "0",
      max: "10000",
    },
    availableFrom: "",
    ownerVerify: false,
  },
  coordinate: {
    latitude: null,
    longitude: null,
  },
  page: 1,
  latLongSearch: "",
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterPropertyType: (state, action) => {
      const selectedProperty = action.payload;
      const propertyIndex =
        state.filterObject.propertyType.indexOf(selectedProperty);
      if (propertyIndex === -1) {
        state.filterObject.propertyType.push(selectedProperty);
      } else {
        state.filterObject.propertyType.splice(propertyIndex, 1);
      }
    },
    setFilterLeaseType: (state, action) => {
      const selectedProperty = action.payload;
      const propertyIndex =
        state.filterObject.leaseType.indexOf(selectedProperty);
      if (propertyIndex === -1) {
        state.filterObject.leaseType.push(selectedProperty);
      } else {
        state.filterObject.leaseType.splice(propertyIndex, 1);
      }
    },
    setFilterFurnishType: (state, action) => {
      const selectedProperty = action.payload;
      const propertyIndex =
        state.filterObject.furnishingType.indexOf(selectedProperty);
      if (propertyIndex === -1) {
        state.filterObject.furnishingType.push(selectedProperty);
      } else {
        state.filterObject.furnishingType.splice(propertyIndex, 1);
      }
    },
    setFilterLeaseLength: (state, action) => {
      const selectedProperty = action.payload;
      const propertyIndex =
        state.filterObject.leaseLength.indexOf(selectedProperty);
      if (propertyIndex === -1) {
        state.filterObject.leaseLength.push(selectedProperty);
      } else {
        state.filterObject.leaseLength.splice(propertyIndex, 1);
      }
    },
    setFilterAccessebility: (state, action) => {
      const selectedProperty = action.payload;
      const propertyIndex =
        state.filterObject.accessibilityFeature.indexOf(selectedProperty);
      if (propertyIndex === -1) {
        state.filterObject.accessibilityFeature.push(selectedProperty);
      } else {
        state.filterObject.accessibilityFeature.splice(propertyIndex, 1);
      }
    },
    setFilterSmoking: (state, action) => {
      const selectedProperty = action.payload;
      const propertyIndex =
        state.filterObject.smoking.indexOf(selectedProperty);
      if (propertyIndex === -1) {
        state.filterObject.smoking.push(selectedProperty);
      } else {
        state.filterObject.smoking.splice(propertyIndex, 1);
      }
    },
    setFilterAmenities: (state, action) => {
      const selectedProperty = action.payload;
      const propertyIndex =
        state.filterObject.amenities.indexOf(selectedProperty);
      if (propertyIndex === -1) {
        state.filterObject.amenities.push(selectedProperty);
      } else {
        state.filterObject.amenities.splice(propertyIndex, 1);
      }
    },
    setFilterBeds: (state, action) => {
      const selectedBed = action.payload;
      const bedIndex = state.filterObject.beds.indexOf(selectedBed);
      if (bedIndex === -1) {
        state.filterObject.beds.push(selectedBed);
      } else {
        state.filterObject.beds.splice(bedIndex, 1);
      }
    },
    setFilterBath: (state, action) => {
      const selectedBed = action.payload;
      const bedIndex = state.filterObject.bath.indexOf(selectedBed);
      if (bedIndex === -1) {
        state.filterObject.bath.push(selectedBed);
      } else {
        state.filterObject.bath.splice(bedIndex, 1);
      }
    },

    setFilterFacilities: (state, action) => {
      const selectedBed = action.payload;
      const bedIndex = state.filterObject.facilities.indexOf(selectedBed);
      if (bedIndex === -1) {
        state.filterObject.facilities.push(selectedBed);
      } else {
        state.filterObject.facilities.splice(bedIndex, 1);
      }
    },
    setFilterAmount: (state, action) => {
      const { min, max } = action.payload;
      state.filterObject.amount.min = String(min);
      state.filterObject.amount.max = String(max);
    },
    setFilterOwnerVerify: (state, action) => {
      state.filterObject.ownerVerify = action.payload;
    },
    setFilterAvailableFrom: (state, action) => {
      state.filterObject.availableFrom = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.coordinate.latitude = action.payload.latitude;
      state.coordinate.longitude = action.payload.longitude;
    },
    setPageLimit: (state, action) => {
      state.page = action.payload;
    },
    setDefaultCoordinate: (state, action) => {
      if (action.payload.default === true) {
        state.coordinate.latitude = action.payload.latitude;
        state.coordinate.longitude = action.payload.longitude;
      }
      state.latLongSearch = action.payload.place;
    },

    setClearFilter: (state) => {
      state.filterObject = {
        propertyType: [],
        beds: [],
        bath: [],
        leaseType: [],
        furnishingType: [],
        leaseLength: [],
        smoking: [],
        accessibilityFeature: [],
        amenities: [],
        facilities: [],
        amount: {
          min: "0",
          max: "10000",
        },
        availableFrom: "",
        ownerVerify: false,
      };
    },
  },
});

export const {
  setFilterPropertyType,
  setFilterLeaseType,
  setFilterLeaseLength,
  setFilterAccessebility,
  setFilterSmoking,
  setFilterAmenities,
  setFilterBeds,
  setFilterBath,
  setFilterFacilities,
  setFilterAmount,
  setFilterOwnerVerify,
  setFilterAvailableFrom,
  setSearchFilter,
  setClearFilter,
  setFilterFurnishType,
  setDefaultCoordinate,
  setPageLimit,
} = filterSlice.actions;
export const getFilterProperty = (state) => state.filter;
export default filterSlice.reducer;
