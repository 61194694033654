import React from "react";
import { Header } from "../../../DefaultImportComponent";
import { Link } from "react-router-dom";

const Delete = () => {
  return (
    <div className="wrapper-static_terms">
      <Header />
      <div className="wrap_static_terms">
        <div className="tittle">
          <h1>Delete my Account</h1>
        </div>
        <div className="wrap_content">
          <div className="Custom_container">
            <div className="wrap_terms_content">
              <div className="wrap_txt">
                <h3>How do I delete my account?</h3>
                <h5 className="mb-3">
                  <b>Via your YCASA App</b>
                </h5>
                <ul className="number_order">
                  <li>
                    <p>Go to your profile tab (bottom right tab on the app)</p>
                  </li>
                  <li>
                    <p>
                      Scroll down to the "Delete Account" button and tap on it.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-3">
                  <b>Via email</b>
                </h5>
                <p>
                  Simply reach out to us over email at estevan@cloakunity.com
                  from the email address associated with your YCASA account
                </p>
              </div>
              <div className="wrap_txt">
                <h5 className="mb-3">
                  <b>
                    What happens to my data once my account has been deleted?
                  </b>
                </h5>
                <p>
                  Please note, once you have deleted your account, you will not
                  be able to recover your data. The process is irreversible.
                </p>
                <p>
                  We will no longer have any of your personal data on our
                  systems. However all anonymised (not linked to you, your name,
                  or your email address) aggregate data will remain.
                </p>
                {/* <ul>
                  <li>
                    Apple/App Store:
                    <Link
                      to={
                        "https://apps.apple.com/in/app/recyclobot/id6449482143"
                      }
                    >
                      {" "}
                      https://apps.apple.com/in/app/recyclobot/id6449482143{" "}
                    </Link>
                  </li>
                  <li>
                    Android/Play Store:
                    <Link
                      to={
                        "https://play.google.com/store/apps/details?id=com.recyclo.recyclobot"
                      }
                    >
                      {" "}
                      https://play.google.com/store/apps/details?id=com.recyclo.recyclobot{" "}
                    </Link>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delete;
