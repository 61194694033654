import React, { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { FaStar } from "react-icons/fa6";
import { MdLocationPin } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { Pdf_icon } from "../../DefaultImport";
import { FaRegImages } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  BOOKING_DETAIL,
  CANCELLATION_POLICY_LIST,
} from "../../service/booking/bookingQuery";
import { GET_JSON_DATA } from "../../service/home/homeQuery";
import {
  DateConversion,
  filterBooking,
  filterJson,
  filterJsonData,
} from "../../helper/common";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getBooking, setBookingDetail, setDispute } from "./bookingSlice";

const BookingDetails = () => {
  const {flag} = useSelector(getBooking)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();
  const [open, setOpen] = useState(false);
  const { data, refetch } = useQuery(BOOKING_DETAIL, {
    variables: {
      _id: param.id,
    },
  });
  const { data: policy } = useQuery(CANCELLATION_POLICY_LIST, {
    variables: {
      input: {
        page: 1,
        limit: 10,
      },
    },
  });
  const { cancellationPolicyList } = policy || {};
  const { list } = cancellationPolicyList || {};
  const [images, setImage] = useState([]);
  const { bookingDetails } = data || {};
  const { property, contractDocument } = bookingDetails || {};
  const { data: jsonData } = useQuery(GET_JSON_DATA);
  const { getJsonData } = jsonData || {};
  const { BookingStatus, FurnishingType, LeaseType, DisputeTopic } = getJsonData || {};
  const openPhotoGallery = (imageArray) => {
    setOpen(true);
    if (imageArray?.length > 0) {
      const updatedImageArray = imageArray.map((image, index) => ({
        src: image,
      }));
      setImage(updatedImageArray);
    }
  };
  useEffect(() => {
    dispatch(setBookingDetail(bookingDetails));
    dispatch(setDispute(DisputeTopic));
    refetch();
    const handleReloadRedirect = () => {
      const redirectFlag = localStorage.getItem("redirectFlag");
      if (redirectFlag) {
        localStorage.removeItem("redirectFlag");
        navigate("/my_booking");
      }
    };
    handleReloadRedirect();
    const beforeUnloadHandler = () => {
      localStorage.setItem("redirectFlag", "true");
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [bookingDetails, dispatch, refetch,flag, param, navigate]);

  return (
    <div className="wrap_details_left_card">
      <div className="wrap_property_section">
        <figure className="large_property">
          <img src={property?.images[0]} alt="" />
          <span
            className="tag_span"
            onClick={() => openPhotoGallery(property?.images)}
          >
            <FaRegImages /> Show more
          </span>
        </figure>
      </div>
      <div className="wrap_inner_card">
        <div className="cstm_flex">
          <div className="location">
            <div className="wrap_booking_rating">
              <p className="cstm_p">
                Booking ID <span> {bookingDetails?.bookingNumber} </span>
              </p>
              {property?.rating > 0 && (
                <span className="rating_star">
                  <FaStar />
                  {property?.rating}
                </span>
              )}
            </div>
            <h5>{property?.title}</h5>
            <div className="dflex">
              <span className="location_tag">
                <MdLocationPin />
                {property?.address}
              </span>
              <span
                className={filterBooking(
                  BookingStatus,
                  bookingDetails?.bookingStatus
                )?.name?.toLowerCase()}
              >
                {
                  filterBooking(BookingStatus, bookingDetails?.bookingStatus)
                    ?.name
                }
              </span>
              {/* <span className="accepted">Accepted</span> */}
              {/* <span className="rejected">Rejected</span> */}
              {/* <span className="completed">Completed</span> */}
              {/* <span className="on_going">
                <img src={dots} alt="" />
                Ongoing
              </span> */}
            </div>
          </div>
        </div>
        <div className="cstm_ul">
          <ul>
            <li>
              {" "}
              <GoDotFill className="hide" />
              {property?.beds} Beds
            </li>
            <li>
              <GoDotFill /> {property?.bath} Bathroom
            </li>
            <li>
              <GoDotFill />{" "}
              {LeaseType &&
                filterJson(LeaseType, property?.leaseType)?.map((value) => (
                  <span>{value.name}</span>
                ))}
            </li>
            <li>
              <GoDotFill />{" "}
              {FurnishingType &&
                filterJsonData(FurnishingType, property?.furnishingType)?.name}
            </li>
          </ul>
        </div>
        <div className="wrap_overview">
          <h6>Booking Details</h6>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="wrap_over_card">
                <p>Move-in Date</p>
                <span>{DateConversion(bookingDetails?.moveInDate)}</span>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="wrap_over_card">
                <p>Lease Length</p>
                {/* {LeaseLength &&
                  filterJson(LeaseLength, property?.leaselength)?.map(
                    (value) => <span> {value.name} </span>
                  )} */}
                {bookingDetails?.leaseLength} Days
              </div>
            </div>
          </div>
        </div>
        {contractDocument && (
          <div className="wrap_overview contract_pdf">
            <h6>Booking Details</h6>
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-9">
                <div className="wrap_over_card wrap_cstm_pdf">
                  <figure>
                    <img src={Pdf_icon} alt="" />
                  </figure>
                  <p>Lease Contract</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="wrap_over_card wrap_cstm_btn_pdf">
                  <Link
                    className="cstm_anch_card_down"
                    to={contractDocument}
                    target="blank"
                  >
                    <button className="btn_see_more">Download</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="wrap_overview wrap_Things">
          <h6>Things to Know</h6>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
              <div className="wrap_things">
                <p>House Rules</p>
                <span>
                  {`Free cancellation before ${moment(
                    bookingDetails?.moveInDate
                  ).format("D MMM")}. cancel before check-in on ${moment(
                    bookingDetails?.moveInDate
                  )
                    .add("15", "days")
                    .format("D MMM")} for a partial refund.`}
                </span>
              </div>
              {/* <div className="btn_see_wrapper">
                <button className="btn_see_more">Learn more</button>
              </div> */}
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="wrap_things">
                <p>Cancellation Policy</p>
                {/* <span>
                  We ask every guest to remember a few simple things about what
                  makes a great guest.
                </span> */}
                <ul>
                  {list?.map((policy) => (
                    <li>{policy?.cancelPolicy}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        plugins={[Thumbnails, Zoom]}
        slides={images}
      />
    </div>
  );
};

export default BookingDetails;
