import { configureStore } from "@reduxjs/toolkit";
import headerReducer from "./components/shared/header/headerSlice";
import authReducer from "./components/auth/authSlice";
import filterReducer from "./components/property/filterSlice";
import bookingReducer from './components/bookingdetails/bookingSlice'
import chatSlice from "./components/chat/chatSlice";
import disputeSlice from "./components/dispute/disputeSlice";
export const store = configureStore({
  reducer: {
    header: headerReducer,
    auth: authReducer,
    filter: filterReducer,
    booking: bookingReducer,
    chat: chatSlice,
    dispute: disputeSlice
  },
});
