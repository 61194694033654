import { gql } from "@apollo/client";

export const GET_FAQ = gql`
  query GetFaq($input: ListFaqDTO!) {
    faqlist(input: $input) {
      list{
        question
        answer
        questionType
        status
        _id
      }
      total
    }
  }
`;
