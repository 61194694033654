import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import "./chat.scss";
import { File, Pdf_icon } from "../../DefaultImport";
import ChatUserList from "../../components/chat/ChatUserList";
import { socket } from "../../socket";
import { useDispatch, useSelector } from "react-redux";
import { getChatState, setEmpty, setHandleMountState, setViewProperty } from "../../components/chat/chatSlice";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { FaBars } from "react-icons/fa";
import face_img from "../../assets/images/face1.png";

import {
  formatTimeStamp,
  getDecryptUserDetail,
  getLastIndexName,
  isPdf,
} from "../../helper/common";
import { handleApiCall } from "../../service/restApi";
import { NoChatFound } from "../../DefaultImportComponent";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useQuery } from "@apollo/client";
import { GET_USER_DETAIL } from "../../service/auth/authQuery";
import { spin } from "../../DefaultImport";

const Message = () => {
  const {archiveStatus} = useSelector(getChatState)
  const [currentRoomId, setCurrentRoomId] = useState(null);
  const { data, refetch } = useQuery(GET_USER_DETAIL);
  const [loader, setLoader] = useState(false);
  const { getUserData } = data || {};
  const { image } = getUserData || {};
  const dispatch = useDispatch();
  const [images, setImage] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const getUser = JSON.parse(getDecryptUserDetail());
  const scrollRef = useRef(null);
  const {
    roomId,
    receiverId,
    propertyDetail,
    receiverdata,
    viewPropertyStatus,
    handleMountState
  } = useSelector(getChatState);
  const [msg, setMsg] = useState(null);
  const [message, setMessage] = useState([]);
  socket.on("receiveMessages", (chat) => {
    const { result, userAuth } = chat;
    if (getUser._id === userAuth) {
      setMessage(result);
    }
  });
  socket.on("receiveMessage", (message) => {
    setMessage((prevMessages) => {
      if (!prevMessages.find((msg) => msg._id === message.result._id)) {
        return [...prevMessages, message.result];
      } else {
        return prevMessages;
      }
    });
  });
  const inputRef = useRef(null);
  const handleChange = (e) => {
    setMsg(e.target.value);
  };
  const handleImageChange = async (e) => {
    try {
      setLoader(true);
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("image", e.target.files[i]);
      }
      const { data } = await handleApiCall("post", "/chat/media", formData);
      if (data) {
        socket.emit("sendMessage", {
          roomId: currentRoomId,
          receiverId: receiverId,
          senderId: getUser?._id,
          message: null,
          image: data?.data?.image,
          msgType: e.target.files[0].type.includes("pdf") ? "3" : "2",
        });
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  socket.on("getRoomId", (data) => {
    setCurrentRoomId(data?.roomId);
    socket.emit("chatMessage", {
      roomId: data?.roomId,
      senderId: getUser?._id,
    });
  });
  const sendMessage = (e) => {
    if (msg) {
      e.preventDefault();
      socket.emit("sendMessage", {
        roomId: currentRoomId,
        receiverId: receiverId,
        senderId: getUser?._id,
        message: msg,
        msgType: "1",
      });
      dispatch(setHandleMountState(handleMountState))
      setMsg("");
    }
  };
  const deleteChat = () => {
    socket.emit("clearChat", {
      senderId: getUser?._id,
      roomId: currentRoomId,
    });
    setMessage([]);
  };
  const openPhotoGallery = (imageArray) => {
    setOpen(true);
    if (imageArray.length > 0) {
      const updatedImageArray = imageArray.map((image) => ({
        src: image,
      }));
      setImage(updatedImageArray);
    }
  };
  socket.on("error", (response) => {
    const { status } = response 
    // if (status === 422) {
    //     dispatch(setEmpty())
    // }
  });
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    if (receiverdata?._id && archiveStatus === false) {
      socket.emit("initiateChat", {
        senderId: getUser?._id,
        receiverId: receiverdata?._id,
        property: propertyDetail?._id,
        userType: "user",
      });
    }
    refetch();
  }, [receiverdata]);

  useEffect(() => {
    return () => {
      dispatch(setEmpty());
    };
  }, []);

  const addNavigationClass = () => {
    document.body.classList.add("innerside_toogle");
  };
  return (
    <div className="wrap_chat_message">
      <div className="wrapside_chat">
        <ChatUserList />
      </div>
      <div className="wrap_chat_content">
        {roomId === null && <NoChatFound />}
        {propertyDetail !== null && (
          <div className="wrap_profile_details" ref={scrollRef}>
            <div className="head_setting">
              <div className="wrap_divide_dots">
                <div className="wrap_owner">
                  <div className="wrap_toogle_icon">
                    <span className="cross_bar" onClick={addNavigationClass}>
                      <FaBars className="cstm_bars" />
                    </span>
                  </div>
                  <figure>
                    <img
                      src={receiverdata?.image ? receiverdata?.image : face_img}
                      alt=""
                    />
                  </figure>
                  <h6>{receiverdata?.name}</h6>
                </div>
                <div className="wrap_btn">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <BsThreeDotsVertical className="cstm_drop_three" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={deleteChat}>
                        {" "}
                        Delete Chat{" "}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="form_profile_set" ref={scrollRef}>
              {viewPropertyStatus && (
                <div className="top_view_detail">
                  <div className="fig_pro_detail">
                    <figure>
                      <img src={propertyDetail?.images[0]} alt="" />
                    </figure>
                    <h6>{propertyDetail?.title}</h6>
                  </div>
                  <div className="btn_cross">
                    <button
                      onClick={() =>
                        navigate(`/property_detail/${propertyDetail?._id}`)
                      }
                      className="view"
                    >
                      View Details
                    </button>
                    <ImCross
                      onClick={() =>
                        dispatch(setViewProperty(!viewPropertyStatus))
                      }
                    />
                  </div>
                </div>
              )}

              {message?.map((data) => (
                <div>
                  {data?.senderId === getUser?._id && (
                    <div className="chat_right">
                      <div className="_chat">
                        <div className="wrap_card_chat">
                          {data?.message && <span>{data?.message}</span>}
                          {/* {data?.image?.length !== 0 && isPdf(data) && (
                            <figure className="single_img">
                              <Link to={`${data?.image[0]}`} target="blank">
                                <img src={Pdf_icon} alt="" />
                              </Link>
                            </figure>
                          )} */}
                          {data?.image?.length !== 0 &&
                            isPdf(data) &&
                            data?.image?.map((image) => (
                              // <figure className="single_img">
                              //   <Link to={image} target="blank">
                              //     <img src={Pdf_icon} alt="" />
                              //   </Link>
                              // </figure>
                              <Link to={image} target="blank">
                                 <div className="cstm_pdf_style">
                                <div className="wra_Pfix">
                                  <div className="wrap_pdf">
                                    <figure>
                                      <img src={Pdf_icon} alt="" />
                                    </figure>
                                  </div>
                                  <div className="details_pdf_wrap">
                                      <p>{ getLastIndexName(image)}</p>
                                  </div>
                                </div>
                              </div>
                              </Link>
                            ))}
                          {data?.image?.length !== 0 &&
                            isPdf(data) === false &&
                            data?.image?.length >= 1 &&
                            data?.image[0]?.length > 1 &&
                            data?.image?.length <= 3 &&
                            data?.image?.map((image) => (
                              <figure
                                className="single_img"
                                onClick={() => openPhotoGallery(data?.image)}
                              >
                                {<img src={image} alt="" />}
                              </figure>
                            ))}
                          {data?.image?.length !== 0 &&
                            isPdf(data) === false &&
                            data?.image?.length >= 4 && (
                              <div className="double_img_set">
                                {data?.image
                                  ?.slice(0, 4)
                                  .map((image, index) => (
                                    <figure
                                      className={`double_img ${
                                        index === 3 && "cstm_more_image"
                                      }`}
                                      key={index}
                                      onClick={() =>
                                        openPhotoGallery(data?.image)
                                      }
                                    >
                                      <img src={image} alt="" />
                                      <span className="image_count_cstm">
                                        {" "}
                                        + {data?.image?.length}
                                      </span>
                                    </figure>
                                  ))}
                              </div>
                            )}
                          <span className="time_card">
                            {formatTimeStamp(data?.createdAt)}
                          </span>
                        </div>
                        <figure>
                          <img src={image ? image : face_img} alt="" />
                        </figure>
                      </div>
                    </div>
                  )}
                  {data?.senderId !== getUser?._id && (
                    <div className="chat_left">
                      <div className="_chat">
                        <figure>
                          <img src={receiverdata?.image ? receiverdata?.image : face_img } alt="" />
                        </figure>

                        <div className="wrap_card_chat">
                          {data?.message && <span>{data?.message}</span>}
                          {/* {data?.image?.length !== 0 && isPdf(data) && (
                            <figure className="single_img">
                              <img src={Pdf_icon} alt="" />
                            </figure>
                          )} */}
                          {data?.image?.length !== 0 &&
                            data?.msgType === 3 &&
                            data?.image?.map((image) => (
                              // <figure className="single_img">
                              //   <Link to={image} target="blank">
                              //     <img src={Pdf_icon} alt="" />
                              //   </Link>
                              // </figure>
                              <Link to={image} target="blank">
                                 <div className="cstm_pdf_style">
                                <div className="wrap_pdf">
                                  <figure>
                                    <img src={Pdf_icon} alt="" />
                                  </figure>
                                </div>
                                <div className="details_pdf_wrap">
                                <p>{ getLastIndexName(image)}</p>
                                </div>
                              </div>
                              </Link>
                            ))}
                          {data?.image?.length !== 0 &&
                            data?.msgType === 2 &&
                            data?.image?.length >= 1 &&
                            data?.image[0]?.length > 1 &&
                            data?.image?.length <= 3 &&
                            data?.image?.map((image) => (
                              <figure
                                className="single_img"
                                onClick={() => openPhotoGallery(data?.image)}
                              >
                                {<img src={image} alt="" />}
                              </figure>
                            ))}
                          {data?.image?.length !== 0 &&
                            data?.msgType === 2 &&
                            data?.image?.length >= 4 && (
                              <div className="double_img_set">
                                {data?.image
                                  ?.slice(0, 4)
                                  .map((image, index) => (
                                    <figure
                                      className={`double_img ${
                                        index === 3 && "cstm_more_image"
                                      }`}
                                      key={index}
                                      onClick={() =>
                                        openPhotoGallery(data?.image)
                                      }
                                    >
                                      <img src={image} alt="" />
                                      <span className="image_count_cstm">
                                        {" "}
                                        + {data?.image?.length}
                                      </span>
                                    </figure>
                                  ))}
                              </div>
                            )}

                          <span className="time_card">
                            {formatTimeStamp(data?.createdAt)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {archiveStatus === false &&
               <form>
               <div className="wrap_type">
                 <div className="wrap_flex_typeing">
                   <input
                     type="text"
                     className="cstm_input"
                     placeholder="Send a message..."
                     name="message"
                     value={msg}
                     onChange={handleChange}
                   />
                   <div className="wrap_upload">
                     <input
                       type="file"
                       className="hidden_input"
                       ref={inputRef}
                       onChange={handleImageChange}
                       accept="image/x-png,image/jpeg,image/jpg,pdf"
                       multiple
                     />
                     <img
                       src={File}
                       alt=""
                       className="size_img"
                       onClick={() => {
                         inputRef?.current?.click();
                       }}
                     />
                     <button
                       onClick={sendMessage}
                       className="form_sbmt_btn_light form_sbmt_btn"
                     >
                       {loader === true ? <img alt="" src={spin} /> : "Send"}
                     </button>
                   </div>
                 </div>
               </div>
             </form>
           }
          </div>
        )}
      </div>
      <Lightbox
        open={open}
        plugins={[Thumbnails, Zoom]}
        close={() => {
          setOpen(false);
          setImage([]);
        }}
        slides={images}
      />
    </div>
  );
};

export default Message;
