import { gql } from "@apollo/client";

export const GET_NOTIFICATION = gql`
	query GetUserAndOwnerNotification($input: NotificationListForUserAndOwnerDTO!){
		notificationListForUserAndOwner(input: $input){
			list{
				_id
				message
				createdAt
			}
		}
	}
`