import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "login",
  status: "idle",
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setLogin: (state) => {
      state.value = "login";
    },
    setRegister: (state) => {
      state.value = "register";
    },
  },
});

export const { setLogin, setRegister } = headerSlice.actions;
export const getTabState = (state) => state.header.value;
export default headerSlice.reducer;
